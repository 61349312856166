import React from 'react';
import { EPaymentMethod } from 'store/payment-reducer/payment.reducer';

import { BtcPaymentCard } from './components/BtcPaymentCard/BtcPaymentCard';
import { CardPaymentCard } from './components/CardPaymentCard';
import { CryptoPaymentCard } from './components/CryptoPaymentCard';

interface IPaymentOptionDefaultProps {
  handlePaymentButtonClick: (paymentMethod: EPaymentMethod) => void;
  balance: number;
  amount: number;
  paymentMethod: EPaymentMethod;
  t: (key: string) => string;
}

interface PaymentCardProps extends Omit<IPaymentOptionDefaultProps, 'paymentMethod'> {
  handlePaymentButtonClick: (paymentMethod: EPaymentMethod) => void;
  balance: number;
  amount: number;
}

const PaymentCardsComponents: Record<EPaymentMethod, React.FC<PaymentCardProps>> = {
  [EPaymentMethod.AlphaPo]: CryptoPaymentCard,
  [EPaymentMethod.Mercuryo]: CardPaymentCard,
  [EPaymentMethod.BtcBalance]: BtcPaymentCard,
};

export const PaymentOption: React.FC<IPaymentOptionDefaultProps> = ({
  balance,
  amount,
  paymentMethod,
  t,
  handlePaymentButtonClick,
}) => {
  const PaymentCardComponent = PaymentCardsComponents[paymentMethod];

  return (
    <PaymentCardComponent
      handlePaymentButtonClick={handlePaymentButtonClick}
      balance={balance}
      amount={amount}
      t={t}
    />
  );
};
