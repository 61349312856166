import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { Button } from 'components/ui/Button';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const Menu = styled.div`
  display: flex;
  gap: 16px;
`;

export const Profile = styled.div`
  display: flex;
  gap: 16px;

  @media (min-width: ${BreakPoint.MobileTop}) {
    justify-content: space-between;
  }
`;
export const LngSwitcherDesktop = styled.div`
  @media (min-width: ${BreakPoint.DesktopLow}) {
    display: none;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;

export const LngSwitcherMobile = styled.div`
  @media (min-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;

export const MenuButton = styled(Button)`
  grid-row: 1;
  width: 24px;

  & svg path {
    fill: #fff;
  }

  &:hover,
  &:focus,
  &:active {

    svg path {
      fill: #fff;
    }
  }

  @media (min-width: ${BreakPoint.DesktopLow}) {
    display: none;
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-row: 1;
  justify-self: start;

  & svg {
    flex-shrink: 0;
  }

  &:focus-visible {
    outline: none;
  }

  @media (min-width: ${BreakPoint.DesktopLow}) {
    display: none;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -18px);
  }
`;

export const ProfileLink = styled(Link)`
  grid-row: 1;
  justify-self: end;
  text-decoration: none;

  & > div > div {
    transition: all ${TransitionTime.Hover};

    & > svg path {
      transition: all ${TransitionTime.Hover};
    }
  }

  &:focus-visible {
    outline: none;
  }

  &:hover > div > div,
  &:focus > div > div {
    background-color: #d8eeff;

    & > svg path {
      fill: #3069e5;
    }
  }

  @media (max-width: ${BreakPoint.LaptopTop}) {
    grid-row: 1;
    justify-self: end;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;
