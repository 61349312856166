import React from 'react';
import { ReactComponent as BtcGray } from 'assets/icons/assets-page-icons/btc-gray.svg';
import { NBSP_JS } from 'constants/unicode/nbsp-js';
import { MinerStatus } from 'pages/PageAssets/components/ui/MinerStatus';
import { IMinersDataMapped } from 'store/api/cuverse-api/assets/types/assets-response.interface';

import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatToNumberWithSpaces } from 'utils/formatters/format-to-number-with-spaces.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './MobileGroupByStatus.styled';

interface IMinersGroupByStatusProps {
  groupData: IMinersDataMapped;
  btcToUsdRate: number;
  isFirstOfModel: boolean;
  t: (key: string) => string;
}

export const MobileGroupByStatus: React.FC<IMinersGroupByStatusProps> = ({
  groupData,
  btcToUsdRate,
  isFirstOfModel,
  t,
}) => {
  const { status, efficiencyGroup, powerConsumption, number, dailyOutput, totalHashrate } =
    groupData;
  const isTurnedOff = status === 'disabled';

  return (
    <S.GroupByStatusWrapper as="dl" $isFirstOfModel={isFirstOfModel}>
      <S.ListRow>
        <S.ListTitle as="dt" $isTurnedOff={isTurnedOff}>
          {t('assets.hashRate')}` &${NBSP_JS}`{t('assets.quantity')}
        </S.ListTitle>
        <S.HashrateValue as="dd" $isTurnedOff={isTurnedOff}>
          <BtcGray />
          <span>{`${formatToNumberWithSpaces(totalHashrate)} TH/s`}</span>
        </S.HashrateValue>
        <S.MinersValue
          as="dd"
          $isTurnedOff={isTurnedOff}
        >{`${formatToNumberWithSpaces(number)} miners`}</S.MinersValue>
      </S.ListRow>
      <S.ListRow>
        <S.ListTitle as="dt" $isTurnedOff={isTurnedOff}>
          {t('assets.powerConsumption')}
        </S.ListTitle>
        <S.PrimaryValue
          as="dd"
          $isTurnedOff={isTurnedOff}
        >{`${formatToNumberWithSpaces(efficiencyGroup, 2)} kW`}</S.PrimaryValue>
        <S.SecondaryValue
          as="dd"
          $isTurnedOff={isTurnedOff}
        >{`${formatToNumberWithSpaces(powerConsumption, 3)} W/TH`}</S.SecondaryValue>
      </S.ListRow>
      <S.ListRow>
        <S.ListTitle as="dt" $isTurnedOff={isTurnedOff}>
          {t('assets.dailyIncome')}
        </S.ListTitle>
        <S.PrimaryValue
          as="dd"
          $isTurnedOff={isTurnedOff}
        >{`$${formatUsdOutput(dailyOutput * btcToUsdRate)}`}</S.PrimaryValue>
        <S.SecondaryValue
          as="dd"
          $isTurnedOff={isTurnedOff}
        >{`$${formatBtcOutput(dailyOutput)} BTC`}</S.SecondaryValue>
      </S.ListRow>
      <S.ListRow>
        <S.ListTitle as="dt" $isTurnedOff={isTurnedOff}>
          {t('assets.status')}
        </S.ListTitle>
        <MinerStatus tagOverride="dd" status={status} t={t} />
      </S.ListRow>
    </S.GroupByStatusWrapper>
  );
};
