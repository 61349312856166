import React from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/affiliate-program-page-icons/copy.svg';
import { ReactComponent as UsersWhite } from 'assets/icons/affiliate-program-page-icons/users-white.svg';
import { ReactComponent as WalletWhite } from 'assets/icons/affiliate-program-page-icons/wallet-white.svg';

import { SPageSection } from 'components/styled/SPageSection';
import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './InfoSection.styled';

interface IInfoSectionProps {
  btcEarned: number;
  btcToUsdRate: number;
  referralsAmount: number;
  affiliateLink: string;
  handleCopyClick: () => void;
  isReferralLinkPending: boolean;
  t: (key: string) => string;
}

export const InfoSection: React.FC<IInfoSectionProps> = ({
  btcEarned,
  btcToUsdRate,
  referralsAmount,
  affiliateLink,
  handleCopyClick,
  isReferralLinkPending,
  t,
}) => {
  return (
    <SPageSection>
      <S.InfoTitle>{t('affiliateInfo.title')}</S.InfoTitle>
      <S.List as="dl">
        <S.Group as="div">
          <S.Term as="dt">
            <WalletWhite />
            {t('affiliateInfo.earned')}
          </S.Term>
          <S.BtcEarnedValue>{`${formatBtcOutput(btcEarned)} BTC`}</S.BtcEarnedValue>
          <S.UsdEarnedValue>{`≈ $${formatUsdOutput(btcToUsdRate * btcEarned)}`}</S.UsdEarnedValue>
        </S.Group>
        <S.Group as="div">
          <S.Term as="dt">
            <UsersWhite />
            {t('affiliateInfo.numbersOfReferrals')}
          </S.Term>
          <S.ReferralsValue>{referralsAmount}</S.ReferralsValue>
        </S.Group>
        <S.LinkGroup as="div">
          <S.AffiliateTitle as="dt"> {t('affiliateInfo.link')}</S.AffiliateTitle>
          <S.AffiliateLinkValue>
            {isReferralLinkPending ? <S.AffiliateLinkLoader /> : affiliateLink}
          </S.AffiliateLinkValue>
          <S.ButtonWrapper>
            <S.AffiliateLinkCopyButton
              variant="alter-primary"
              text={t('affiliateInfo.copyLink')}
              icon={<CopyIcon />}
              onClick={handleCopyClick}
              disabled={isReferralLinkPending}
            />
          </S.ButtonWrapper>
        </S.LinkGroup>
      </S.List>
    </SPageSection>
  );
};
