export const HISTORY_ITEMS_PER_PAGE = 10;
export const RECENT_TRANSACTIONS_COUNT = 12;
export const HISTORY_TRANSACTIONS_COUNT = 10000;
export const INITIAL_DATE = '01.01.2021';

export const TRANSACTION_TYPE = 'TRANSACTION TYPE';
export const REFERENCE = 'reference';
export const DESCRIPTION = 'description';
export const TIME = 'time';
export const DATE = 'DATE';

export const HIDE_FILTERS = 'HIDE FILTERS';
export const SHOW_FILTERS = 'SHOW FILTERS';

export const tabItems = [
  { text: 'today', value: 'today' },
  { text: 'week', value: 'week' },
  { text: 'month', value: 'month' },
  { text: 'year', value: 'year' },
];
