import styled from 'styled-components';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

export const CartTittleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CartTitle = styled.h2`
  font-family: ${FontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  color: ${EColors.Gray1Text};
`;
