import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { Button } from 'components/ui/Button';

export const CartContentWrapper = styled.div`
  position: relative;
  display: inline-flex;
`;

export const CartTooltipText = styled.span`
  position: relative;
  display: inline-block;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: ${EColors.Blue2NewBrand};
  cursor: pointer;
  user-select: none;
  font-variant-numeric: lining-nums tabular-nums;

  &::after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 2px dashed;
  }
`;

export const CartContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CartTotalValue = styled.span`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  font-variant-numeric: lining-nums tabular-nums;
  color: ${EColors.Gray1Text};
`;

export const CartCheckoutButton = styled(Button)`
  padding: 7px 12px;
  border-radius: 24px;
`;
