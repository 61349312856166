import { EPaymentCategory } from 'store/payment-reducer/payment.reducer';

import { AppRoute } from 'utils/route/app-route';

interface ICategoryPaymentRedirectReturn {
  route: string;
  replace?: boolean;
}

export const useCategoryPaymentRedirect = (
  category: EPaymentCategory | null,
): ICategoryPaymentRedirectReturn => {
  const redirectUserTo: Record<EPaymentCategory, string> = {
    [EPaymentCategory.Electricity]: AppRoute.Home(),
    [EPaymentCategory.Cart]: AppRoute.Purchase(),
  };

  if (category) {
    return { route: redirectUserTo[category], replace: true };
  }

  return { route: AppRoute.Home(), replace: true };
};
