import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PageAffiliateProgram } from 'pages/PageAffiliateProgram';
import { PageAssets } from 'pages/PageAssets';
import { PageDashboard } from 'pages/PageDashboard';
import { PageDisable2FA } from 'pages/PageDisable2FA';
import { PageLogin } from 'pages/PageLogin';
import { PageMinerInfo } from 'pages/PageMinerInfo';
import { PageNotFound } from 'pages/PageNotFound';
import { PagePasswordReset } from 'pages/PagePasswordReset';
import { PagePayment } from 'pages/PagePayment/PagePayment';
import { PageProfile } from 'pages/PageProfile';
import { PersonalInfoForm } from 'pages/PageProfile/components/logic/PersonalInfoForm';
import { SecuritySettings } from 'pages/PageProfile/components/logic/SecuritySettings';
import { WalletAddressForm } from 'pages/PageProfile/components/logic/WalletAddressForm';
import { PagePurchase } from 'pages/PagePurchase';
import { InvoiceScreen } from 'pages/PagePurchase/components/logic/InvoiceScreen';
import { MinersScreen } from 'pages/PagePurchase/components/logic/MinersScreen';
import { PaymentSuccessScreen } from 'pages/PagePurchase/components/logic/PaymentSuccessScreen';
import { PageRegistration } from 'pages/PageRegistration';
import { PageTransactionsHistory } from 'pages/PageTransactionsHistory';
import { PageVerification } from 'pages/PageVerification';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';

import { InnerLayout } from 'components/logic/InnerLayout';
import { OuterLayout } from 'components/logic/OuterLayout';
import { RedirectToOuterRoute } from 'components/route-wrappers/RedirectToOuterRoute';
import { gtmEventsPageViewSender } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';

import { useViewportMetaContent } from './hooks/useViewportMetaContent';

export const App: React.FC = () => {
  const userAuthStatus = useSelector(selectUserAuthStatus);
  useViewportMetaContent();
  const location = useLocation();
  const pageName = location.pathname.split('/');

  useEffect(() => {
    gtmEventsPageViewSender({
      cityId: 0,
      cityName: 'not set',
      userAuth: userAuthStatus === 'authorized-verified' ? 1 : 0,
      userId: 0,
      pageType: pageName[pageName.length - 1] || 'dashboard',
    });
  }, [pageName, userAuthStatus]);

  switch (userAuthStatus) {
    case 'authorized-verified':
      return (
        <Routes>
          <Route
            path="*"
            element={
              <RedirectToOuterRoute>
                <Navigate to={AppRoute.Home()} />
              </RedirectToOuterRoute>
            }
          />
          <Route path={AppRoute.Home()} element={<InnerLayout />}>
            <Route index element={<PageDashboard />} />
            <Route path={AppRoute.Profile()} element={<PageProfile />}>
              <Route index element={<Navigate to={AppRoute.ProfileTab('personal')} replace />} />
              <Route path={AppRoute.ProfileTab('personal')} element={<PersonalInfoForm />} />
              <Route path={AppRoute.ProfileTab('wallet')} element={<WalletAddressForm />} />
              <Route path={AppRoute.ProfileTab('security')} element={<SecuritySettings />} />
            </Route>
            <Route path={AppRoute.Verification()} element={<PageVerification />} />
            <Route path={AppRoute.Disable2FA()} element={<PageDisable2FA />} />
            <Route path={AppRoute.Purchase()} element={<PagePurchase />}>
              <Route index element={<MinersScreen />} />
              <Route path={AppRoute.PurchaseCheckout()} element={<InvoiceScreen />} />
              <Route path={AppRoute.PurchaseSuccess()} element={<PaymentSuccessScreen />} />
              <Route path={AppRoute.PurchaseMinerInfo()} element={<PageMinerInfo />} />
            </Route>
            <Route path={AppRoute.Payment()} element={<PagePayment />} />
            <Route path={AppRoute.Assets()} element={<PageAssets />} />
            <Route path={AppRoute.TransactionsHistory()} element={<PageTransactionsHistory />} />
            <Route path={AppRoute.AffiliateProgram()} element={<PageAffiliateProgram />} />
            <Route path={AppRoute.NotFound()} element={<PageNotFound />} />
          </Route>
        </Routes>
      );
    case 'unauthorized':
    case 'email-verification-pending':
    case 'registration-success':
    case 'authentication-required':
      return (
        <Routes>
          <Route
            path="*"
            element={
              <RedirectToOuterRoute>
                <Navigate to={AppRoute.Home()} />
              </RedirectToOuterRoute>
            }
          />
          <Route path={AppRoute.Home()} element={<OuterLayout />}>
            <Route index element={<Navigate to={AppRoute.Login()} />} />
            <Route path={AppRoute.Login()} element={<PageLogin />} />
            <Route path={AppRoute.Registration()} element={<PageRegistration />} />
            <Route path={AppRoute.ReferralRegistration()} element={<PageRegistration />} />
            <Route path={AppRoute.PasswordReset()} element={<PagePasswordReset />} />
            <Route path={AppRoute.NotFound()} element={<PageNotFound />} />
          </Route>
        </Routes>
      );
    case 'anonymous':
      return null;
  }
};
