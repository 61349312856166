import { SECONDS_IN_MINUTE } from 'constants/time/seconds-in-minute.const';

export const getFormattedTimeDelay = (timeDelay: number | string): string => {
  const timeDelayNumber = Number(timeDelay);
  if (isNaN(timeDelayNumber) || timeDelayNumber < 0) {
    return '00:00';
  }
  const formattedMinutes = String(Math.floor(timeDelayNumber / SECONDS_IN_MINUTE)).padStart(2, '0');
  const formattedSeconds = (timeDelayNumber % SECONDS_IN_MINUTE).toFixed(0).padStart(2, '0');
  return `${formattedMinutes}:${formattedSeconds}`;
};
