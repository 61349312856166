import { FC } from 'react';
import { EColors } from 'styles/style-variables/colors';

import { SwitcherWrapper } from './components/ui/SwitcherWithTittle/SwitcherWrapper';
import { ESwitcherSize, ESwitcherVariants, IPropsSwitcher, Sizes } from './types/switcher-props';

export const Switcher: FC<IPropsSwitcher> = ({
  leftTittle,
  rightTittle,
  size = ESwitcherSize.small,
  background,
  disabledBackground,
  checked,
  disabled,
  variant,
  icon,
  onChange,
}): JSX.Element => {
  const preparedProps = {
    leftTittle,
    rightTittle,
    checked,
    onChange,
    disabled: disabled ?? false,
    background: background ?? EColors.Blue2NewBrand,
    disabledBackground: disabledBackground ?? EColors.Gray3,
    ...Sizes[size],
  };

  const switcherVariants: Record<ESwitcherVariants, JSX.Element> = {
    [ESwitcherVariants.withTittle]: (
      <SwitcherWrapper props={preparedProps} leftTittle={leftTittle} rightTittle={rightTittle} />
    ),
    [ESwitcherVariants.leftTittle]: (
      <SwitcherWrapper props={preparedProps} leftTittle={leftTittle} />
    ),
    [ESwitcherVariants.rightTittle]: (
      <SwitcherWrapper props={preparedProps} rightTittle={rightTittle} />
    ),
    [ESwitcherVariants.withIcon]: <SwitcherWrapper props={preparedProps} icon={icon} />,
  };

  if (!variant) return <SwitcherWrapper props={preparedProps} />;

  return switcherVariants[variant];
};
