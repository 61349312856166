import { EColors } from 'styles/style-variables/colors';

export enum ESwitcherVariants {
  withTittle = 'with-tittle',
  leftTittle = 'left-tittle',
  rightTittle = 'right-tittle',
  withIcon = 'with-icon',
}

export enum ESwitcherSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface IPropsSwitcher {
  variant?: ESwitcherVariants;
  leftTittle?: string | React.ReactElement;
  rightTittle?: string | React.ReactElement;
  size?: ESwitcherSize;
  disabledBackground?: EColors;
  background?: EColors;
  disabled?: boolean;
  icon?: React.ReactElement;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Sizes = {
  [ESwitcherSize.small]: { width: '26px', height: '12px', circle: '10px', margin: '1px' },
  [ESwitcherSize.medium]: { width: '32px', height: '16px', circle: '12px', margin: '2px' },
  [ESwitcherSize.large]: { width: '38px', height: '20px', circle: '16px', margin: '2px' },
};

export interface IPropsSwitcherRequired
  extends Required<
    Omit<IPropsSwitcher, 'variant' | 'leftTittle' | 'rightTittle' | 'size' | 'icon'>
  > {
  width: string;
  height: string;
  circle: string;
  margin: string;
  checked: boolean;
  icon?: React.ReactElement;
}

export interface IPropsStyledSwitcher {
  $switcherWidth: string;
  $switcherHeight: string;
  $switcherCircleSize: string;
  $switcherBackground: EColors;
  $switcherDisabledBackground?: EColors;
  $switcherCircleBackground?: EColors;
  $checked?: boolean;
  $circleMargin?: string;
}
