import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { TInvoices } from 'store/api/cuverse-api/invoice/types/invoice-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

/**
 * Calculates the total daily fee of pending miners.
 * @param minersData - An array of objects containing miner data.
 * @param cartItems - An array of objects containing purchase cart items.
 * @returns The total daily fee of pending miners.
 */
export const getTotalFeePendingMiners = (
  minersData: IMinerDataMapped[],
  cartItems: ICartItemMapped[],
): number => {
  const commonArray: { id: number; fee: number; quantity: number }[] = [];
  minersData.forEach((minerData) => {
    const cartItem = cartItems.find((item) => item.productId === minerData.id);
    if (cartItem) {
      commonArray.push({
        id: cartItem.productId,
        fee: minerData.dailyElectricityFee,
        quantity: cartItem.quantity,
      });
    }
  });
  const totalFee = commonArray.reduce((sum, item) => sum + item.fee * item.quantity, 0);
  return Number(formatUsdOutput(totalFee));
};

/**
 * Calculates the total tax.
 * @param invoices - An object of invoices.
 * @returns The total tax.
 */
export const getTax = (invoices: TInvoices | undefined): number => {
  return invoices ? Object.values(invoices).reduce((sum, item) => sum + item.tax, 0) : 0;
};
