import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Button } from 'components/ui/Button';

export const SuggestedAmountsList = styled.ul`
  display: flex;
  gap: 1px;
`;

export const SuggestedAmount = styled.li`
  width: 100%;

  &:first-child {

    & button {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }
  }

  &:last-child {

    & button {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
`;

export const AmountButton = styled(Button)`
  width: 100%;
  padding: 0;
  border-radius: 0;
  background-color: #f4f4f7;
  color: #272727;

  &:hover,
  &:focus {
    background-color: #eaeaea;
  }

  &:active,
  &:disabled {
    background-color: #a1a1a1;
    color: #fff;
  }
`;

export const AmountValuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
`;

export const AmountPeriodValue = styled.span<{ $days: string }>`
  font-size: 14px;
  white-space: nowrap;

  &::after {
    content: ' ${({ $days }) => $days}';
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    &::after {
      content: ' days';
      font-size: 10px;
    }
  }
`;
export const AmountUsdValue = styled.span`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
`;
