import { FC } from 'react';
import { FormApi } from 'final-form';
import { TPaymentsPerDays } from 'pages/PageDashboard/components/logic/DepositElectricityForm/hooks/usePaymentsPerDays';
import { IDepositElectricityFormValues } from 'pages/PageDashboard/components/ui/ElectricityAmountField/types/deposit-electricity-form-values.interface';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './SuggestedAmountList.styled';

interface ISuggestedAmountListProps {
  paymentsPerDays: TPaymentsPerDays;
  activeSuggestedAmount: number | null;
  form: FormApi<IDepositElectricityFormValues>;
  handleAmountButtonClick: (
    amount: number,
    period: number,
    form: FormApi<IDepositElectricityFormValues>,
  ) => void;
  t: (key: string) => string;
}

export const SuggestedAmountList: FC<ISuggestedAmountListProps> = ({
  paymentsPerDays,
  activeSuggestedAmount,
  form,
  handleAmountButtonClick,
  t,
}) => {
  return (
    <S.SuggestedAmountsList>
      {Object.entries(paymentsPerDays).map(([amountPeriod, { period, usdCost }]) => (
        <S.SuggestedAmount key={amountPeriod}>
          <S.AmountButton
            type="button"
            variant="primary-with-children"
            onClick={() => handleAmountButtonClick(usdCost, period, form)}
            disabled={usdCost === activeSuggestedAmount}
          >
            <S.AmountValuesWrapper>
              <S.AmountPeriodValue
                $days={t('replenish.days').toLocaleLowerCase()}
              >{`${period}`}</S.AmountPeriodValue>
              <S.AmountUsdValue>${formatUsdOutput(usdCost)}</S.AmountUsdValue>
            </S.AmountValuesWrapper>
          </S.AmountButton>
        </S.SuggestedAmount>
      ))}
    </S.SuggestedAmountsList>
  );
};
