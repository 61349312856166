import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const ScreenWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 24px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    flex-grow: 0;
    gap: 12px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    flex-grow: 0;
    gap: 12px;
  }
`;
