import React, { FC } from 'react';
import { ReactComponent as ConnectIcon } from 'assets/icons/miner-info/connectIcon.svg';
import { ReactComponent as FeeIcon } from 'assets/icons/miner-info/feeIcon.svg';
import { ReactComponent as MinerIcon } from 'assets/icons/miner-info/minerIcon.svg';
import { ReactComponent as TimeIcon } from 'assets/icons/miner-info/timeIcon.svg';

import * as S from './WorkFlowInfo.styled';

interface IWorkFlow {
  t: (key: string) => string;
}

export const WorkFlowInfo: FC<IWorkFlow> = ({ t }): React.ReactElement => {
  const workFlowSteps = [
    {
      icon: <MinerIcon />,
      text: t('minerInfo.buyStep'),
    },
    {
      icon: <ConnectIcon />,
      text: t('minerInfo.startStep'),
    },
    {
      icon: <FeeIcon />,
      text: t('minerInfo.feeStep'),
    },
    {
      icon: <TimeIcon />,
      text: t('minerInfo.runStep'),
    },
  ];

  return (
    <S.HowItWorksContainer>
      <S.HowItWorksTitle>{t('minerInfo.workFlowTitle')}</S.HowItWorksTitle>
      <S.HowItWorksInfo>
        {workFlowSteps.map((item) => (
          <S.Info key={item.text}>
            <span>{item.icon}</span>
            <S.Text>{item.text}</S.Text>
          </S.Info>
        ))}
      </S.HowItWorksInfo>
    </S.HowItWorksContainer>
  );
};
