import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const Miner = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 2fr;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;

  @media (max-width: ${BreakPoint.LaptopLow}) {
    padding: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-columns: 1fr;
    padding: 12px;
  }
`;

export const MinerPicture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 200px;
    height: 200px;

    @media (max-width: ${BreakPoint.MobileTop}) {
      width: 160px;
      height: 160px;
    }
  }
`;

export const OptionsContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

export const Text = styled.p`
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
`;

export const Options = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 24px;
  border-radius: 16px;
  background-color: #f4f4f7;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
`;
