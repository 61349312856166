import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';

import { AppRoute } from 'utils/route/app-route';

import * as S from './PageNotFound.styled';

export const PageNotFound: React.FC = () => {
  const userAuthStatus = useSelector(selectUserAuthStatus);
  const isUserAuthorised = userAuthStatus === 'authorized-verified';
  const { t } = useTranslation();

  return (
    <S.Container $hasBackground={isUserAuthorised}>
      <S.NotFoundCard>
        <S.NotFoundCode>404</S.NotFoundCode>
        <S.NotFoundTitle>{t('notFound.title')}</S.NotFoundTitle>
        <S.LinkContainer>
          <S.NavigationLink linkTo={AppRoute.Home()} text={t('notFound.buttonText')} />
        </S.LinkContainer>
      </S.NotFoundCard>
    </S.Container>
  );
};
