import { useEffect, useState } from 'react';
import { TFetchStatus } from 'types/api/fetch-status.type';

export const useUnloadStatus = (paymentStatus: TFetchStatus): { isLoading: boolean } => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (paymentStatus === 'pending') {
      setIsLoading(true);
    }

    if (paymentStatus === 'fulfilled') {
      setIsLoading(true);
      window.addEventListener('beforeunload', () => {
        setIsLoading(true);
      });
    }

    if (paymentStatus === 'rejected') {
      setIsLoading(false);
    }

    return () => {
      window.removeEventListener('beforeunload', () => {
        setIsLoading(false);
      });
    };
  }, [paymentStatus]);

  return { isLoading };
};
