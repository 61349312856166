import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  selectReferralLink,
  selectReferralLinkFetchStatus,
  selectReferralStats,
} from 'store/affiliate-reducer/affiliate.selectors';
import {
  fetchReferralLinksThunkAction,
  fetchReferralStatsThunkAction,
} from 'store/affiliate-reducer/affiliate.thunk-actions';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { fetchPairRatesThunkAction } from 'store/rates-reducer/rates.thunk-actions';
import { useAppDispatch } from 'store/store';

import { SPage } from 'components/styled/SPage';
import { copyText } from 'utils/common/copy-text';
import { gtmClickCopyAffiliateLink } from 'utils/gtmSender/gtmSender';

import { HistorySection } from './components/logic/HistorySection';
import { InfoSection } from './components/ui/InfoSection';
import { CUVERSE_BASE_URL } from './constants/cuverse-base-url.const';

export const PageAffiliateProgram: React.FC = () => {
  const dispatch = useAppDispatch();
  const { totalReferrals, totalProfit } = useSelector(selectReferralStats);
  const btcToUsdRate = useSelector(selectBtcUsdRate);
  const referralLink = useSelector(selectReferralLink);
  const referralLinkFetchStatus = useSelector(selectReferralLinkFetchStatus);
  const isReferralLinkPending = referralLinkFetchStatus === 'pending';
  const { t } = useTranslation();

  const affiliateLink = `${CUVERSE_BASE_URL}${referralLink}`;

  const copyAffiliateLink = () => {
    copyText(affiliateLink);
    gtmClickCopyAffiliateLink();
  };

  useEffect(() => {
    void dispatch(fetchReferralStatsThunkAction());
    void dispatch(fetchPairRatesThunkAction());
    void dispatch(fetchReferralLinksThunkAction());
  }, [dispatch]);

  return (
    <SPage
      headerComponent={
        <InfoSection
          btcEarned={totalProfit}
          btcToUsdRate={btcToUsdRate}
          referralsAmount={totalReferrals}
          affiliateLink={affiliateLink}
          handleCopyClick={copyAffiliateLink}
          isReferralLinkPending={isReferralLinkPending}
          t={t}
        />
      }
    >
      <HistorySection />
    </SPage>
  );
};
