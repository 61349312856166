import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';

import { TButtonVariant } from 'components/ui/Button/Button.types';
import { usePromoStatusAttributes } from 'components/ui/PromoCodeArea/hooks/usePromoStatusAttributes';
import { IPromoCodeAreaValue } from 'components/ui/PromoCodeArea/types/promo-code-area-value.interface';
import { removeNonAlphanumericSymbols } from 'utils/formatters/remove-non-alphanumeric-symbols.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isRequired } from 'utils/validators/is-required';

import { MAX_PROMO_LENGTH } from './constants/max-promo-length.const';

import * as S from './PromoCodeArea.styled';

interface IPromoCodeAreaProps {
  promoStatus: EPromoCodeStatus;
  bottomBordered?: boolean;
  variant?: TButtonVariant;
  className?: string;
  handlePromoCodeButtonClick?: () => void;
  handlePromoCodeSubmit?: (value: IPromoCodeAreaValue, form: FormApi<IPromoCodeAreaValue>) => void;
  handleCloseButtonClick?: () => void;
  t: (t: string) => string;
}

export const PromoCodeArea: React.FC<IPromoCodeAreaProps> = ({
  promoStatus,
  variant,
  bottomBordered = true,
  className,
  handlePromoCodeButtonClick,
  handlePromoCodeSubmit = () => null,
  handleCloseButtonClick,
  t,
}) => {
  const { leftIcon, text, closeButton } = usePromoStatusAttributes(
    promoStatus,
    handleCloseButtonClick,
  );

  const handlePromoCodeInputChange = (value: string, form: FormApi<IPromoCodeAreaValue>) =>
    form.change('promoCode', removeNonAlphanumericSymbols(value));

  switch (promoStatus) {
    case EPromoCodeStatus.Button:
      return (
        <S.PromoInitialWrapper $bottomBordered={bottomBordered} className={className}>
          <S.PromoButton
            className="promo-enter-button"
            type="button"
            variant="borderless"
            text={t('purchase.enterPromoCodeText')}
            onClick={handlePromoCodeButtonClick}
          />
        </S.PromoInitialWrapper>
      );
    case EPromoCodeStatus.Form:
      return (
        <Form onSubmit={handlePromoCodeSubmit} className={className}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
            <S.PromoForm onSubmit={handleSubmit} $isFailed={submitFailed}>
              <Field name="promoCode" type="input" validate={composeValidators([isRequired])}>
                {({ input, meta }) => (
                  <S.InputWrapper className="promo-input-wrapper">
                    <S.PromoInput
                      className="promo-input"
                      {...input}
                      type="text"
                      placeholder={t('purchase.promoCodeText')}
                      autoFocus={true}
                      hasErrors={meta.error && submitFailed}
                      onChange={(evt) => handlePromoCodeInputChange(evt.currentTarget.value, form)}
                      maxLength={MAX_PROMO_LENGTH}
                    />
                    {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                  </S.InputWrapper>
                )}
              </Field>
              <S.SendingButton
                onClick={() => handlePromoCodeSubmit(form.getState().values, form)}
                type="submit"
                text="Send"
                variant="primary"
                disabled={submitFailed && hasValidationErrors}
              />
            </S.PromoForm>
          )}
        </Form>
      );
    case EPromoCodeStatus.Checking:
    case EPromoCodeStatus.Accepted:
    case EPromoCodeStatus.Rejected:
      return (
        <S.PromoStatus
          $promoStatus={promoStatus}
          className={className}
          $bottomBordered={bottomBordered}
        >
          {leftIcon}
          <S.ConfirmationText $promoStatus={promoStatus}>{text}</S.ConfirmationText>
          <S.CloseButtonWrapper>{closeButton}</S.CloseButtonWrapper>
        </S.PromoStatus>
      );
  }
};
