export enum EColors {
  Blue1OldBrand = '#03349E',
  Blue2NewBrand = '#0E46C0',
  Blue3 = '#3069E5',
  Blue4 = '#6A96F6',
  Blue5 = '#D8EEFF',
  BlueGrayBack = '#F4F4F7',
  BlueGrayBackDark = '#E5EAF1',
  BlueGrayBackMid = '#EEF3FA',
  Gray1Text = '#272727',
  Gray2 = '#646464',
  Gray3 = '#A1A1A1',
  Gray4 = '#C9C9C9',
  Gray5 = '#EAEAEA',
  Gray6 = '#F5F5F5',
  Green1 = '#00BC81',
  Green2 = '#95FAC5',
  Green3 = '#E0FBED',
  Red1 = '#EC494F',
  Red2 = '#FCE7E7',
  Yellow1 = '#F0B90B',
  Yellow2 = '#FFE27A',
  Yellow3 = '#FFF6D9',
  White = '#ffffff',
  Black = '#000000',
  SiteBack = '#E0E5ED',
}
