import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SECOND_IN_MILLISECONDS } from 'constants/time/second-in-milliseconds.const';
import { SECONDS_IN_MINUTE } from 'constants/time/seconds-in-minute.const';
import { DepositBtcForm } from 'pages/PageDashboard/components/ui/DepositBtcForm';
import { DepositBtcPayment } from 'pages/PageDashboard/components/ui/DepositBtcPayment';
import { setDepositStepAction } from 'store/deposit-reducer/deposit.reducer';
import {
  selectDepositData,
  selectDepositDataFetchStatus,
  selectDepositStep,
  selectPaymentFetchStatus,
  selectPaymentStatus,
} from 'store/deposit-reducer/deposit.selectors';
import {
  requestDepositThunkAction,
  requestPaymentStatusThunkAction,
} from 'store/deposit-reducer/deposit.thunk-actions';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { useAppDispatch } from 'store/store';
import { IDepositBtcFormValues } from 'types/ui/DepositBtcForm/deposit-btc-form-values.interface';
import { EPaymentStatus } from 'types/ui/PaymentStatus/payment-status.enum';

import { gtmClickDepositProcess } from 'utils/gtmSender/gtmSender';

interface IProps {
  t: (key: string) => string;
}

export const DepositBtcContent: React.FC<IProps> = ({ t }) => {
  const dispatch = useAppDispatch();
  const resendRequestIntervalRef = useRef<ReturnType<typeof setInterval>>();
  const btcUsdRate = useSelector(selectBtcUsdRate);
  const depositStep = useSelector(selectDepositStep);
  const depositData = useSelector(selectDepositData);
  const depositDataFetchStatus = useSelector(selectDepositDataFetchStatus);
  const depositPaymentStatus = useSelector(selectPaymentStatus);
  const depositPaymentFetchStatus = useSelector(selectPaymentFetchStatus);

  const isDepositDataPending = depositDataFetchStatus === 'pending';
  const isPaymentStatusPending = depositPaymentFetchStatus === 'pending';
  const autoUpdateInterval = SECONDS_IN_MINUTE * SECOND_IN_MILLISECONDS;

  const [amountToPay, setAmountToPay] = useState('');

  const handleDepositBtcFormSubmit = (values: IDepositBtcFormValues) => {
    if (!isDepositDataPending) {
      void dispatch(requestDepositThunkAction({ coin: 'BTC', amount: Number(values.amount) }));
      setAmountToPay(values.amount);
      gtmClickDepositProcess('Continue');
    }
  };

  const handleStatusCheck = () =>
    void dispatch(requestPaymentStatusThunkAction({ coin: 'BTC', address: depositData.address }));

  const handleRefreshAddress = () => {
    if (!isDepositDataPending) {
      void dispatch(requestDepositThunkAction({ coin: 'BTC', amount: Number(amountToPay) }));
    }
  };

  const handleFinishButtonClick = () => {
    dispatch(closePopupAction());
    dispatch(setDepositStepAction('initial'));
    gtmClickDepositProcess('Finish');
  };

  useEffect(() => {
    if (
      depositPaymentStatus !== EPaymentStatus.Paid &&
      depositPaymentStatus !== EPaymentStatus.Expired
    ) {
      resendRequestIntervalRef.current = setInterval(() => {
        void dispatch(
          requestPaymentStatusThunkAction({ coin: 'BTC', address: depositData.address }),
        );
      }, autoUpdateInterval);
    }
    return () => clearInterval(resendRequestIntervalRef.current);
  }, [dispatch, depositData.address, depositPaymentStatus, autoUpdateInterval]);

  return depositStep === 'initial' ? (
    <DepositBtcForm
      btcUsdRate={btcUsdRate}
      handleFormSubmit={handleDepositBtcFormSubmit}
      isDepositDataPending={isDepositDataPending}
      t={t}
    />
  ) : (
    <DepositBtcPayment
      amountToPay={depositData.amount}
      walletAddress={depositData.address}
      qrCodeUrl={depositData.code}
      paymentStatus={depositPaymentStatus}
      handleStatusCheck={handleStatusCheck}
      handleRefreshAddress={handleRefreshAddress}
      handleFinishButtonClick={handleFinishButtonClick}
      isDepositDataPending={isDepositDataPending}
      isPaymentStatusPending={isPaymentStatusPending}
    />
  );
};
