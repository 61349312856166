import React, { FC } from 'react';
import { ReactComponent as Energy } from 'assets/icons/miner-info/energy.svg';
import { ReactComponent as HashRate } from 'assets/icons/miner-info/hashRate.svg';
import { ReactComponent as Profit } from 'assets/icons/miner-info/profit.svg';
import { ReactComponent as RoiIcon } from 'assets/icons/miner-info/roi.svg';

import { formatToNumberWithSpaces } from 'utils/formatters/format-to-number-with-spaces.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './MinerOptions.styled';

interface IMinerOptions {
  hashRate: number;
  energyEfficiency: string;
  annualRoi: number;
  monthlyRevenue: number;
  picture: string;
  t: (key: string) => string;
}

export const MinerOptions: FC<IMinerOptions> = ({
  hashRate,
  energyEfficiency,
  annualRoi,
  monthlyRevenue,
  picture,
  t,
}): React.ReactElement => {
  const options = [
    {
      icon: <HashRate />,
      label: t('minerInfo.hashRate'),
      value: `${formatToNumberWithSpaces(hashRate, 2)} TH/s`,
    },
    {
      icon: <Energy />,
      label: t('minerInfo.energyEfficiency'),
      value: `${formatToNumberWithSpaces(energyEfficiency, 2)} W/THs`,
    },
    {
      icon: <RoiIcon />,
      label: t('minerInfo.annualRoi'),
      value: `up to ${annualRoi.toFixed(2)}%`,
    },
    {
      icon: <Profit />,
      label: t('minerInfo.monthlyProfit'),
      value: `up to $${formatUsdOutput(monthlyRevenue)}`,
    },
  ];

  return (
    <S.Miner>
      <S.MinerPicture>
        <img width={200} height={200} src={picture} alt="miner" />
      </S.MinerPicture>
      <S.OptionsContainer>
        {options.map((item) => (
          <S.Options key={item.label}>
            <div>{item.icon}</div>
            <p>{item.label}</p>
            <p>{item.value}</p>
          </S.Options>
        ))}
      </S.OptionsContainer>
    </S.Miner>
  );
};
