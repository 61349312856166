import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IUptimeState } from './uptime.reducer';

export const getUptimeState = (state: TRootState): IUptimeState => state.uptimeReducer;

export const selectUptime = createSelector([getUptimeState], (uptimeState) => ({
  uptime: uptimeState.uptime,
}));

export const selectLastUptimeToggle = createSelector([getUptimeState], (uptimeState) => ({
  lastUptimeToggle: uptimeState.lastUptimeToggle,
}));

export const selectCooldown = createSelector([getUptimeState], (uptimeState) => ({
  cooldown: uptimeState.isCooldownActive,
}));

export const selectUptimeStatus = createSelector([getUptimeState], (uptimeState) => ({
  uptimeStatus: uptimeState.uptimeStatus,
}));
