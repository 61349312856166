import { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { ReactComponent as BackIcon } from 'assets/icons/link-icons/back-icon.svg';
import { FormApi } from 'final-form';
import { IPasswordResetFormValues } from 'types/ui/PasswordResetForm/password-reset-form-values.interface';

import { Input } from 'components/ui/Input';
import { removeSpacesAndEmojis } from 'utils/formatters/remove-spaces-and-emojis.util';
import { gtmClickSendRequestResetPassword } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';
import { composeValidators } from 'utils/validators/compose-validators';
import { MAX_EMAIL_LENGTH } from 'utils/validators/constants/validation.constants';
import { isNotTooLong } from 'utils/validators/is-not-too-long';
import { isRequired } from 'utils/validators/is-required';
import { isValidEmail } from 'utils/validators/is-valid-email';

import { IPasswordResetFormProps } from './types/password-reset-form-props.interface';

import * as S from './PasswordResetForm.styled';

export const PasswordResetForm: FC<IPasswordResetFormProps> = ({
  handleFormSubmit,
  isSubmitting = false,
  hasBackendError = false,
  t,
}) => {
  const handleEmailChange = (
    value: string,
    form: FormApi<IPasswordResetFormValues, Partial<IPasswordResetFormValues>>,
  ) => {
    form.change('email', removeSpacesAndEmojis(value));
  };

  return (
    <S.PasswordResetSection>
      <S.MainContentWrapper>
        <S.Title>{t('passwordReset.title')}</S.Title>

        <S.BackLink
          linkTo={AppRoute.Login()}
          text={t('passwordReset.returnLinkText')}
          icon={<BackIcon />}
        />

        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
            <S.Form onSubmit={handleSubmit} $isFailed={submitFailed || hasBackendError}>
              <Field
                name="email"
                validate={composeValidators([
                  isRequired,
                  isValidEmail,
                  isNotTooLong(MAX_EMAIL_LENGTH, 'email'),
                ])}
              >
                {({ input, meta }) => (
                  <S.InputLabel>
                    <S.LabelText>{t('passwordReset.emailText')}</S.LabelText>
                    <Input
                      {...input}
                      type="text"
                      hasErrors={meta.error && submitFailed}
                      onChange={(evt) => handleEmailChange(evt.currentTarget.value, form)}
                      hasValidationIcons={true}
                    />
                    {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                  </S.InputLabel>
                )}
              </Field>

              <S.SubmitButton
                type="submit"
                text="Send request"
                disabled={(submitFailed && hasValidationErrors) || isSubmitting}
                onClick={() => gtmClickSendRequestResetPassword()}
              />
            </S.Form>
          )}
        </Form>
      </S.MainContentWrapper>

      <S.FieldRequiredDescription>
        {t('passwordReset.requiredFieldText')}
      </S.FieldRequiredDescription>
    </S.PasswordResetSection>
  );
};
