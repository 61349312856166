import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import {
  createCartThunkAction,
  deleteCartItemThunkAction,
  updateAllCartThunkAction,
  updateCartThunkAction,
} from 'store/cart-reducer/cart.thunk-actions';
import { selectCart, selectCartFetchStatus } from 'store/cart-reducer/cart-selectors';
import { useAppDispatch } from 'store/store';

import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { gtmClickCart } from 'utils/gtmSender/gtmSender';

interface IUseMinerCardButtonProps {
  minerData: IMinerDataMapped;
}

interface IUseMinerCardButtonReturn {
  handleIncrement: () => void;
  handleDecrement: () => void;
  handleInputChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => void;
  handleOnFocus: () => void;
  handleBlur: () => void;
  handleMouseOver: () => void;
  handleMouseLeave: () => void;
  quantity?: number;
  isActualSoldOut: boolean;
  availableMinersNumber: number;
  inputValue: number;
  mouseOnButton: boolean;
  isCountInitial: boolean;
  isFocused: boolean;
  cartId: number;
}

export const useMinerCardButton = ({
  minerData,
}: IUseMinerCardButtonProps): IUseMinerCardButtonReturn => {
  const dispatch = useAppDispatch();
  const { cartId, cartItems } = useSelector(selectCart);
  const cartFetchStatus = useSelector(selectCartFetchStatus);
  const [mouseOnButton, setMouseOnButton] = useState(false);
  const [isCountInitial, setIsCountInitial] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const cartListItem = cartItems.find((item) => item.productId === minerData.id);
  const { id, dailyElectricityFee, model, hashrate, price, isSoldOut } = minerData;
  const initialIsSoldOut = cartListItem?.maxQuantity === 0 || isSoldOut;
  const [isActualSoldOut, setIsActualSoldOut] = useState(initialIsSoldOut);
  const [availableMinersNumber, setAvailableMinersNumber] = useState(0);
  const [inputValue, setInputValue] = useState(() => {
    return cartListItem?.quantity || 0;
  });
  const isCartPending = cartFetchStatus === 'pending';
  const isCartExist = !!cartId;

  const handleIncrement = () => {
    if (cartListItem && cartListItem?.quantity >= cartListItem?.maxQuantity) return;
    if (isCartPending) return;

    gtmClickCart(model, id, price, model.split(' ')[0], hashrate, dailyElectricityFee);
    isCartExist
      ? void dispatch(updateCartThunkAction({ cartId, id })).then(() => {
          setIsCountInitial(true);
        })
      : void dispatch(createCartThunkAction(id)).then(() => {
          setIsCountInitial(true);
        });
  };

  const handleDecrement = () => {
    if (!cartListItem?.quantity) return;
    if (isCartPending) return;

    void dispatch(deleteCartItemThunkAction({ cartId, id })).then(() => setIsCountInitial(true));
  };

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(removeNonNumericSymbols(evt.target.value));

    setInputValue(isNaN(value) ? 0 : Math.min(cartListItem?.maxQuantity || 0, value));
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key !== 'Enter') return;

    if (isCartPending) return;
    const updatedCartItems = cartItems.map((item) => {
      const { productId, quantity } = item;

      if (productId === id)
        return {
          quantity: Math.min(cartListItem?.maxQuantity || 0, inputValue),
          product_id: item.productId,
        };
      return { product_id: productId, quantity };
    });

    void dispatch(updateAllCartThunkAction({ cartId, body: { cart_items: updatedCartItems } }));
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    if (isCountInitial) return;
    setIsFocused(false);
    if (isCartPending) return;
    const updatedCartItems = cartItems.map((item) => {
      const { productId, quantity } = item;

      if (productId === id)
        return {
          quantity: Math.min(cartListItem?.maxQuantity || 0, inputValue),
          product_id: item.productId,
        };
      return { product_id: productId, quantity };
    });

    void dispatch(updateAllCartThunkAction({ cartId, body: { cart_items: updatedCartItems } }));
  };

  const handleMouseOver = () => setMouseOnButton(true);
  const handleMouseLeave = () => {
    setMouseOnButton(false);
    setIsCountInitial(false);
  };

  useEffect(() => {
    setInputValue(cartListItem?.quantity || 0);
  }, [cartListItem?.quantity]);

  useLayoutEffect(() => {
    if (cartListItem) {
      const minersInStock = cartListItem.maxQuantity;
      const minersInCart = cartListItem.quantity;
      const isActualSoldOut = cartListItem.maxQuantity === 0;

      setAvailableMinersNumber(minersInStock - minersInCart);
      setIsActualSoldOut(isActualSoldOut);
    }
  }, [cartListItem, cartListItem?.maxQuantity, cartListItem?.quantity]);

  return {
    quantity: cartListItem?.quantity,
    inputValue,
    mouseOnButton,
    isFocused,
    isCountInitial,
    isActualSoldOut,
    availableMinersNumber,
    handleIncrement,
    handleDecrement,
    handleInputChange,
    handleOnFocus,
    handleKeyDown,
    handleBlur,
    handleMouseOver,
    handleMouseLeave,
    cartId,
  };
};
