import { FC } from 'react';
import { Field } from 'react-final-form';
import { FormApi } from 'final-form';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { composeValidators } from 'utils/validators/compose-validators';
import { isGreaterOrEqual } from 'utils/validators/is-greater-or-equal';
import { isLessOrEqual } from 'utils/validators/is-less-or-equal';
import { isNumber } from 'utils/validators/is-number';
import { isRequired } from 'utils/validators/is-required';

import {
  MAXIMUM_REPLENISH_AMOUNT_DAYS,
  MAXIMUM_REPLENISH_AMOUNT_USD,
} from './constants/maximum-replenish-amount';
import {
  MINIMUM_REPLENISH_AMOUNT_DAYS,
  MINIMUM_REPLENISH_AMOUNT_USD,
} from './constants/minimum-replenish-amount';
import { IDepositElectricityFormValues } from './types/deposit-electricity-form-values.interface';

import * as S from './AmountField.styled';

interface IAmountFieldProps {
  submitFailed: boolean;
  activeDayAmount: number | null;
  oneDayAmount: number;
  isSwitcherOn: boolean;
  isReplenishPending: boolean;
  form: FormApi<IDepositElectricityFormValues>;
  handleInputChange: (value: string, form: FormApi<IDepositElectricityFormValues>) => void;
  t: (key: string) => string;
}

const calculateUsdContent = (
  isSwitcherOn: boolean,
  activeDayAmount: number | null,
  inputValue: string,
  oneDayAmountValue: number,
) => {
  if (isSwitcherOn) return '';
  if (activeDayAmount) return `$ ${formatUsdOutput(activeDayAmount * oneDayAmountValue)}`;
  return `$ ${formatUsdOutput(+inputValue * oneDayAmountValue)}`;
};

const calculateDaysContent = (
  isSwitcherOn: boolean,
  activeDayAmount: number | null,
  inputValue: string,
  oneDayAmountValue: number,
  t: (key: string) => string,
) => {
  if (!isSwitcherOn) return '';
  if (activeDayAmount)
    return `${formatUsdOutput(activeDayAmount)} ${t('replenish.days').toLocaleLowerCase()}`;

  const calculatedDays = +inputValue / oneDayAmountValue;
  return `${formatUsdOutput(calculatedDays)} ${t('replenish.days').toLocaleLowerCase()}`;
};

const calculateInputValue = (
  isSwitcherOn: boolean,
  activeDayAmount: number | null,
  inputValue: string,
) => {
  if (activeDayAmount) return isSwitcherOn ? inputValue : activeDayAmount;
  return inputValue;
};

export const ElectricityAmountField: FC<IAmountFieldProps> = ({
  submitFailed,
  activeDayAmount,
  oneDayAmount,
  isSwitcherOn,
  isReplenishPending,
  form,
  handleInputChange,
  t,
}) => {
  const oneDayAmountValue = oneDayAmount || 1;

  return (
    <Field
      name="amount"
      validate={composeValidators([
        isRequired,
        isNumber,
        isGreaterOrEqual(
          isSwitcherOn ? MINIMUM_REPLENISH_AMOUNT_USD : MINIMUM_REPLENISH_AMOUNT_DAYS,
        ),
        isLessOrEqual(isSwitcherOn ? MAXIMUM_REPLENISH_AMOUNT_USD : MAXIMUM_REPLENISH_AMOUNT_DAYS),
      ])}
    >
      {({ input, meta }) => {
        return (
          <S.InputLabel $isFailed={submitFailed}>
            <S.AmountInput
              $isSwitcherOn={isSwitcherOn}
              $usdContent={calculateUsdContent(
                isSwitcherOn,
                activeDayAmount,
                input.value,
                oneDayAmountValue,
              )}
              $daysContent={calculateDaysContent(
                isSwitcherOn,
                activeDayAmount,
                input.value,
                oneDayAmountValue,
                t,
              )}
              value={calculateInputValue(isSwitcherOn, activeDayAmount, input.value)}
              type="text"
              hasErrors={meta.error && submitFailed}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(evt.currentTarget.value, form)
              }
              autoComplete="off"
              isDisabled={isReplenishPending}
            />
            {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
          </S.InputLabel>
        );
      }}
    </Field>
  );
};
