import React from 'react';
import { ReactComponent as Miner } from 'assets/icons/assets-page-icons/miner.svg';
import { MobileGroupByStatus } from 'pages/PageAssets/components/ui/MobileGroupByStatus';
import { TMinersGroupByModel } from 'pages/PageAssets/types/miners-group-by-model.type';

import * as S from './MinersGroupByModel.styled';

interface IMinersGroupByModelProps {
  minersGroupByModel: TMinersGroupByModel;
  btcToUsdRate: number;
  t: (key: string) => string;
}

export const MinersGroupByModel: React.FC<IMinersGroupByModelProps> = ({
  minersGroupByModel,
  btcToUsdRate,
  t,
}) => {
  const [modelName] = Object.keys(minersGroupByModel);
  const rowsByStatus = minersGroupByModel[modelName];
  const { active: activeMiners, pending: pendingMiners, disabled: disabledMiners } = rowsByStatus;

  return (
    <S.GroupByModelWrapper>
      <S.GroupTitle as="h2">
        <Miner />
        <S.TitleText>{modelName}</S.TitleText>
      </S.GroupTitle>
      {activeMiners && (
        <MobileGroupByStatus
          groupData={activeMiners}
          btcToUsdRate={btcToUsdRate}
          isFirstOfModel
          t={t}
        />
      )}
      {pendingMiners && (
        <MobileGroupByStatus
          groupData={pendingMiners}
          btcToUsdRate={btcToUsdRate}
          isFirstOfModel={!activeMiners}
          t={t}
        />
      )}
      {disabledMiners && (
        <MobileGroupByStatus
          groupData={disabledMiners}
          btcToUsdRate={btcToUsdRate}
          isFirstOfModel={!activeMiners && !pendingMiners}
          t={t}
        />
      )}
    </S.GroupByModelWrapper>
  );
};
