import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const UptimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  font-family: ${FontFamily.Montserrat};
`;

export const Hr = styled.hr`
  width: 100%;
  opacity: 0.3;
  color: #a1a1a1;
`;

export const UptimeTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;

  @media (max-width: ${BreakPoint.LaptopLow}) {
    font-size: 18px;
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: #0e46c0;
  cursor: pointer;
`;

export const UptimeHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 16px;
`;

export const DesktopColumnsLabels = styled.tr`
  @media (max-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;

export const MobileColumnsLabels = styled.tr`
  @media (min-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;

export const CellValues = styled.td`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #272727;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 14px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
  }
`;

export const DesktopValuesWrapper = styled.tr`
  @media (max-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;

export const MobileValuesWrapper = styled.tr`
  @media (min-width: ${BreakPoint.MobileTop}) {
    display: none;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const HeadCell = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  white-space: nowrap;
  color: #646464;
  justify-self: flex-start;
  font-variant-numeric: lining-nums tabular-nums;
`;
