import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCartList,
  deleteCart,
  deleteCartListItem,
  multipleUpdateCartList,
  requestCartList,
  updateCart,
  updateCartList,
} from 'store/api/cuverse-api/cart/cart.service';
import {
  ICartDataMapped,
  IUpdateCartBodyRequest,
} from 'store/api/cuverse-api/cart/types/cart-response.interface';

import { mapCartData } from 'utils/mapper/mapper';
import { notifyError, notifySuccess } from 'utils/notify/notify.utils';

export const fetchCartThunkAction = createAsyncThunk(
  'miners/fetchCart',
  async (): Promise<ICartDataMapped> => {
    return await requestCartList()
      .then((res) => mapCartData(res))
      .catch((error) => {
        throw error;
      });
  },
);

export const createCartThunkAction = createAsyncThunk(
  'miners/createCart',
  async (id: number): Promise<ICartDataMapped> => {
    return await createCartList(id)
      .then((res) => mapCartData(res))
      .catch((error) => {
        notifyError('Cannot create cart');
        throw error;
      });
  },
);

export const updateCartThunkAction = createAsyncThunk(
  'miners/updateCart',
  async ({ cartId, id }: { cartId: number; id: number }): Promise<ICartDataMapped> => {
    return await updateCartList(cartId, id)
      .then((res) => mapCartData(res))
      .catch((error) => {
        notifyError('Cannot update cart');
        throw error;
      });
  },
);

export const updateAllCartThunkAction = createAsyncThunk(
  'miners/updateAllCart',
  async ({
    cartId,
    body,
  }: {
    cartId: number;
    body: IUpdateCartBodyRequest;
  }): Promise<ICartDataMapped> => {
    return await updateCart(cartId, body)
      .then((res) => mapCartData(res))
      .catch((error) => {
        notifyError('Cannot update cart');
        throw error;
      });
  },
);

export const multipleUpdateCartThunkAction = createAsyncThunk(
  'miners/multipleUpdateCart',
  async ({
    data,
    cartId,
  }: {
    data: { product_id: number; quantity: number }[];
    cartId: number;
  }): Promise<ICartDataMapped> => {
    return await multipleUpdateCartList(data, cartId)
      .then((res) => {
        notifySuccess('Cart updated');
        return mapCartData(res);
      })
      .catch((error) => {
        notifyError('Cannot multiple update cart');
        throw error;
      });
  },
);

export const deleteCartItemThunkAction = createAsyncThunk(
  'miners/deleteCartItem',
  async ({ cartId, id }: { cartId: number; id: number }): Promise<ICartDataMapped> => {
    return await deleteCartListItem(cartId, id)
      .then((res) => mapCartData(res))
      .catch((error) => {
        notifyError('Cannot delete item');
        throw error;
      });
  },
);

export const deleteAllCartItemsThunkAction = createAsyncThunk(
  'miners/deleteAllCartItems',
  async ({ cartId }: { cartId: number }): Promise<void> => {
    await deleteCart(cartId).catch((error) => {
      notifyError('Cannot delete cart');
      throw error;
    });
  },
);
