import React from 'react';

import * as S from './MinersHeader.styled';

interface IProps {
  t: (key: string) => string;
}

export const MinersHeader: React.FC<IProps> = ({ t }) => {
  return (
    <>
      <S.Title>{t('purchase.buyMinerTitle')}</S.Title>
    </>
  );
};
