import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { HistoryTableDesktop } from 'pages/PageAffiliateProgram/components/ui/HistoryTableDesktop/HistoryTableDesktop';
import { HistoryTableMobile } from 'pages/PageAffiliateProgram/components/ui/HistoryTableMobile/HistoryTableMobile';
import { TOTAL_HISTORY_ITEMS_ON_PAGE } from 'pages/PageAffiliateProgram/constants/referral-history.consts';
import {
  selectReferralHistory,
  selectReferralLinkFetchStatus,
} from 'store/affiliate-reducer/affiliate.selectors';
import { fetchReferralHistory } from 'store/affiliate-reducer/affiliate.thunk-actions';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SPageSection } from 'components/styled/SPageSection';
import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';
import { Pagination } from 'components/ui/Pagination';

import * as S from './HistorySection.styled';

export const HistorySection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { innerWidth } = useInnerWidth();
  const [currentPage, setCurrentPage] = useState(1);
  const referralHistoryStatus = useSelector(selectReferralLinkFetchStatus);
  const referralHistoryData = useSelector(selectReferralHistory);
  const { t } = useTranslation();
  const isPending = referralHistoryStatus === 'pending';
  const handleSetCurrentPage = (newPage: number): void => {
    if (newPage <= 0) {
      setCurrentPage(1);
    } else if (newPage > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(newPage);
    }
  };

  const totalPages = referralHistoryData
    ? Math.ceil(referralHistoryData?.length / TOTAL_HISTORY_ITEMS_ON_PAGE)
    : 1;

  const slicedItems =
    referralHistoryData?.slice(
      (currentPage - 1) * TOTAL_HISTORY_ITEMS_ON_PAGE,
      currentPage * TOTAL_HISTORY_ITEMS_ON_PAGE,
    ) || [];

  useEffect(() => {
    void dispatch(fetchReferralHistory());
  }, [dispatch]);
  return (
    <SPageSection tittleCeo="Referral history">
      {innerWidth >= parseInt(BreakPoint.TabletLow) ? (
        <HistoryTableDesktop items={slicedItems} isLoading={isPending} />
      ) : (
        <HistoryTableMobile items={slicedItems} isLoading={isPending} />
      )}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleSetCurrentPage={handleSetCurrentPage}
          t={t}
        />
      )}
    </SPageSection>
  );
};
