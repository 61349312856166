import React from 'react';
import { ReactComponent as BasicVerifiedIcon } from 'assets/icons/verify-icons/basic-verification-icon.svg';
import { ReactComponent as FullVerifiedIcon } from 'assets/icons/verify-icons/full-verification-icon.svg';

interface IVerificationPanelDescription {
  text: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  buttonText: string;
}

/**
 * Verification panel text
 * @param isVerified boolean flag
 * @returns an array of information depending on the isVerified flag
 */
export const getVerificationPanelText = (isVerified: boolean): IVerificationPanelDescription[] => {
  return [
    {
      text: 'basicVerification',
      Icon: BasicVerifiedIcon,
      description: 'description1',
      buttonText: 'buttonText1',
    },
    {
      text: 'fullVerification',
      Icon: FullVerifiedIcon,
      description: 'description2',
      buttonText: isVerified ? 'buttonText1' : 'buttonText2',
    },
  ];
};
