import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { selectDashboardWallet } from 'store/dashboard-reducer/dashboard.selectors';

import { SPage } from 'components/styled/SPage';

import { PaymentOptionsHeader } from './components/ui/PaymentOptionsHeader';
import { CHOOSE_PAYMENT_OPTION } from './components/ui/PaymentOptionsHeader/constants/payment-options-header.consts';
import { PaymentOptionsScreen } from './components/ui/PaymentOptionsScreen';
import { usePayment } from './hooks/usePayment';

export const PagePayment: FC = () => {
  const { amount: balance } = useSelector(selectDashboardWallet);
  const { route, amount, paymentId, paymentCategory, handlePaymentButtonClick } = usePayment();

  const { t } = useTranslation();

  return (
    <SPage
      headerComponent={<PaymentOptionsHeader paymentCategory={paymentCategory} />}
      pageTittle={CHOOSE_PAYMENT_OPTION}
    >
      {!paymentId ? (
        <Navigate to={route} replace={true} />
      ) : (
        <PaymentOptionsScreen
          balance={balance}
          amount={amount}
          handlePaymentButtonClick={handlePaymentButtonClick}
          t={t}
        />
      )}
    </SPage>
  );
};
