import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestChartData } from 'store/api/cuverse-api/chart/chart.service';
import { IChartResponseData } from 'store/api/cuverse-api/chart/types/chart-response';

export const fetchChartThunkAction = createAsyncThunk(
  'minersInfo/Chart',
  async (): Promise<IChartResponseData> => {
    return await requestChartData()
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  },
);
