import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { Button } from 'components/ui/Button';

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 58px;
  color: #fff;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 32px;
    line-height: 40px;
  }

  @media (width <= 490px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const BackButton = styled(Button)`
  padding: 8px 12px;
  border: 1px solid #fff;
  border-radius: 16px;
  color: #fff;

  & svg path {
    fill: #fff;
  }
`;
