import styled from 'styled-components';

import { Button } from 'components/ui/Button';

export const LinkButton = styled(Button)`
  width: 120px;
  border: 1px solid #fff;
  border-radius: 24px;
  color: white;
`;
