import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReplenishResponseDataMapped } from 'store/api/cuverse-api/replenish/types/replenish-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchReplenishThunkAction } from './replenish.thunk-actions';

export interface IReplenishState {
  replenishStatus: string | null;
  replenishData: IReplenishResponseDataMapped;
  status: TFetchStatus;
  error: string | null;
}

export const initialState: IReplenishState = {
  replenishStatus: null,
  replenishData: {
    purchaseId: null,
    name: '',
    createdAt: '',
    priceUsd: 0,
    priceBtc: 0,
    priceLtc: 0,
    priceUst: 0,
    priceEth: 0,
    units: 0,
    discount: 0,
    tax: 0,
    fee: 0,
  },
  status: 'initial',
  error: null,
};

const replenishSlice = createSlice({
  name: 'replenish',
  initialState,
  reducers: {
    clearReplenishStateAction: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReplenishThunkAction.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchReplenishThunkAction.fulfilled,
      (state, action: PayloadAction<IReplenishResponseDataMapped>) => {
        state.status = 'fulfilled';
        state.replenishData = action.payload;
      },
    );
    builder.addCase(fetchReplenishThunkAction.rejected, (state, error) => {
      state.status = 'rejected';
      state.error = JSON.stringify(error);
    });
  },
});

export const replenishReducer = replenishSlice.reducer;
export const { clearReplenishStateAction } = replenishSlice.actions;
