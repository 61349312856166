import type { FC } from 'react';
import { EColors } from 'styles/style-variables/colors';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';

import * as S from './SPageSection.styled';

interface IPropsSPageSection {
  children?: React.ReactNode;
  sectionBackgroundColor?: EColors;
  tittleCeo?: string;
}

export const SPageSection: FC<IPropsSPageSection> = ({
  children,
  sectionBackgroundColor,
  tittleCeo,
}): JSX.Element => {
  return (
    <S.PageSection as="section" $backgroundColor={sectionBackgroundColor}>
      <SVisuallyHidden as="h2">{tittleCeo}</SVisuallyHidden>
      {children}
    </S.PageSection>
  );
};
