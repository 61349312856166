import React, { FC } from 'react';
import { ReactComponent as QuestionIcon } from 'assets/icons/miner-info/questionIcon.svg';
import { openLimitedUptimeExplainerPopupAction } from 'store/modals-reducer/modals.reducer';
import { useAppDispatch } from 'store/store';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './ProfitabilityInfo.styled';

interface IMinerProfitability {
  monthlyProfit: number;
  monthlyIncome: number;
  annualRoi: number;
  monthlyElectricityFee: number;
  t: (t: string) => string;
}

export const ProfitabilityInfo: FC<IMinerProfitability> = ({
  monthlyProfit,
  monthlyElectricityFee,
  monthlyIncome,
  annualRoi,
  t,
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const openUptimeLimitPopup = () => dispatch(openLimitedUptimeExplainerPopupAction());
  return (
    <S.UptimeContainer>
      <S.UptimeHeader>
        <S.UptimeTitle>{t('minerInfo.profitabilityTitle')}</S.UptimeTitle>
        <S.InfoLabel onClick={openUptimeLimitPopup}>
          <QuestionIcon />
          <span>{t('minerInfo.uptimeWork')}</span>
        </S.InfoLabel>
      </S.UptimeHeader>
      <S.Table>
        <thead>
          <S.DesktopColumnsLabels>
            <th></th>
            <th>
              <S.HeadCell>{t('minerInfo.monthlyIncome')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('minerInfo.monthlyElectricityFee')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('minerInfo.monthlyProfit')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('minerInfo.annualRoi')}</S.HeadCell>
            </th>
          </S.DesktopColumnsLabels>

          <S.MobileColumnsLabels>
            <th></th>
            <th>
              <S.HeadCell>100% {t('minerInfo.uptime')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>65% {t('minerInfo.uptime')}</S.HeadCell>
            </th>
          </S.MobileColumnsLabels>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5}>
              <S.Hr />
            </td>
          </tr>

          <>
            <S.DesktopValuesWrapper>
              <S.CellValues>100% uptime</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyIncome)}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyElectricityFee)}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyProfit)}</S.CellValues>
              <S.CellValues>{formatUsdOutput(annualRoi)}%</S.CellValues>
            </S.DesktopValuesWrapper>
            <S.DesktopValuesWrapper>
              <S.CellValues>65% uptime</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyIncome * 0.65)}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyElectricityFee * 0.65)}</S.CellValues>
              <S.CellValues>
                ${formatUsdOutput(monthlyIncome * 0.65 - monthlyElectricityFee * 0.65)}
              </S.CellValues>
              <S.CellValues>{formatUsdOutput(annualRoi * 0.65)}%</S.CellValues>
            </S.DesktopValuesWrapper>
          </>

          <>
            <S.MobileValuesWrapper>
              <S.CellValues>{t('minerInfo.monthlyIncome')}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyIncome)}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyIncome * 0.65)}</S.CellValues>
            </S.MobileValuesWrapper>
            <S.MobileValuesWrapper>
              <S.CellValues>{t('minerInfo.monthlyElectricityFee')}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyElectricityFee)}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyElectricityFee * 0.65)}</S.CellValues>
            </S.MobileValuesWrapper>
            <S.MobileValuesWrapper>
              <S.CellValues>{t('minerInfo.monthlyProfit')}</S.CellValues>
              <S.CellValues>${formatUsdOutput(monthlyProfit)}</S.CellValues>
              <S.CellValues>
                ${formatUsdOutput(monthlyIncome * 0.65 - monthlyElectricityFee * 0.65)}
              </S.CellValues>
            </S.MobileValuesWrapper>
            <S.MobileValuesWrapper>
              <S.CellValues>{t('minerInfo.annualRoi')}</S.CellValues>
              <S.CellValues>${formatUsdOutput(annualRoi)}</S.CellValues>
              <S.CellValues>
                ${formatUsdOutput((monthlyIncome * 0.65 - monthlyElectricityFee * 0.65) * 12)}
              </S.CellValues>
            </S.MobileValuesWrapper>
          </>
        </tbody>
      </S.Table>
    </S.UptimeContainer>
  );
};
