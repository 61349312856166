import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store/store';

import { IReplenishState } from './replenish.reducer';

export const getReplenishState = (state: TRootState): IReplenishState => state.replenishReducer;

export const selectReplenishData = createSelector(
  [getReplenishState],
  (replenishState: IReplenishState) => replenishState.replenishData,
);

export const selectElectricityReplenishStatus = createSelector(
  [getReplenishState],
  (replenishState: IReplenishState) => replenishState.replenishStatus,
);

export const selectElectricityReplenishFetchStatus = createSelector(
  [getReplenishState],
  (replenishState: IReplenishState) => replenishState.status,
);
