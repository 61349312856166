import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

export const PageSection = styled.section<{ $backgroundColor?: EColors }>`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
  }
`;
