import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const TableWrapper = styled.div<{ isEmpty: boolean }>`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
  justify-content: center;
  padding: 0 16px;
  border-radius: 24px;
  background-color: ${EColors.White};
`;

export const Table = styled.table`
  table-layout: fixed;
  width: 60%;
  border-collapse: separate;
  border-spacing: 8px 24px;

  & td,
  th {
    box-sizing: border-box;
    text-align: start;
  }

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    width: 73%;
  }
`;

export const HeadCellTh = styled.th`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: ${EColors.Gray2};
`;

export const Tr = styled.tr`
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${EColors.Gray1Text};
`;

export const NoAffiliateHistory = styled(SText)`
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;
