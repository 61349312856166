import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { ElectricityAmountField } from 'pages/PageDashboard/components/ui/ElectricityAmountField/AmountField';
import { SuggestedAmountList } from 'pages/PageDashboard/components/ui/SuggestedAmountList';
import { EPromoCodeStatus } from 'store/promocode-reducer/constants/promocode-statuses';
import { setPromoCheckStatusAction } from 'store/promocode-reducer/promocode.reducer';
import { selectPromoStatus } from 'store/promocode-reducer/promocode.selectors';
import { selectElectricityReplenishFetchStatus } from 'store/replenish-reducer/replenish.selectors';
import { useAppDispatch } from 'store/store';

import { Switcher } from 'components/ui/Switcher';
import { ESwitcherSize, ESwitcherVariants } from 'components/ui/Switcher/types/switcher-props';

import { PromoCodePortalForm } from './components/PromoCodePortalForm';
import { SWITCHER_SECOND_TITTLE } from './constants/ui-text.const';
import { useFormHandlers } from './hooks/useFormHandlers';
import { usePaymentsPerDays } from './hooks/usePaymentsPerDays';

import * as S from './DepositElectricityForm.styled';

interface IProps {
  t: (key: string) => string;
}

export const DepositElectricityForm: React.FC<IProps> = ({ t }) => {
  const dispatch = useAppDispatch();
  const replenishFetchStatus = useSelector(selectElectricityReplenishFetchStatus);
  const promoStatus = useSelector(selectPromoStatus);

  const isReplenishPending = replenishFetchStatus === 'pending';
  const { paymentsPerDays, oneDayAmount } = usePaymentsPerDays();

  const [isPortalReady, setIsPortalReady] = useState(false);

  const {
    discountValue,
    activeDayAmount,
    activeSuggestedAmount,
    isSwitcherOn,
    handleAmountButtonClick,
    handleInputChange,
    handleFormSubmit,
    validateForm,
    handleSwitchChange,
  } = useFormHandlers({
    oneDayAmount,
  });

  useEffect(() => {
    const portalContainer = document.getElementById('portal');
    if (!portalContainer) {
      const newPortalContainer = document.createElement('div');
      newPortalContainer.id = 'portal';
      document.body.appendChild(newPortalContainer);
    }
    setIsPortalReady(true);
  }, []);

  useEffect(() => {
    return () => void dispatch(setPromoCheckStatusAction(EPromoCodeStatus.Button));
  }, [dispatch]);

  return (
    <S.DepositElectricityWrapper>
      <Form
        onSubmit={handleFormSubmit}
        validate={validateForm}
        render={({ handleSubmit, submitFailed, form, hasValidationErrors }) => {
          return (
            <form onSubmit={handleSubmit}>
              <S.FormContent>
                <S.SuggestedAmountsBlock>
                  <S.SuggestedAmountTitle>{t('replenish.selectAmount')}</S.SuggestedAmountTitle>
                  <SuggestedAmountList
                    activeSuggestedAmount={activeSuggestedAmount}
                    paymentsPerDays={paymentsPerDays}
                    form={form}
                    handleAmountButtonClick={handleAmountButtonClick}
                    t={t}
                  />
                </S.SuggestedAmountsBlock>
                <S.AnotherAmountBlock>
                  <S.SwitcherWrapper>
                    <S.LabelText>{t('replenish.selectAnotherAmount')}</S.LabelText>
                    <Switcher
                      leftTittle={t('replenish.days')}
                      rightTittle={SWITCHER_SECOND_TITTLE}
                      checked={isSwitcherOn}
                      onChange={() => handleSwitchChange(form)}
                      variant={ESwitcherVariants.withTittle}
                      size={ESwitcherSize.small}
                    />
                  </S.SwitcherWrapper>
                  <S.FormInputsWrapper $submitFailed={submitFailed && hasValidationErrors}>
                    <ElectricityAmountField
                      submitFailed={submitFailed}
                      activeDayAmount={activeDayAmount}
                      oneDayAmount={oneDayAmount}
                      isSwitcherOn={isSwitcherOn}
                      isReplenishPending={isReplenishPending}
                      form={form}
                      handleInputChange={handleInputChange}
                      t={t}
                    />
                    <S.DepositButtonWrapper $promoStatus={promoStatus}>
                      <div id="portal"></div>
                      {isPortalReady &&
                        createPortal(
                          <PromoCodePortalForm
                            valueUsd={discountValue}
                            IsSwitcherOn={isSwitcherOn}
                          />,
                          document.getElementById('portal') as HTMLElement,
                        )}
                      <S.DepositButton
                        onClick={() => handleFormSubmit(form.getState().values, form)}
                        text={t('replenish.replenishButtonText')}
                        type="submit"
                        disabled={(submitFailed && hasValidationErrors) || isReplenishPending}
                      />
                    </S.DepositButtonWrapper>
                  </S.FormInputsWrapper>
                </S.AnotherAmountBlock>
              </S.FormContent>
            </form>
          );
        }}
      />
    </S.DepositElectricityWrapper>
  );
};
