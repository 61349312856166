import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IPromocodeCheckDataMapped,
  IPromocodeResponseDataMapped,
  TDiscountType,
} from 'store/api/cuverse-api/promocode/types/promocode-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { EPromoCodeCategories } from './constants/promocode-categories';
import { EPromoCodeStatus } from './constants/promocode-statuses';
import {
  applyPromoThunkAction,
  checkPromoAvailabilityThunkAction,
} from './promocode.thunk-actions';

export interface IPromocodeState {
  couponData: IPromocodeResponseDataMapped;
  promocodeId?: number;
  discount: number;
  category: EPromoCodeCategories | null;
  discountType: TDiscountType;
  promoStatus: EPromoCodeStatus;
  promoCheckStatus: EPromoCodeStatus;
  promoError: string | null;
}

export const initialState: IPromocodeState = {
  couponData: {
    discount: 0,
    priceUsd: 0,
    priceBtc: 0,
    priceLtc: 0,
    priceUst: 0,
    priceEth: 0,
    type: 'percent',
  },

  discount: 0,
  category: null,
  discountType: 'percent',
  promoStatus: EPromoCodeStatus.Button,
  promoCheckStatus: EPromoCodeStatus.Button,
  promoError: null,
};

const promocodeSlice = createSlice({
  name: 'promocode',
  initialState,
  reducers: {
    setPromoStatusAction: (state, { payload }: PayloadAction<EPromoCodeStatus>) => {
      state.promoStatus = payload;
    },
    setPromoCheckStatusAction: (state, { payload }: PayloadAction<EPromoCodeStatus>) => {
      state.promoCheckStatus = payload;
    },
    clearPromoCheckState: (state) => {
      state.discount = 0;
      delete state.promocodeId;
      state.category = null;
      state.promoCheckStatus = EPromoCodeStatus.Button;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applyPromoThunkAction.pending, (state) => {
        state.promoStatus = EPromoCodeStatus.Checking;
      })
      .addCase(
        applyPromoThunkAction.fulfilled,
        (state, { payload }: PayloadAction<IPromocodeResponseDataMapped>) => {
          state.promoStatus = EPromoCodeStatus.Accepted;
          if (state.couponData) {
            state.couponData.priceUsd = payload.priceUsd;
            state.couponData.priceBtc = payload.priceBtc;
            state.couponData.priceLtc = payload.priceLtc;
            state.couponData.priceUst = payload.priceUst;
            state.couponData.priceEth = payload.priceEth;
            state.couponData.discount = payload.discount;
            state.discountType = payload.type;
          }
        },
      )
      .addCase(applyPromoThunkAction.rejected, (state, error) => {
        state.promoStatus = EPromoCodeStatus.Rejected;
        state.promoError = JSON.stringify(error);
      });

    builder
      .addCase(checkPromoAvailabilityThunkAction.pending, (state) => {
        state.promoCheckStatus = EPromoCodeStatus.Checking;
      })
      .addCase(
        checkPromoAvailabilityThunkAction.fulfilled,
        (state, { payload }: PayloadAction<IPromocodeCheckDataMapped>) => {
          state.promoCheckStatus = EPromoCodeStatus.Accepted;
          state.discount = payload.discount;
          state.promocodeId = payload.id;
        },
      )
      .addCase(checkPromoAvailabilityThunkAction.rejected, (state, error) => {
        state.promoCheckStatus = EPromoCodeStatus.Rejected;
        state.promoError = JSON.stringify(error);
      });
  },
});

export const { setPromoStatusAction, setPromoCheckStatusAction, clearPromoCheckState } =
  promocodeSlice.actions;
export const promocodeReducer = promocodeSlice.reducer;
