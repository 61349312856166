import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const CardWrapper = styled.div`
  display: flex;
  flex: 1 1 calc(33.333% - 16px);
  flex-direction: column;
  justify-content: space-between;
  align-content: flex-start;
  gap: 12px;
  width: 100%;
  max-height: 246px;
  padding: 24px;
  border-radius: 24px;
  background-color: ${EColors.White};

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: repeat(1, min-content);
    max-width: 100%;
    padding: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 8px;
    max-width: 100%;
    padding: 16px;
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  align-content: center;
  gap: 8px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: max-content;
  height: min-content;
`;

export const CartTitle = styled.h3`
  font-family: ${FontFamily.Montserrat};
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  color: ${EColors.Gray1Text};

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const CardText = styled(SText)`
  font-size: 14px;
  line-height: 20px;
  color: ${EColors.Gray2};

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`;

export const IconText = styled(SText)`
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${EColors.Gray2};

  @media (width <= 420px) {
    font-size: 12px;
    line-height: 16px;
  }
`;

export const IconsContainer = styled.div<{ $iconGap: number }>`
  display: flex;
  align-items: center;
  gap: ${({ $iconGap }) => `${$iconGap}px`};
`;

export const AmountInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: row;
    gap: 32px;
    margin: 0;
  }
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-transform: uppercase;
  font-variant-numeric: lining-nums tabular-nums;
  color: ${EColors.Gray2};
`;

export const AmountTitle = styled(SText)`
  font-size: 10px;
  line-height: 14px;
`;

export const Amount = styled(SText)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
`;

export const AmountYouPay = styled(SText)`
  font-size: 18px;
  line-height: 24px;

  @media (min-width: ${BreakPoint.TabletTop}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const PayButton = styled(Button)`
  width: 100%;
  padding: 12px;
  border-radius: 24px;
  font-weight: 400;
  letter-spacing: 0.3px;
  white-space: nowrap;
  color: ${EColors.White};

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: flex-end;
    min-width: 200px;
    max-height: 32px;
    padding: 7px 20px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-height: 32px;
    padding: 7px 20px;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const StyledSpinner = styled(SSpinnerBlue)`
  margin: 0 auto;
`;
