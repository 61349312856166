import { ReactComponent as BasicVerifiedIcon } from 'assets/icons/verify-icons/basic-verification-success-icon.svg';
import { ReactComponent as FullVerifiedIcon } from 'assets/icons/verify-icons/full-verification-success-icon.svg';
import { ReactComponent as NotVerifiedIcon } from 'assets/icons/verify-icons/profile-status-icon.svg';
import { IVerificationStatusText } from 'pages/PageVerification/types/types';
import { EVerificationStatusCode } from 'store/api/cuverse-api/profile/types/profile-response.interface';

import { getIsBasicVerificationStatus } from './get-is-basic-verification-status.util';
import { getIsFullVerificationStatus } from './get-is-full-verification-status.util';

/**
 * Profile verification status
 * @param verificationStatusCode verification status
 * @returns an object of text and icon
 */
export const getVerificationStatusTag = (
  verificationStatusCode: EVerificationStatusCode,
): IVerificationStatusText => {
  let status;
  if (getIsFullVerificationStatus(verificationStatusCode)) {
    status = {
      statusText: 'fullVerification',
      icon: FullVerifiedIcon,
    };
  } else if (getIsBasicVerificationStatus(verificationStatusCode)) {
    status = {
      statusText: 'basicVerification',
      icon: BasicVerifiedIcon,
    };
  } else {
    status = {
      statusText: 'notVerified',
      icon: NotVerifiedIcon,
    };
  }

  return status;
};
