import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { SContainer, SInvoiceWrapper, SPaymentWrapper } from 'pages/PagePurchase/components/styled';
import { InvoiceDescription } from 'pages/PagePurchase/components/ui/InvoiceDescription';
import { InvoiceHeader } from 'pages/PagePurchase/components/ui/InvoiceHeader';
import { PaymentAgreementForm } from 'pages/PagePurchase/components/ui/PaymentAgreementForm';
import { PaymentDetails } from 'pages/PagePurchase/components/ui/PaymentDetails';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { selectDashboardWallet } from 'store/dashboard-reducer/dashboard.selectors';
import { setCartPromoStatusAction } from 'store/invoice-reducer/invoice.reducer';
import {
  selectCartDiscountType,
  selectCartPromoStatus,
  selectInvoiceData,
} from 'store/invoice-reducer/invoice.selectors';
import { applyCartPromoThunkAction } from 'store/invoice-reducer/invoice.thunk-actions';
import { openAdditionalTermsPopupAction } from 'store/modals-reducer/modals.reducer';
import { EPaymentCategory, setPaymentCategoryAction } from 'store/payment-reducer/payment.reducer';
import { EPromoCodeCategories } from 'store/promocode-reducer/constants/promocode-categories';
import { useAppDispatch } from 'store/store';

import { SVisuallyHidden } from 'components/styled/SVisuallyHidden';
import { PromoCodeArea } from 'components/ui/PromoCodeArea';
import { IPromoCodeAreaValue } from 'components/ui/PromoCodeArea/types/promo-code-area-value.interface';
import { AppRoute } from 'utils/route/app-route';

export const InvoiceScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const invoiceData = useSelector(selectInvoiceData);
  const cartData = useSelector(selectCart);
  const promoStatus = useSelector(selectCartPromoStatus);
  const promoDiscountType = useSelector(selectCartDiscountType);
  const { amount } = useSelector(selectDashboardWallet);

  const { cartId, cartItems, totalCartSum } = cartData;
  const discount = invoiceData?.discount || 0;
  const finalSumBtc = invoiceData?.priceBtc || 0;
  const handlePaymentSubmit = () => {
    if (invoiceData?.id) {
      dispatch(setPaymentCategoryAction(EPaymentCategory.Cart));
      navigate(AppRoute.Payment(), { replace: true });
    }
  };

  const handlePromoCodeButtonClick = () =>
    dispatch(setCartPromoStatusAction(EPromoCodeStatus.Form));

  const handlePromoCodeSubmit = ({ promoCode }: IPromoCodeAreaValue) =>
    void dispatch(
      applyCartPromoThunkAction({
        purchaseId: cartId,
        couponCode: promoCode,
        category: EPromoCodeCategories.Cart,
      }),
    );

  const handleCloseButtonClick = () => dispatch(setCartPromoStatusAction(EPromoCodeStatus.Form));

  const handleAdditionalTermsClick = () => dispatch(openAdditionalTermsPopupAction());

  useEffect(() => {
    dispatch(setCartPromoStatusAction(EPromoCodeStatus.Button));
  }, [dispatch]);

  return (
    <>
      {!cartId || !cartData.cartItems.length ? (
        <Navigate to={AppRoute.Purchase()} replace />
      ) : (
        <>
          <InvoiceHeader
            step={'checkout'}
            invoiceNumber={invoiceData?.id}
            invoiceDate={invoiceData?.createdAt}
            t={t}
          />
          <SContainer>
            <SInvoiceWrapper $purchaseStep={'buying'}>
              <SVisuallyHidden as="h2">Invoice data</SVisuallyHidden>
              <InvoiceDescription cartItems={cartItems} />
              <SPaymentWrapper>
                <PromoCodeArea
                  promoStatus={promoStatus}
                  handlePromoCodeButtonClick={handlePromoCodeButtonClick}
                  handlePromoCodeSubmit={handlePromoCodeSubmit}
                  handleCloseButtonClick={handleCloseButtonClick}
                  t={t}
                />
                <PaymentDetails
                  sumUsd={totalCartSum}
                  finalSumBtc={finalSumBtc}
                  discount={discount}
                  discountType={promoDiscountType}
                  btcEarned={amount}
                  t={t}
                />
                <PaymentAgreementForm
                  handleAdditionalTermsClick={handleAdditionalTermsClick}
                  handlePaymentSubmit={handlePaymentSubmit}
                  isSubmitting={false}
                  t={t}
                />
              </SPaymentWrapper>
            </SInvoiceWrapper>
          </SContainer>
        </>
      )}
    </>
  );
};
