import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormApi } from 'final-form';
import { MAXIMUM_REPLENISH_AMOUNT_USD } from 'pages/PageDashboard/components/ui/ElectricityAmountField/constants/maximum-replenish-amount';
import { IDepositElectricityFormValues } from 'pages/PageDashboard/components/ui/ElectricityAmountField/types/deposit-electricity-form-values.interface';
import { EPaymentCategory, setPaymentCategoryAction } from 'store/payment-reducer/payment.reducer';
import { selectPromocodeId } from 'store/promocode-reducer/promocode.selectors';
import { selectElectricityReplenishFetchStatus } from 'store/replenish-reducer/replenish.selectors';
import { fetchReplenishThunkAction } from 'store/replenish-reducer/replenish.thunk-actions';
import { useAppDispatch } from 'store/store';

import { formatInputValue } from 'utils/formatters/format-input-value.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { removeNonNumericSymbols } from 'utils/formatters/remove-non-numeric-symbols.util';
import { gtmClickReplenish } from 'utils/gtmSender/gtmSender';
import { notifyError } from 'utils/notify/notify.utils';
import { AppRoute } from 'utils/route/app-route';

interface IUseFormHandlersProps {
  oneDayAmount: number;
}

interface IUseFormHandlersReturn {
  discountValue: number;
  activeDayAmount: number | null;
  activeSuggestedAmount: number | null;
  isSwitcherOn: boolean;
  handleAmountButtonClick: (
    value: number,
    day: number,
    form: FormApi<IDepositElectricityFormValues>,
  ) => void;
  handleInputChange: (value: string, form: FormApi<IDepositElectricityFormValues>) => void;
  handleFormSubmit: (
    values: IDepositElectricityFormValues,
    form: FormApi<IDepositElectricityFormValues>,
  ) => void;
  validateForm: (values: IDepositElectricityFormValues) => Partial<IDepositElectricityFormValues>;
  handleSwitchChange: (form: FormApi<IDepositElectricityFormValues>) => void;
}

export const useFormHandlers = ({
  oneDayAmount,
}: IUseFormHandlersProps): IUseFormHandlersReturn => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const couponId = useSelector(selectPromocodeId);
  const replenishFetchStatus = useSelector(selectElectricityReplenishFetchStatus);

  const isReplenishPending = replenishFetchStatus === 'pending';

  const [activeSuggestedAmount, setActiveSuggestedAmount] = useState<number | null>(null);
  const [activeDayAmount, setActiveDayAmount] = useState<number | null>(null);
  const [isSwitcherOn, setIsSwitcherOn] = useState(false);
  const [discountValue, setDiscountValue] = useState(0);
  const [newNotify, setNewNotify] = useState(true);

  const calculateAmount = (
    amount: number,
    oneDayAmount?: number,
    isSwitcherOn?: boolean,
    activeDayAmount: number | null = null,
  ) => {
    const oneDayAmountValue = oneDayAmount || 1;
    const calculatedDays = activeDayAmount
      ? activeDayAmount * oneDayAmountValue
      : amount * oneDayAmountValue;
    return isSwitcherOn ? Math.floor(amount) : Math.floor(calculatedDays);
  };

  const validateForm = (values: IDepositElectricityFormValues) => {
    const errors: Partial<IDepositElectricityFormValues> = {};

    if (!isSwitcherOn && +values.amount > MAXIMUM_REPLENISH_AMOUNT_USD) {
      errors.amount = `Maximum replenish amount $${formatUsdOutput(MAXIMUM_REPLENISH_AMOUNT_USD)} `;
    }

    return errors;
  };

  const handleAmountButtonClick = (
    value: number,
    day: number,
    form: FormApi<IDepositElectricityFormValues, Partial<IDepositElectricityFormValues>>,
  ) => {
    if (!isReplenishPending) {
      setActiveSuggestedAmount(value);
      setActiveDayAmount(day);
      setIsSwitcherOn(false);
      setNewNotify(true);
      form.change('amount', isSwitcherOn ? value.toFixed(3) : day.toFixed(0));
      const amount = calculateAmount(value, oneDayAmount, isSwitcherOn, day);

      setDiscountValue(amount);
    }
  };

  const handleInputChange = (value: string, form: FormApi<IDepositElectricityFormValues>) => {
    if (!isReplenishPending) {
      activeSuggestedAmount && setActiveSuggestedAmount(null);
      activeDayAmount && setActiveDayAmount(null);
      const formattedAmount = removeNonNumericSymbols(formatInputValue(value, 0));
      setNewNotify(true);
      form.change('amount', formattedAmount);
      const amount = calculateAmount(+formattedAmount, oneDayAmount, isSwitcherOn);
      setDiscountValue(amount);
    }
  };

  const handleFormSubmit = (
    values: IDepositElectricityFormValues,
    form: FormApi<IDepositElectricityFormValues>,
  ) => {
    if (isReplenishPending) return;

    const amount = calculateAmount(+values.amount, oneDayAmount, isSwitcherOn, activeDayAmount);
    setDiscountValue(amount);
    const validationErrors = validateForm({ amount: amount.toString() });
    validationErrors.amount && newNotify && notifyError(validationErrors.amount);
    setNewNotify(false);

    if (Object.keys(validationErrors).length > 0) {
      form.resetFieldState('amount');
      activeSuggestedAmount && setActiveSuggestedAmount(null);
      activeDayAmount && setActiveDayAmount(null);

      form.batch(() => {
        for (const key in validationErrors) {
          form.change('amount', values[key as keyof typeof values]);
        }
      });
      return;
    }

    if (form.getState().hasValidationErrors) return;

    void dispatch(fetchReplenishThunkAction({ amount, coupon_id: couponId })).then(() => {
      dispatch(setPaymentCategoryAction(EPaymentCategory.Electricity));
      navigate(AppRoute.Payment());
    });
    gtmClickReplenish(amount.toString());
  };

  const handleSwitchChange = (form: FormApi<IDepositElectricityFormValues>) => {
    setIsSwitcherOn(!isSwitcherOn);
    const amount = calculateAmount(+form.getState().values.amount, oneDayAmount, !isSwitcherOn);
    setDiscountValue(isNaN(amount) ? 0 : amount);
  };

  return {
    activeDayAmount,
    activeSuggestedAmount,
    discountValue,
    isSwitcherOn,
    validateForm,
    handleAmountButtonClick,
    handleInputChange,
    handleFormSubmit,
    handleSwitchChange,
  };
};
