import { useSelector } from 'react-redux';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { selectInvoiceData } from 'store/invoice-reducer/invoice.selectors';
import { selectActiveMiners } from 'store/purchase-reducer/purchase.selectors';

import { IEcommerce } from 'utils/gtmSender/types';

interface IGMTEcommerceReturn {
  btc: IEcommerce;
}

export const useGmtPaymentPrepareEcommerce = (): IGMTEcommerceReturn => {
  const invoiceData = useSelector(selectInvoiceData);
  const minersData = useSelector(selectActiveMiners);
  const { cartItems, totalCartSum } = useSelector(selectCart);
  const gtmItems = minersData.map((minerData) => {
    const cartItem = cartItems.find((item) => item.productId === minerData.id);
    return {
      item_name: minerData.model,
      item_id: minerData.id,
      price: minerData.price,
      item_brand: minerData.model.split(' ')[0],
      item_hash_rate: minerData.hashrate,
      item_electricity_fee: minerData.dailyElectricityFee,
      quantity: cartItem ? cartItem.quantity : 0,
    };
  });

  const itemsInCart = gtmItems.filter((el) => el.quantity);
  const gtmEcommerce = {
    transaction_id: invoiceData?.id,
    value: totalCartSum,
    coupon: 'not set',
    payment_type: 'BTC transfer',
    items: itemsInCart,
  };

  return { btc: gtmEcommerce };
};
