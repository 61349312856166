import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import { ICartResponse, IUpdateCartBodyRequest } from './types/cart-response.interface';
import { CartRoute } from './cart.route';

export const requestCartList = async (): Promise<ICartResponse> =>
  cuverseApi.get<ICartResponse>(CartRoute.Cart()).then((res) => res?.data);

export const createCartList = async (id: number): Promise<ICartResponse> =>
  cuverseApi.post<ICartResponse>(CartRoute.Cart(), { product_id: id }).then((res) => res?.data);

export const updateCartList = async (
  cartNumber: number,
  productId: number,
): Promise<ICartResponse> =>
  cuverseApi
    .patch<ICartResponse>(CartRoute.UpdateCart(cartNumber), { product_id: productId })
    .then((res) => res?.data);

export const updateCart = async (
  cartNumber: number,
  body: IUpdateCartBodyRequest,
): Promise<ICartResponse> =>
  cuverseApi.put<ICartResponse>(CartRoute.UpdateCart(cartNumber), body).then((res) => res?.data);

export const multipleUpdateCartList = async (
  data: { product_id: number; quantity: number }[],
  cartId: number,
): Promise<ICartResponse> =>
  cuverseApi
    .put<ICartResponse>(CartRoute.MultipleUpdateCart(cartId), { cart_items: data })
    .then((res) => res?.data);

export const deleteCartListItem = async (
  cartNumber: number,
  productId: number,
): Promise<ICartResponse> =>
  cuverseApi
    .delete<ICartResponse>(CartRoute.DeleteCartItem(cartNumber, productId))
    .then((res) => res?.data);

export const deleteCart = async (cartNumber: number): Promise<void> => {
  await cuverseApi.delete<ICartResponse>(CartRoute.DeleteCart(cartNumber));
};
