import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { Select } from 'components/ui/Select';

export const LngSwitcher = styled(Select)`
  margin-right: 24px;

  & button {
    min-height: 32px;
    padding: 7px 32px 7px 12px;
    border: none;
    font-weight: 500;
  }

  & button:focus {
    box-shadow: none;
  }

  & button span {
    font-size: 12px;
    color: #0e46c0;
  }

  & div ul li button span {
    font-size: 12px;
    color: #272727;
  }

  & button svg {
    color: #0e46c0;
  }

  @media (max-width: ${BreakPoint.DesktopLow}) {
    margin: 0;

    & button {
      background-color: #6a96f6;
    }

    & button span {
      font-size: 12px;
      color: #fff;
    }

    & button svg {
      color: #fff;
    }

    & div ul li button {
      background-color: #fff;
    }
  }

  @media (max-width: ${BreakPoint.MobileTop}) {

    & button {
      align-self: flex-start;
      width: 70px;
      margin-right: -12px;
      background-color: transparent;
    }
  }
`;
