export enum ELanguages {
  ENG = 'en',
  DEU = 'de',
  ITA = 'it',
  ESP = 'es',
}

export type TLanguages = 'ENG' | 'DEU' | 'ITA' | 'ESP';

export interface LanguageContextType {
  language: TLanguages;
  changeLanguage: (lng: TLanguages) => void;
}
