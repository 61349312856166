import React from 'react';
import { useTranslation } from 'react-i18next';

import { MAIN_SITE_LINK } from './constants/main-site-link';

import * as S from './NavigationFooter.styled';

interface INavigationFooterProps {
  handleLogout: () => void;
  isLoggingOut: boolean;
}

export const NavigationFooter: React.FC<INavigationFooterProps> = ({
  handleLogout,
  isLoggingOut,
}) => {
  const { t } = useTranslation();
  return (
    <S.FooterLinksWrapper>
      <S.LogoutButton onClick={handleLogout} disabled={isLoggingOut}>
        {t('menu.logOut')}
      </S.LogoutButton>
      <S.MainSiteLink href={MAIN_SITE_LINK} target="_blank">
        {t('menu.goToMainSite')} →
      </S.MainSiteLink>
    </S.FooterLinksWrapper>
  );
};
