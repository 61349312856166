import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';

export const Charts = styled.div`
  display: grid;
  width: 100%;
  min-height: 400px;
  border-radius: 16px;
  background-color: #fff;
`;

export const ChartsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding: 16px;
  }
`;

export const ChartsTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ChartTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;

  @media (max-width: ${BreakPoint.LaptopLow}) {
    font-size: 18px;
  }
`;

export const ChartLabel = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #646464;
`;
