import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/button-icons/miner-white.svg';
import { DesktopRowsByModel } from 'pages/PageAssets/components/ui/DesktopRowsByModel';
import { TMinersGroupByModel } from 'pages/PageAssets/types/miners-group-by-model.type';

import { ContentPlaceholder } from 'components/ui/ContentPlaceholder';
import { AppRoute } from 'utils/route/app-route';

import * as S from './AssetsTableDesktop.styled';

interface IAssetsTableDesktopProps {
  minersByModel: TMinersGroupByModel[];
  btcToUsdRate: number;
  isPending: boolean;
  t: (key: string) => string;
}

export const AssetsTableDesktop: React.FC<IAssetsTableDesktopProps> = ({
  minersByModel,
  btcToUsdRate,
  isPending,
  t,
}) => {
  const navigate = useNavigate();

  if (minersByModel.length === 0 && !isPending) {
    return (
      <S.TableWrapper>
        <ContentPlaceholder
          buttonIcon={<MinerWhiteIcon />}
          buttonText={t('contentPlaceholder.buyMiner')}
          tittleText={t('contentPlaceholder.buyYourFirstMiner')}
          onButtonClick={() => navigate(AppRoute.Purchase())}
        />
      </S.TableWrapper>
    );
  }

  return (
    <S.TableWrapper>
      <S.Table as="table">
        <thead>
          <tr>
            <th>
              <S.HeadCell>{t('desktop.typeAndName')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>
                {t('assets.hashRate')}
                {t('assets.quantity')}
              </S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('assets.powerConsumption')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('assets.dailyIncome')}</S.HeadCell>
            </th>
            <th>
              <S.HeadCell>{t('assets.status')}</S.HeadCell>
            </th>
          </tr>
        </thead>
        <tbody>
          {minersByModel.map((minersGroup: TMinersGroupByModel) => {
            const minerModel = Object.keys(minersGroup)[0];
            return (
              <DesktopRowsByModel
                key={minerModel}
                minersGroupByModel={minersGroup}
                btcToUsdRate={btcToUsdRate}
              />
            );
          })}
        </tbody>
      </S.Table>
    </S.TableWrapper>
  );
};
