import React from 'react';
import { useSelector } from 'react-redux';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { DesktopTable } from 'pages/PagePurchase/components/ui/DesktopTable';
import { MobileTable } from 'pages/PagePurchase/components/ui/MobileTable';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import {
  FROM_TEXT,
  MAILING_SENDER_ADDRESS,
  SUPPORT_EMAIL,
} from './constants/invoice-description.consts';

import * as S from './InvoiceDescription.styled';

interface IInvoiceDescriptionProps {
  cartItems: ICartItemMapped[];
}

export const InvoiceDescription: React.FC<IInvoiceDescriptionProps> = ({ cartItems }) => {
  const { innerWidth } = useInnerWidth();
  const { firstname, lastname, address, email } = useSelector(selectProfile);

  return (
    <S.Wrapper>
      <S.AddressBlock>
        <S.From>
          <S.Name>{FROM_TEXT}</S.Name>
          <S.MailingSenderBlock>
            <S.MailingSenderAddress>{MAILING_SENDER_ADDRESS}</S.MailingSenderAddress>
            <S.Email>{SUPPORT_EMAIL}</S.Email>
          </S.MailingSenderBlock>
        </S.From>
        <S.To>
          <S.Name>{`To: ${lastname} ${firstname}`}</S.Name>
          <S.MailingRecipientBlock>
            <S.MailingRecipientAddress>{address}</S.MailingRecipientAddress>
            <S.Email>{email}</S.Email>
          </S.MailingRecipientBlock>
        </S.To>
      </S.AddressBlock>
      {innerWidth >= parseInt(BreakPoint.TabletLow) ? (
        <DesktopTable cartItems={cartItems} />
      ) : (
        <MobileTable cartItems={cartItems} />
      )}
    </S.Wrapper>
  );
};
