import { createSelector } from '@reduxjs/toolkit';
import { ICartDataMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { ICartState } from './cart-reducer';

export const getCartState = (state: TRootState): ICartState => state.cartReducer;

export const selectCart = createSelector([getCartState], (state): ICartDataMapped => state.cart);

export const selectCartFetchStatus = createSelector(
  [getCartState],
  (state): TFetchStatus => state.cartListFetchStatus,
);
