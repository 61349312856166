import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchCartThunkAction } from 'store/cart-reducer/cart.thunk-actions';
import { selectCart } from 'store/cart-reducer/cart-selectors';
import { fetchActiveMinersThunkAction } from 'store/purchase-reducer/purchase.thunk-actions';
import { useAppDispatch } from 'store/store';

import { Cart } from 'components/logic/Cart/Cart';
import { AppRoute } from 'utils/route/app-route';

const routesWithDynamicCart: string[] = [
  AppRoute.Home(),
  AppRoute.Assets(),
  AppRoute.TransactionsHistory(),
  AppRoute.AffiliateProgram(),
];
const routesWithStaticCart: string[] = [AppRoute.Purchase(), AppRoute.PurchaseMinerInfo()];

interface IUseCartReturn {
  Cart: JSX.Element | null;
  translateContent: boolean;
}

export const useCart = (): IUseCartReturn => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const cartData = useSelector(selectCart);

  const isDynamicRouteWithCart = routesWithDynamicCart.includes(location.pathname);
  const isStaticRouteWithCart = routesWithStaticCart.includes(location.pathname);
  const isCartEmpty = !cartData.cartItems.length;
  const isCartItemsQuantityZero =
    cartData.cartItems.reduce((acc, item) => acc + item.quantity, 0) === 0;

  useEffect(() => {
    void dispatch(fetchActiveMinersThunkAction());
    void dispatch(fetchCartThunkAction());
  }, [dispatch]);

  if (
    (isDynamicRouteWithCart && isCartEmpty) ||
    (isDynamicRouteWithCart && isCartItemsQuantityZero)
  ) {
    return {
      Cart: null,
      translateContent: false,
    };
  }

  if (isStaticRouteWithCart || isDynamicRouteWithCart) {
    return {
      Cart: <Cart />,
      translateContent: true,
    };
  }

  return {
    Cart: null,
    translateContent: false,
  };
};
