import { createSelector } from '@reduxjs/toolkit';
import { IChartResponseData } from 'store/api/cuverse-api/chart/types/chart-response';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { IChartState } from './chart-reducer';

export const getChartState = (state: TRootState): IChartState => state.chartReducer;

export const selectChart = createSelector(
  [getChartState],
  (state): IChartResponseData | null => state.chart,
);

export const selectChartFetchStatus = createSelector(
  [getChartState],
  (state): TFetchStatus => state.chartStatus,
);
