import { FC } from 'react';
import { ReactComponent as MinusIcon } from 'assets/icons/button-icons/minus.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/button-icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/button-icons/trash.svg';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { TTransformedCartItem } from 'components/logic/Cart/types/types';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './CartItem.styled';

interface ICartItemProps {
  item: TTransformedCartItem & IMinerDataMapped;
  handleDecrement: (id: number) => void;
  handleIncrement: (id: number) => void;
  handleDeleteAll: (id: number) => void;
}

export const CartItem: FC<ICartItemProps> = ({
  item,
  handleDecrement,
  handleIncrement,
  handleDeleteAll,
}) => {
  if (item.quantity === 0) {
    return null;
  }

  return (
    <S.CartItemWrapper>
      <S.ItemMainContentWrapper>
        <S.MinerPicture>
          <source
            srcSet={`${item.picture}, ${item.picture} 2x, ${item.picture} 3x`}
            type="image/webp"
          />
          <source
            srcSet={`${item.picture}, ${item.picture} 2x, ${item.picture} 3x`}
            type="image/png"
          />
          <img src={item.picture} alt="Miner" width={40} height={40} />
        </S.MinerPicture>
        <S.ItemDescription>
          <S.ItemNameTittle>{item.name.split('-')[0]}</S.ItemNameTittle>
          <S.ItemSummTittle>${formatUsdOutput(item.sum)}</S.ItemSummTittle>
        </S.ItemDescription>
      </S.ItemMainContentWrapper>
      <S.ButtonsWrapper>
        <S.PlusMinusWrapper>
          <MinusIcon onClick={() => handleDecrement(item.id)} />
          <S.CountTittle>{item.quantity}</S.CountTittle>
          <PlusIcon onClick={() => handleIncrement(item.id)} />
        </S.PlusMinusWrapper>
        <S.TrashWrapper>
          <TrashIcon onClick={() => handleDeleteAll(item.id)} />
        </S.TrashWrapper>
      </S.ButtonsWrapper>
    </S.CartItemWrapper>
  );
};
