import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGoogleLoginCallback } from 'hooks/useGoogleLoginCallback';
import { setUserAuthStatusAction } from 'store/auth-reducer/auth.reducer';
import { selectLoginFetchStatus, selectUserAuthStatus } from 'store/auth-reducer/auth.selectors';
import { requestLoginThunkAction } from 'store/auth-reducer/auth.thunk-actions';
import { useAppDispatch } from 'store/store';
import { IAuthFormValues } from 'types/ui/AuthForm/auth-form-values.interface';
import { ICodeVerificationFormValues } from 'types/ui/CodeVerificationForm/code-verification-form-values.interface';

import { AuthForm } from 'components/ui/AuthForm';
import { CodeVerificationForm } from 'components/ui/CodeVerificationForm';
import { gtmClickForgotPassword, gtmClickSendForm } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';

export const PageLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loginFetchStatus = useSelector(selectLoginFetchStatus);
  const userAuthStatus = useSelector(selectUserAuthStatus);
  const { t } = useTranslation();

  const mfaErrorHandlerRef = useRef<AsyncGenerator<string | undefined, void, string> | null>(null);

  const handleGoogleLoginClick = useGoogleLoginCallback(mfaErrorHandlerRef);

  const handleAuthFormSubmit = (body: IAuthFormValues) => {
    void dispatch(
      requestLoginThunkAction({ body, errorHandlerRef: mfaErrorHandlerRef, gtmClickSendForm }),
    );
  };

  const handleCodeVerificationFormSubmit = (values: ICodeVerificationFormValues) => {
    void mfaErrorHandlerRef.current?.next(Object.values(values).join(''));
  };

  const handleCodeVerificationBackClick = () => dispatch(setUserAuthStatusAction('unauthorized'));

  useEffect(() => {
    if (userAuthStatus === 'email-verification-pending') {
      navigate(AppRoute.Registration());
    }
  }, [userAuthStatus, navigate]);

  switch (userAuthStatus) {
    case 'authentication-required':
      return (
        <CodeVerificationForm
          title={t('login.title')}
          handleSubmit={handleCodeVerificationFormSubmit}
          handleBackClick={handleCodeVerificationBackClick}
          t={t}
        />
      );
    default:
      return (
        <AuthForm
          type="login"
          handleFormSubmit={handleAuthFormSubmit}
          handleGoogleLoginClick={handleGoogleLoginClick}
          isSubmitting={loginFetchStatus === 'pending'}
          hasBackendError={loginFetchStatus === 'rejected'}
          handleForgotPassword={() => gtmClickForgotPassword()}
        />
      );
  }
};
