export const mergeArraysById = <A1 extends { id: number }, A2 extends { id: number }>(
  arr1: A1[],
  arr2: A2[],
): (A1 & A2)[] => {
  const map = new Map();
  arr1.forEach((el) => {
    map.set(el.id, { ...el });
  });

  arr2.forEach((el) => {
    const existing = map.get(el.id);
    if (existing) {
      map.set(el.id, { ...existing, ...el });
    }
  });

  return Array.from(map.values());
};
