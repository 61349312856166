import React from 'react';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './DesktopTable.styled';

interface IDesktopTableProps {
  cartItems: ICartItemMapped[];
}

export const DesktopTable: React.FC<IDesktopTableProps> = ({ cartItems }) => {
  return (
    <S.CartWrapper>
      <S.Table>
        <thead>
          <tr>
            <th>
              <S.HeadSell>description</S.HeadSell>
            </th>
            <th>
              <S.HeadSell>price</S.HeadSell>
            </th>
            <th>
              <S.HeadSell>amount</S.HeadSell>
            </th>
            <th>
              <S.HeadSell>total</S.HeadSell>
            </th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <tr key={item.productId}>
              <td>
                <S.TitleWrapper>
                  <S.TitleCell>{item.name}</S.TitleCell>
                  <S.PurchaseTypeCell>Buy a miner</S.PurchaseTypeCell>
                </S.TitleWrapper>
              </td>
              <td>
                <S.ValueCell>{`$${formatUsdOutput(item.price)}`}</S.ValueCell>
              </td>
              <td>
                <S.ValueCell>{item.quantity}</S.ValueCell>
              </td>
              <td>
                <S.ValueCell>{`$${formatUsdOutput(item.quantity * item.price)}`}</S.ValueCell>
              </td>
            </tr>
          ))}
        </tbody>
      </S.Table>
    </S.CartWrapper>
  );
};
