import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as CartIcon } from 'assets/icons/miner-info/cart.svg';
import { useMinerCardButton } from 'pages/PagePurchase/components/hook/useMinerCardButton';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { multipleUpdateCartThunkAction } from 'store/cart-reducer/cart.thunk-actions';
import { useAppDispatch } from 'store/store';
import { EColors } from 'styles/style-variables/colors';

import { CartManageButton } from 'components/ui/CartManageButton';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './CartSection.styled';

interface IMinerInfoProps {
  monthlyRevenue: number;
  price: number;
  quantity: number;
  availableMinersNumber: number;
  isActualSoldOut: boolean;
  minerData: IMinerDataMapped;
  monthlyElectricityFee: number;
  t: (key: string) => string;
}

export const CartSection: FC<IMinerInfoProps> = ({
  monthlyRevenue,
  price,
  monthlyElectricityFee,
  minerData,
  t,
}): React.ReactElement => {
  const dispatch = useAppDispatch();
  const [isAddToCartDisabled, setIsAddToCartDisabled] = useState<boolean>(false);

  const {
    inputValue,
    isFocused,
    isCountInitial,
    mouseOnButton,
    handleBlur,
    handleIncrement,
    handleDecrement,
    handleInputChange,
    handleMouseOver,
    handleMouseLeave,
    handleKeyDown,
    handleOnFocus,
    cartId,
    quantity,
    availableMinersNumber,
    isActualSoldOut,
  } = useMinerCardButton({ minerData });
  const { id } = minerData;
  const totalMonthlyProfit = quantity ? monthlyRevenue * quantity - monthlyElectricityFee : 0;
  const totalPrice = price * (quantity || 0);

  const handleAddButton = () => {
    void dispatch(
      multipleUpdateCartThunkAction({
        data: [{ product_id: id, quantity: quantity || 0 }],
        cartId: cartId,
      }),
    );
    setIsAddToCartDisabled(true);
  };

  useEffect(() => {
    setIsAddToCartDisabled(false);
  }, [quantity]);

  return (
    <S.QuantityContainer>
      <S.ButtonWrapper>
        <S.QuantityTitle>{t('minerInfo.quantity')}</S.QuantityTitle>
        <S.ButtonsCountWrapper>
          <CartManageButton
            quantity={quantity || 0}
            inputValue={inputValue}
            inputBackgroundColor={isFocused ? EColors.White : EColors.Yellow3}
            isMouseOnButton={mouseOnButton}
            isInitial={isCountInitial}
            handleOnBlur={handleBlur}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
            handleInputChange={handleInputChange}
            handleOnMouseOver={handleMouseOver}
            handleOnMouseLeave={handleMouseLeave}
            handleOnKeyDown={handleKeyDown}
            handleOnFocus={handleOnFocus}
            availableMinersNumber={availableMinersNumber}
            isSoldOut={isActualSoldOut}
            t={t}
          />
        </S.ButtonsCountWrapper>
      </S.ButtonWrapper>
      <S.InfoContainer>
        <S.InfoWrapper>
          <S.Text>{t('minerInfo.totalMonthlyProfit')}</S.Text>
          <S.Text>
            {t('minerInfo.upTo')} <S.Cost>{`$${formatUsdOutput(totalMonthlyProfit)}`}</S.Cost>
          </S.Text>
        </S.InfoWrapper>
        <S.InfoWrapper>
          <S.Text>{t('minerInfo.totalPrice')}</S.Text>
          <S.Price>{`$${formatUsdOutput(totalPrice)}`}</S.Price>
        </S.InfoWrapper>
      </S.InfoContainer>
      <S.AddToCartWrapper>
        <S.QuantityButton
          onClick={handleAddButton}
          icon={<CartIcon />}
          text={t('minerInfo.addToCart')}
          variant="primary"
          disabled={isAddToCartDisabled || quantity === 0}
        />
      </S.AddToCartWrapper>
    </S.QuantityContainer>
  );
};
