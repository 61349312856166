import { IInvoiceResponseDataMapped } from 'store/api/cuverse-api/invoice/types/invoice-response.interface';
import { IReplenishResponseDataMapped } from 'store/api/cuverse-api/replenish/types/replenish-response.interface';
import { EPaymentCategory } from 'store/payment-reducer/payment.reducer';

type TPaymentParams = {
  replenishData: IReplenishResponseDataMapped | null;
  invoiceData: IInvoiceResponseDataMapped | null;
};

type TPaymentAdaptor = (paymentParams: TPaymentParams) => {
  amount: number;
  paymentId: number | null;
};

const defaultPaymentAdaptor: TPaymentAdaptor = () => ({
  amount: 0,
  paymentId: null,
});

/**
 * Adapts invoice data to the required payment format for card payments.
 */
const cardPaymentAdaptor: TPaymentAdaptor = ({ invoiceData }) => ({
  amount: invoiceData?.priceBtc || 0,
  paymentId: invoiceData?.id || null,
});

/**
 * Adapts replenish data to the required payment format for electricity payments.
 */
const electricityPaymentAdaptor: TPaymentAdaptor = ({ replenishData }) => ({
  amount: replenishData?.priceBtc || 0,
  paymentId: replenishData?.purchaseId || null,
});

const paymentAdaptors: Record<EPaymentCategory, TPaymentAdaptor> = {
  [EPaymentCategory.Cart]: cardPaymentAdaptor,
  [EPaymentCategory.Electricity]: electricityPaymentAdaptor,
};

/**
 * Adapts payment params to the required payment format based on the category.
 */
export const paymentParamsAdaptor = (
  paymentParams: TPaymentParams,
  paymentCategory: EPaymentCategory | null,
): { amount: number; paymentId: number | null } => {
  const adaptor = paymentCategory
    ? paymentAdaptors[paymentCategory] || defaultPaymentAdaptor
    : defaultPaymentAdaptor;
  return adaptor(paymentParams);
};
