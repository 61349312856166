import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInnerWidth } from 'hooks/useInnerWidth';
import { AssetsTableDesktop } from 'pages/PageAssets/components/ui/AssetsTableDesktop';
import { AssetsTableMobile } from 'pages/PageAssets/components/ui/AssetsTableMobile';
import { ASSETS_ITEMS_PER_PAGE } from 'pages/PageAssets/constants/constants';
import { transformMinersByStatusToMinersByModel } from 'pages/PageAssets/utils/transform-miners-by-status-to-miners-by-model.util';
import { selectAssets, selectAssetsStatus } from 'store/assets-reducer/assets.selectors';
import { requestAssetsData } from 'store/assets-reducer/assets.thunk-actions';
import { selectBtcUsdRate } from 'store/rates-reducer/rates.selectors';
import { fetchPairRatesThunkAction } from 'store/rates-reducer/rates.thunk-actions';
import { useAppDispatch } from 'store/store';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SPageSection } from 'components/styled/SPageSection';
import { Pagination } from 'components/ui/Pagination';

import { ASSETS_TABLE } from './constants/ui-text.const';

interface IProps {
  t: (key: string) => string;
}

export const AssetsSection: React.FC<IProps> = ({ t }) => {
  const dispatch = useAppDispatch();
  const assets = useSelector(selectAssets);
  const assetsStatus = useSelector(selectAssetsStatus);
  const btcToUsdRate = useSelector(selectBtcUsdRate);

  const { innerWidth } = useInnerWidth();

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(assets.length / ASSETS_ITEMS_PER_PAGE);

  const isPending = assetsStatus === 'pending';

  const displayedMinersByModel = transformMinersByStatusToMinersByModel(
    assets.slice((currentPage - 1) * ASSETS_ITEMS_PER_PAGE, currentPage * ASSETS_ITEMS_PER_PAGE),
  );

  const handleSetCurrentPage = (newPage: number): void => {
    if (newPage <= 0) {
      setCurrentPage(1);
    } else if (newPage > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    void dispatch(requestAssetsData());
    void dispatch(fetchPairRatesThunkAction());
  }, [dispatch]);

  return (
    <SPageSection tittleCeo={ASSETS_TABLE}>
      {innerWidth >= parseInt(BreakPoint.TabletLow) ? (
        <AssetsTableDesktop
          minersByModel={displayedMinersByModel}
          btcToUsdRate={btcToUsdRate}
          isPending={isPending}
          t={t}
        />
      ) : (
        <AssetsTableMobile
          minersByModel={displayedMinersByModel}
          btcToUsdRate={btcToUsdRate}
          isPending={isPending}
          t={t}
        />
      )}
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleSetCurrentPage={handleSetCurrentPage}
          t={t}
        />
      )}
    </SPageSection>
  );
};
