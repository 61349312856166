import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const LimitedUptimeHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    flex-direction: column;
  }
`;

export const SwitcherWrapper = styled.div`
  display: flex;
  gap: 12px;
  width: max-content;
  padding: 8px 12px;
  border-radius: 12px;
  background-color: ${EColors.Blue4};
`;

export const Tittle = styled(SText)`
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${EColors.White};
`;

export const HowItWorksButton = styled(Button)`
  color: ${EColors.White};

  &:active,
  &:focus,
  &:hover {
    color: ${EColors.White};
  }
`;
