import React from 'react';
import { ReactComponent as BtcGray } from 'assets/icons/dashboard-page-icons/btc-gray.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/dashboard-page-icons/plus.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/dashboard-page-icons/upload.svg';
import { ReactComponent as WalletBlack } from 'assets/icons/dashboard-page-icons/wallet-black.svg';
import { SArticleTitle } from 'pages/PageDashboard/components/styled';

import * as S from './WalletArticle.styled';

interface IWalletArticleProps {
  btcValue: string;
  usdValue: string;
  handleDepositClick: () => void;
  handleWithdrawClick: () => void;
  t: (key: string) => string;
}

export const WalletArticle: React.FC<IWalletArticleProps> = ({
  btcValue,
  usdValue,
  handleWithdrawClick,
  handleDepositClick,
  t,
}) => {
  return (
    <S.WalletArticle as="article">
      <SArticleTitle as="h3">
        <WalletBlack />
        {t('wallet.walletSectionTitle')}
      </SArticleTitle>
      <S.BalanceList as="dl">
        <S.BalanceTitle as="dt">
          <BtcGray />
          {t('wallet.totalBalanceTitle')}
        </S.BalanceTitle>
        <S.BalanceDescription as="dd">
          <S.BtcValue>{btcValue}</S.BtcValue>
          <S.UsdValue>{usdValue}</S.UsdValue>
        </S.BalanceDescription>
      </S.BalanceList>
      <S.WalletButtons>
        <S.WalletButton
          text={t('deposit.deposit')}
          icon={<PlusIcon />}
          onClick={handleDepositClick}
        />
        <S.WalletButton
          text={t('withdrawContent.withdraw')}
          icon={<UploadIcon />}
          onClick={handleWithdrawClick}
        />
      </S.WalletButtons>
    </S.WalletArticle>
  );
};
