import React from 'react';
import { ReactComponent as MinerIcon } from 'assets/icons/button-icons/miner.svg';
import { ReactComponent as Calendar } from 'assets/icons/dashboard-page-icons/calendar.svg';
import { ReactComponent as Clock } from 'assets/icons/dashboard-page-icons/clock.svg';
import { ReactComponent as HashrateIcon } from 'assets/icons/dashboard-page-icons/hashrate.svg';
import { ReactComponent as MinerBlack } from 'assets/icons/dashboard-page-icons/miner-black.svg';
import { ReactComponent as MinerGray } from 'assets/icons/dashboard-page-icons/miner-gray.svg';
import { ReactComponent as TimerIcon } from 'assets/icons/dashboard-page-icons/timer.svg';
import { SArticleTitle } from 'pages/PageDashboard/components/styled';

import { Button } from 'components/ui/Button';

import * as S from './MiningArticle.styled';

interface IMiningArticleProps {
  hashrate: string;
  activeMinersCount: string;
  launchPendingHashrate: string;
  launchPendingMinersCount: string;
  minedYesterdayBtc: string;
  totalMinedBtc: string;
  t: (key: string) => string;
  isEnabled: boolean;
  openLimitedUptimePopup: () => void;
}

export const MiningArticle: React.FC<IMiningArticleProps> = ({
  hashrate,
  activeMinersCount,
  launchPendingMinersCount,
  launchPendingHashrate,
  minedYesterdayBtc,
  totalMinedBtc,
  t,
  isEnabled,
  openLimitedUptimePopup,
}) => {
  return (
    <S.MiningArticle as="article">
      <S.ArticleTittleWrapper>
        <SArticleTitle as="h3">
          <MinerBlack />
          {t('mining.sectionTitle')}
        </SArticleTitle>
        <Button
          className="article-button"
          variant={'primary-gray'}
          text={isEnabled ? t('uptime.65uptime') : t('uptime.100uptime')}
          icon={<MinerIcon />}
          onClick={openLimitedUptimePopup}
        />
      </S.ArticleTittleWrapper>
      <S.HashrateList as="dl">
        <S.HashrateAmountWrapper>
          <HashrateIcon />
          <S.HashrateTitle as="dt">{t('mining.hashRateAmountTitle')}</S.HashrateTitle>
          <S.HashrateAmountValue as="dd">{hashrate}</S.HashrateAmountValue>
          <S.HashrateMinersCount as="dd">
            <MinerGray />
            {activeMinersCount}
          </S.HashrateMinersCount>
        </S.HashrateAmountWrapper>

        <S.LaunchPendingWrapper>
          <TimerIcon />
          <S.LaunchPendingTitle as="dt">{t('mining.launchPendingTitle')}</S.LaunchPendingTitle>
          <S.LaunchPendingHashrateValue as="dd">
            {launchPendingHashrate}
          </S.LaunchPendingHashrateValue>
          <S.LaunchPendingMinersCount as="dd">
            <MinerGray />
            {launchPendingMinersCount}
          </S.LaunchPendingMinersCount>
        </S.LaunchPendingWrapper>
      </S.HashrateList>

      <S.MiningList as="dl">
        <S.MiningItemWrapper>
          <S.MiningTitle as="dt">
            <Clock />
            {t('mining.minedYesterdayTitle')}
          </S.MiningTitle>
          <S.MiningValue as="dd">{minedYesterdayBtc}</S.MiningValue>
        </S.MiningItemWrapper>
        <S.MiningItemWrapper>
          <S.MiningTitle as="dt">
            <Calendar />
            {t('mining.totalMinedTitle')}
          </S.MiningTitle>
          <S.MiningValue as="dd">{totalMinedBtc}</S.MiningValue>
        </S.MiningItemWrapper>
      </S.MiningList>
    </S.MiningArticle>
  );
};
