import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { selectInvoiceData } from 'store/invoice-reducer/invoice.selectors';
import { selectIpData } from 'store/ip-reducer/ip.selectors';
import { fetchIpData } from 'store/ip-reducer/ip.thunk-actions';
import { selectProfile } from 'store/profile-reducer/profile.selectors';
import { useAppDispatch } from 'store/store';

import { getBrowserName } from 'utils/common/get-browser-name.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './AdditionalTerms.styled';

export const AdditionalTerms: React.FC = () => {
  const dispatch = useAppDispatch();
  const today = dayjs();
  const profile = useSelector(selectProfile);
  const invoice = useSelector(selectInvoiceData);
  const invoiceMiners = invoice ? Object.values(invoice?.invoices) : [];
  const ipData = useSelector(selectIpData);
  const browserName = getBrowserName();
  const { t } = useTranslation();

  useEffect(() => {
    void dispatch(fetchIpData());
  }, [dispatch]);

  return (
    <S.AdditionalTermsArticle>
      <S.AdditionalTermsTitle>{`Cuverse Miner Services Agreement №${invoice?.id}`}</S.AdditionalTermsTitle>
      <S.TermDescription>
        <S.DescriptionParagraph>
          {t('additionalTerms.description1Paragraph')}
        </S.DescriptionParagraph>
        <S.DescriptionParagraph>
          {t('additionalTerms.description2Paragraph')}
        </S.DescriptionParagraph>
        <S.DescriptionParagraph>
          {t('additionalTerms.description3Paragraph')}
        </S.DescriptionParagraph>
        <S.DescriptionParagraph>
          {t('additionalTerms.description4Paragraph')}
        </S.DescriptionParagraph>
        <S.DescriptionParagraph>
          {t('additionalTerms.description5Paragraph')}
        </S.DescriptionParagraph>
      </S.TermDescription>

      <S.TermsCategoriesList>
        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.provisionTermsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision3Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision4Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision5Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision6Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision7Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.provision8Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.paymentTermsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment3Term')}</S.TermText>
              <S.TermsInner1LevelList>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>
                    {t('additionalTerms.payment3-1Term')}
                  </S.TermInner1LevelText>
                </S.TermInner1LevelItem>
              </S.TermsInner1LevelList>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment4Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment5Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment6Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment7Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.payment8Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.accomplishmentTermsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment1Term')}</S.TermText>
              <S.TableWrapper>
                <S.TableTitle>{t('additionalTerms.accomplishment1TableTitle')}</S.TableTitle>
                <S.HorizontalTable>
                  <tr>
                    <th>{t('additionalTerms.guaranteedAvailabilityTableHeading')}</th>
                    <td>{t('additionalTerms.guaranteedAvailabilityTableData')}</td>
                  </tr>
                  <tr>
                    <th>{t('additionalTerms.monthlyHealthTableHeading')}</th>
                    <td>{t('additionalTerms.monthlyHealthTableData')}</td>
                  </tr>
                </S.HorizontalTable>
              </S.TableWrapper>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment3Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment4Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment5Term')}</S.TermText>
              <S.TableWrapper>
                <S.TableTitle>{t('additionalTerms.accomplishment5TableTitle')}</S.TableTitle>
                <S.VerticalTable>
                  <tr>
                    <th>{t('additionalTerms.serviceAvailableTableHeading')}</th>
                    <th>{t('additionalTerms.serviceMaintenanceTableHeading')}</th>
                    <th>{t('additionalTerms.compensationTableHeading')}</th>
                  </tr>
                  <tr>
                    <td>over 99.8%</td>
                    <td>up to 1 hr. 30 min.</td>
                    <td>not compensated</td>
                  </tr>
                  <tr>
                    <td>from 99.79 to 99%</td>
                    <td>from 1 hr. 31 min. to 7 hr. 18 min.</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>from 98.99 to 98%</td>
                    <td>from 7 hrs. 19 min. to 14 hrs. 36 min.</td>
                    <td>30%</td>
                  </tr>
                  <tr>
                    <td>from 97.991 to 96.7%</td>
                    <td>from 14 hrs. 37 min. to 23 hrs. 59 min.</td>
                    <td>70%</td>
                  </tr>
                  <tr>
                    <td>under 96.6%</td>
                    <td>from 24 hours</td>
                    <td>100%</td>
                  </tr>
                </S.VerticalTable>
              </S.TableWrapper>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment6Term')}</S.TermText>
              <S.TableWrapper>
                <S.TableTitle>{t('additionalTerms.accomplishment6TableTitle')}</S.TableTitle>
                <S.VerticalTable>
                  <tr>
                    <th>{t('additionalTerms.compensableDowntimeTableHeading')}</th>
                    <th>{t('additionalTerms.nonCompensableDowntimeTableHeading')}</th>
                  </tr>
                  <tr>
                    <td>{t('additionalTerms.compensableDowntimeTableData')}</td>
                    <td>{t('additionalTerms.nonCompensableDowntimeTableData')}</td>
                  </tr>
                </S.VerticalTable>
              </S.TableWrapper>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment7Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment8Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment9Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment10Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment11Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.accomplishment12Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.obligationsTermsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.obligations1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.obligations2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.obligations3Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.obligations4Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.obligations5Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.obligations6Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.obligations7Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.suspensionTermsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.suspension1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.suspension2Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.terminationTermsTitle')}</S.CategoryTitle>
          <S.CategoryDescription>
            {t('additionalTerms.terminationTermsDescription')}
          </S.CategoryDescription>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.termination1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.termination2Term')}</S.TermText>
              <S.TermsInner1LevelList>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>
                    {t('additionalTerms.termination2-1Term')}
                  </S.TermInner1LevelText>
                  <S.TermsInner2LevelList>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>
                        {t('additionalTerms.termination2-1-1Term')}
                      </S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>
                        {t('additionalTerms.termination2-1-2Term')}
                      </S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>
                        {t('additionalTerms.termination2-1-3Term')}
                      </S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                  </S.TermsInner2LevelList>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>
                    {t('additionalTerms.termination2-2Term')}
                  </S.TermInner1LevelText>
                  <S.TermsInner2LevelList>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>
                        {t('additionalTerms.termination2-2-1Term')}
                      </S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                    <S.TermInner2LevelItem>
                      <S.TermInner2LevelText>
                        {t('additionalTerms.termination2-2-2Term')}
                      </S.TermInner2LevelText>
                    </S.TermInner2LevelItem>
                  </S.TermsInner2LevelList>
                </S.TermInner1LevelItem>
              </S.TermsInner1LevelList>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.termination3Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.termination4Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.termination5Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.termination6Term')}</S.TermText>
              <S.TermsInner1LevelList>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>
                    {t('additionalTerms.termination6-1Term')}
                  </S.TermInner1LevelText>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>
                    {t('additionalTerms.termination6-2Term')}
                  </S.TermInner1LevelText>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>
                    {t('additionalTerms.termination6-3Term')}
                  </S.TermInner1LevelText>
                </S.TermInner1LevelItem>
                <S.TermInner1LevelItem>
                  <S.TermInner1LevelText>
                    {t('additionalTerms.termination6-4Term')}
                  </S.TermInner1LevelText>
                </S.TermInner1LevelItem>
              </S.TermsInner1LevelList>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.disclaimerTitle')}</S.CategoryTitle>
          <S.CategoryDescriptionUppercase>
            {t('additionalTerms.disclaimerText')}
          </S.CategoryDescriptionUppercase>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.agreementTitle')}</S.CategoryTitle>
          <S.CategoryDescription>{t('additionalTerms.agreementText')}</S.CategoryDescription>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.warrantyTermsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.warranty1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.warranty2Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.forceMajeureTermsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.forceMajeure1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.forceMajeure2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.forceMajeure3Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('additionalTerms.forceMajeure4Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('additionalTerms.definitionsTitle')}</S.CategoryTitle>
          <S.DefinitionsList>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.serverTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>
                {t('additionalTerms.serverDescription')}
              </S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.servicesTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>
                {t('additionalTerms.servicesDescription')}
              </S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.customersEquipmentTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>
                {t('additionalTerms.customerEquipmentDescription')}
              </S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.proxyServerTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>
                {t('additionalTerms.proxyServerDescription')}
              </S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.dashboardTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>
                {t('additionalTerms.dashboardDescription')}
              </S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.softGraceTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>
                {t('additionalTerms.softGraceDescription')}
              </S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.hardGraceTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${t('additionalTerms.hardGraceDescription')}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.hashRateTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${t('additionalTerms.hashRateDescription')}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.hashingTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${t('additionalTerms.hashingDescription')}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.hashFunctionTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${t('additionalTerms.hashFunctionDescription')}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.algorithmSHA256Term')}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${t('additionalTerms.algorithmSHA256Description')}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.partiesTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${t('additionalTerms.partiesDescription')}`}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('additionalTerms.balanceTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{` — ${t('additionalTerms.balanceDescription')}`}</S.DefinitionDescription>
            </div>
          </S.DefinitionsList>
        </S.TermsCategory>
      </S.TermsCategoriesList>

      <S.AgreementSpecificationTitle>
        {t('additionalTerms.agreementSpecificationsTitle')}
      </S.AgreementSpecificationTitle>
      <S.DataList>
        <div>
          <S.DataTerm>{`${t('additionalTerms.effectiveTerm')}: `}</S.DataTerm>
          <S.DataDescription>{today.format('DD.MM.YYYY')}</S.DataDescription>
        </div>
        <S.TermDescription>
          <S.DescriptionParagraph>
            {t('additionalTerms.effective1Paragraph')}
          </S.DescriptionParagraph>
          <S.DescriptionParagraph>
            {t('additionalTerms.effective2Paragraph')}
          </S.DescriptionParagraph>
          <S.DescriptionParagraph>
            {t('additionalTerms.effective3Paragraph')}
          </S.DescriptionParagraph>
        </S.TermDescription>
        <div>
          <S.DataTerm>{`${t('additionalTerms.customerInfoIDTitle')}: `}</S.DataTerm>
          <S.DataDescription>{profile.id}</S.DataDescription>
        </div>
        <div>
          <S.DataTerm>{`${t('additionalTerms.accountNameTitle')}: `}</S.DataTerm>
          <S.DataDescription>{profile.email}</S.DataDescription>
        </div>
        <div>
          <S.DataTerm>{`${t('additionalTerms.userNameTitle')}: `}</S.DataTerm>
          <S.DataDescription>{`${profile.firstname} ${profile.lastname}`}</S.DataDescription>
        </div>
        <div>
          <S.DataTerm>{`${t('additionalTerms.billingAddressTitle')}: `}</S.DataTerm>
          <S.DataDescription>{profile.address}</S.DataDescription>
        </div>
      </S.DataList>

      <ul>
        {invoiceMiners.map((miner) => (
          <S.InvoiceMiner key={miner.name}>
            <S.DataList>
              <div>
                <S.DataTerm>{`${t('additionalTerms.productNameTitle')}: `}</S.DataTerm>
                <S.DataDescription>{miner.name}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.manufacturerTitle')}: `}</S.DataTerm>
                <S.DataDescription>{miner.manufacturer}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.modelTitle')}: `}</S.DataTerm>
                <S.DataDescription>{miner.model}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.amountTitle')}: `}</S.DataTerm>
                <S.DataDescription>
                  {`${(Number(miner.price_usd) / Number(miner.unit_price)).toFixed(0)} pcs.`}
                </S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.algorithmTitle')}: `}</S.DataTerm>
                <S.DataDescription>{miner.algorithm}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.hashRateTitle')}: `}</S.DataTerm>
                <S.DataDescription>{`${miner.hashrate.toFixed(0)} TH/s`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.upfrontFeesTitle')}: `}</S.DataTerm>
                <S.DataDescription>
                  {`$${formatUsdOutput(Number(miner.price_usd) / miner.hashrate)} per TH/s`}
                </S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.electricityFeeTitle')}: `}</S.DataTerm>
                <S.DataDescription>{`$${t('additionalTerms.electricityFeeDefaultValue')} per TH/s`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.managementFeeTitle')}: `}</S.DataTerm>
                <S.DataDescription>{`${Number(miner.management_fee).toFixed(2)}%`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.termTitle')}: `}</S.DataTerm>
                <S.DataDescription>{`${miner.term.toFixed(0)} days`}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.startDateTitle')}: `}</S.DataTerm>
                <S.DataDescription>{miner.start_at}</S.DataDescription>
              </div>
              <div>
                <S.DataTerm>{`${t('additionalTerms.discountTitle')}: `}</S.DataTerm>
                <S.DataDescription>{`$${miner.discount}`}</S.DataDescription>
              </div>
            </S.DataList>
          </S.InvoiceMiner>
        ))}
      </ul>

      <S.SignatureWrapper>
        <S.SignatureTitle>{t('additionalTerms.signatureInfoTitle')}</S.SignatureTitle>
        <S.DataList>
          <div>
            <S.DataTerm>{`${t('additionalTerms.ipAddressTitle')}: `}</S.DataTerm>
            <S.DataDescription>{ipData?.ip}</S.DataDescription>
          </div>
          <div>
            <S.DataTerm>{`${t('additionalTerms.countryTitle')}: `}</S.DataTerm>
            <S.DataDescription>{ipData?.countryName}</S.DataDescription>
          </div>
          <div>
            <S.DataTerm>{`${t('additionalTerms.dateAndTimeTitle')}: `}</S.DataTerm>
            <S.DataDescription>{today.format('MMM DD, YYYY h:mm:ss a')}</S.DataDescription>
          </div>
          <div>
            <S.DataTerm>{`${t('additionalTerms.browserTitle')}: `}</S.DataTerm>
            <S.DataDescription>{browserName}</S.DataDescription>
          </div>
        </S.DataList>
      </S.SignatureWrapper>
    </S.AdditionalTermsArticle>
  );
};
