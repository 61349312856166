import { useSelector } from 'react-redux';
import { useAppDispatch } from 'store/store';
import {
  selectCooldown,
  selectLastUptimeToggle,
  selectUptime,
  selectUptimeStatus,
} from 'store/uptime-reducer/uptime.selectors';
import { setUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';
import { TFetchStatus } from 'types/api/fetch-status.type';

interface IUseUptimePing {
  handleLimitedUptimeDisabled: () => void;
  handleLimitedUptimeEnabled: () => void;
  uptime: boolean;
  lastUptimeToggle: string | null;
  cooldown: boolean;
  uptimeStatus: TFetchStatus;
}

/**
 * Hook for handling limited uptime.
 *
 * @returns this hook returns an object with the following properties:
 * @param handleLimitedUptimeDisabled - function to disable limited uptime
 * @param handleLimitedUptimeEnabled - function to enable limited uptime
 * @param uptime - boolean indicating if limited uptime is enabled
 * @param lastUptimeToggle - string indicating the last time the limited uptime was toggled
 * @param cooldown - boolean indicating if cooldown is active
 * @param uptimeStatus - string indicating the status of the uptime request
 */
export const useUptime = (): IUseUptimePing => {
  const dispatch = useAppDispatch();
  const { uptime } = useSelector(selectUptime);
  const { lastUptimeToggle } = useSelector(selectLastUptimeToggle);
  const { cooldown } = useSelector(selectCooldown);
  const { uptimeStatus } = useSelector(selectUptimeStatus);

  const handleLimitedUptimeDisabled = () => {
    void dispatch(setUptimeThunkAction({ uptimeAction: 'disable' }));
  };
  const handleLimitedUptimeEnabled = () => {
    void dispatch(setUptimeThunkAction({ uptimeAction: 'enable' }));
  };

  return {
    handleLimitedUptimeDisabled,
    handleLimitedUptimeEnabled,

    uptime,
    lastUptimeToggle,
    cooldown,
    uptimeStatus,
  };
};
