import React, { FC, useState } from 'react';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './ProfitSection.styled';

interface IProfit {
  estDailyOutput: number;
  btcUsdRate: number;
  electricityFees: number;
  t: (key: string) => string;
}

export const ProfitSection: FC<IProfit> = ({
  estDailyOutput,
  electricityFees,
  t,
}): React.ReactElement => {
  const items = [
    { text: '$70 000', value: '70000' },
    { text: '$80 000', value: '80000' },
    { text: '$90 000', value: '90000' },
    { text: '$100 000', value: '100000' },
    { text: '$110 000', value: '110000' },
    { text: '$120 000', value: '120000' },
  ];
  const [val, setVal] = useState(items[0].value);

  const labels = [
    t('minerInfo.period'),
    t('minerInfo.electricityFees'),
    t('minerInfo.totalIncome'),
    t('minerInfo.totalProfit'),
  ];
  const fee = electricityFees * 365;
  const income = +val * estDailyOutput * 365;
  const btcValue = income / +val;
  const profit = income - fee;
  const values = [
    {
      title: `1 ${t('minerInfo.year')}`,
      fee: `$${formatUsdOutput(fee)}`,
      income: `$${formatUsdOutput(income)}`,
      btcValue: `$${formatUsdOutput(btcValue)}`,
      profit: `$${formatUsdOutput(profit)}`,
    },
    {
      title: `3 ${t('minerInfo.years')}`,
      fee: `$${formatUsdOutput(fee * 3)}`,
      income: `$${formatUsdOutput(income * 3)}`,
      btcValue: `$${formatUsdOutput(btcValue * 3)}`,
      profit: `$${formatUsdOutput(profit * 3)}`,
    },
    {
      title: `5 ${t('minerInfo.years')}`,
      fee: `$${formatUsdOutput(fee * 5)}`,
      income: `$${formatUsdOutput(income * 5)}`,
      btcValue: `$${formatUsdOutput(btcValue * 5)}`,
      profit: `$${formatUsdOutput(profit * 5)}`,
    },
  ];

  return (
    <S.ChartsInfo>
      <S.ProfitTitle>{t('minerInfo.profitPerMiner')}</S.ProfitTitle>
      <S.ProfitWrapper>
        <S.BtcPricer>
          <S.HeadCell>{t('minerInfo.btnPrice')}</S.HeadCell>
          <S.ProfitSelect
            items={items}
            currentValue={val}
            onChange={(newValue) => setVal(newValue)}
          />
        </S.BtcPricer>

        <S.Table>
          <S.TableHead>
            <S.TableTr>
              {labels.map((label) => (
                <S.Th key={label}>{label}</S.Th>
              ))}
            </S.TableTr>
          </S.TableHead>
          <tbody>
            <S.TableTrHr>
              <td colSpan={4}>
                <S.Hr />
              </td>
            </S.TableTrHr>
            {values.map((value) => (
              <tr key={value.title}>
                <S.Text>{value.title}</S.Text>
                <S.Text>{value.fee}</S.Text>
                <td>
                  <S.Income>{value.income}</S.Income>
                  <S.BtcValue>{value.btcValue} BTC</S.BtcValue>
                </td>
                <S.Text>{value.profit}</S.Text>
              </tr>
            ))}
          </tbody>
        </S.Table>
      </S.ProfitWrapper>
    </S.ChartsInfo>
  );
};
