import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const CommonWrapper = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 3fr 2fr;
  font-family: ${FontFamily.Montserrat};

  @media (max-width: ${BreakPoint.LaptopTop}) and (min-width: ${BreakPoint.LaptopLow}) {
    grid-template-columns: 3fr 2fr;
  }

  @media (max-width: ${BreakPoint.LaptopLow}) {
    grid-template-columns: 1fr;
  }
`;
