import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ELanguages, LanguageContextType, TLanguages } from './types/language-provider.interface';

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const savedLanguage = (localStorage.getItem('language') as TLanguages) || 'ENG';
  const [language, setLanguage] = useState<TLanguages>(savedLanguage);

  const changeLanguage = async (lng: TLanguages) => {
    setLanguage(lng);
    localStorage.setItem('language', lng);
    let currentLanguage: string;
    switch (lng) {
      case 'DEU':
        currentLanguage = 'de';
        break;
      case 'ITA':
        currentLanguage = 'it';
        break;
      case 'ESP':
        currentLanguage = 'es';
        break;
      case 'ENG':
      default:
        currentLanguage = 'en';
        break;
    }
    await i18n.changeLanguage(currentLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
