import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FetchStatus } from 'constants/fetch-status/fetch-status.const';
import { NBSP_JS } from 'constants/unicode/nbsp-js';
import { selectDisable2FAFetchStatus } from 'store/auth-reducer/auth.selectors';
import { requestDisable2FAThunkAction } from 'store/auth-reducer/auth.thunk-actions';
import { useAppDispatch } from 'store/store';
import { ICodeVerificationFormValues } from 'types/ui/CodeVerificationForm/code-verification-form-values.interface';

import { CodeVerificationForm } from 'components/ui/CodeVerificationForm';

import { Disable2FAConfirmation } from './components/ui/Disable2FAConfirmation';
import { TDisable2FAStep } from './components/ui/Disable2FAConfirmation/types/disable-2fa-step.type';

import * as S from './PageDisable2FA.styled';

export const PageDisable2FA: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [disable2FAStep, setDisable2FAStep] = useState<TDisable2FAStep>('initial');
  const [secretCode, setSecretCode] = useState<string>('');

  const disable2FAFetchStatus = useSelector(selectDisable2FAFetchStatus);
  const isDataPending = disable2FAFetchStatus === FetchStatus.Pending;

  const handleSubmitVerificationForm = (values: ICodeVerificationFormValues) => {
    setSecretCode(Object.values(values).join(''));
    setDisable2FAStep('confirmation');
  };

  const handleConfirmDisabling2FA = () => {
    void dispatch(requestDisable2FAThunkAction({ auth_confirm_code: secretCode })).then(() =>
      navigate(-1),
    );
  };

  const handleCancel = () => navigate(-1);

  switch (disable2FAStep) {
    case 'initial':
      return (
        <S.Container>
          <CodeVerificationForm
            title={`${t('disable2fa.title')}${NBSP_JS}Authenticator${t('disable2fa.auth')}`}
            handleSubmit={handleSubmitVerificationForm}
            handleCancel={handleCancel}
            t={t}
          />
        </S.Container>
      );
    case 'confirmation':
      return (
        <S.Container>
          <Disable2FAConfirmation
            handleConfirm={handleConfirmDisabling2FA}
            handleCancel={handleCancel}
            isPending={isDataPending}
            t={t}
          />
        </S.Container>
      );
  }
};
