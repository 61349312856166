import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import styled, { css } from 'styled-components';
import { shake } from 'styles/keyframes/shake';
import { AnimationDuration } from 'styles/style-variables/animation-duration';
import { BreakPoint } from 'styles/style-variables/breakpoint';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';
import { Input } from 'components/ui/Input';

export const PromoInitialWrapper = styled.div<{ $bottomBordered: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: 32px;

  ${({ $bottomBordered }) => {
    if ($bottomBordered) {
      return css`
        margin-bottom: 16px;
        border-bottom: 1px solid #eaeaea;
      `;
    }
  }}
`;

export const PromoButton = styled(Button)`
  justify-content: start;
  padding: 0;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-height: 48px;
`;

export const PromoForm = styled.form<{ $isFailed: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  min-height: 32px;

  ${({ $isFailed }) => {
    if ($isFailed) {
      return css`
        animation: ${shake} ${AnimationDuration.Shake} ease;
      `;
    }
  }};
`;

export const PromoInput = styled(Input)`
  & input {
    flex-grow: 1;
    height: auto;
    padding: 7px 12px;
    font-size: 12px;
    line-height: 16px;

    &::placeholder {
      font-size: 12px;
      line-height: 16px;
    }
  }
`;

export const SendingButton = styled(Button)`
  padding: 7px 16px;
`;

export const ErrorText = styled(SText)`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: #ec494f;
`;

export const PromoStatus = styled.div<{
  $promoStatus: EPromoCodeStatus;
  $bottomBordered?: boolean;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-height: 32px;
  padding: 7px 12px;
  border-radius: 12px;
  background: #e5eaf1;
  ${({ $promoStatus }) => {
    if ($promoStatus === EPromoCodeStatus.Rejected) {
      return css`
        background: #fce7e7;
      `;
    }
  }};

  ${({ $bottomBordered }) => {
    if ($bottomBordered) {
      return css`
        margin-bottom: 16px;
        border-bottom: 1px solid #eaeaea;
      `;
    }
  }}

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    align-self: start;
  }

  & svg {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }
`;

export const ConfirmationText = styled(SText)<{ $promoStatus: EPromoCodeStatus }>`
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  ${({ $promoStatus }) => {
    if ($promoStatus === EPromoCodeStatus.Rejected) {
      return css`
        color: #ec494f;
      `;
    }
  }};
`;

export const CloseButtonWrapper = styled.div`
  margin-left: auto;

  & svg path {
    fill: #ec494f;
    stroke: #ec494f;
    stroke-width: 3%;
  }

  &:hover,
  &:focus {

    & svg path {
      fill: #f1777c;
      stroke: #f1777c;
    }
  }

  &:active {

    & svg path {
      fill: #e71b22;
      stroke: #e71b22;
    }
  }
`;
