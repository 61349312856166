import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const HighLightsTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 18px;
  }
`;

export const HighLightsContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  padding: 24px;
  border-radius: 16px;
  background-color: #e5eaf1;
  font-family: ${FontFamily.Montserrat};
`;

export const SubtitleText = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 14px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    font-size: 12px;
  }
`;

export const InfoCardsBlock = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 16px;
    grid-template-columns: 1fr;
  }
`;

export const HighLightsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 12px;
    width: 100%;
  }
`;

export const HighLightsSubtitles = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
