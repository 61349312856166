import { createSelector } from '@reduxjs/toolkit';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { TPurchaseStep } from 'store/api/cuverse-api/purchase/types/purchase-step.type';
import { TRootState } from 'store/store';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { IPurchaseState } from './purchase.reducer';

export const getPurchaseState = (state: TRootState): IPurchaseState => state.purchaseReducer;

export const selectActiveMiners = createSelector(
  [getPurchaseState],
  (state): IMinerDataMapped[] => state.activeMiners,
);

export const selectMinersFetchStatus = createSelector(
  [getPurchaseState],
  (state): TFetchStatus => state.minersFetchStatus,
);

export const selectPurchaseStep = createSelector(
  [getPurchaseState],
  (state): TPurchaseStep => state.purchaseStep,
);
