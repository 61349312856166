import { useSelector } from 'react-redux';
import { SUGGESTED_AMOUNTS } from 'pages/PageDashboard/components/logic/DepositElectricityForm/constants/suggested-amounts.const';
import { selectDashboardElectricity } from 'store/dashboard-reducer/dashboard.selectors';

interface IAmount {
  period: number;
  usdCost: number;
}

type TAmountValue = '30d' | '60d' | '90d' | '120d';

export type TPaymentsPerDays = Record<TAmountValue, IAmount>;

export const usePaymentsPerDays = (): {
  paymentsPerDays: TPaymentsPerDays;
  oneDayAmount: number;
} => {
  const { dailyTotalFee } = useSelector(selectDashboardElectricity);

  const dailyTotalFeeToDisplay = dailyTotalFee === 0 ? 1 : dailyTotalFee;

  const paymentsPerDays = {} as TPaymentsPerDays;

  for (const amount of SUGGESTED_AMOUNTS) {
    paymentsPerDays[amount.toString() as TAmountValue] = {
      period: amount,
      usdCost: amount * dailyTotalFeeToDisplay,
    };
  }

  return { paymentsPerDays, oneDayAmount: dailyTotalFeeToDisplay };
};
