import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { NewPasswordForm } from 'pages/PagePasswordReset/components/ui/NewPasswordForm';
import { PasswordResetForm } from 'pages/PagePasswordReset/components/ui/PasswordResetForm';
import {
  resetPasswordChangeStepAction,
  setPasswordChangeStepAction,
} from 'store/auth-reducer/auth.reducer';
import {
  selectChangePasswordStep,
  selectOtpVerificationStatus,
  selectPasswordChangeCodeFetchStatus,
  selectPasswordResetStatus,
} from 'store/auth-reducer/auth.selectors';
import {
  requestOtpVerificationThunkAction,
  requestPasswordChangeCodeThunkAction,
  requestPasswordResetThunkAction,
} from 'store/auth-reducer/auth.thunk-actions';
import { useAppDispatch } from 'store/store';
import { INewPasswordFormValues } from 'types/ui/NewPasswordForm/new-password-form-values.interface';
import { IOtpFormValues } from 'types/ui/OtpForm/otp-form-values.interface';
import { IPasswordResetFormValues } from 'types/ui/PasswordResetForm/password-reset-form-values.interface';

import { AuthSuccess } from 'components/ui/AuthSuccess';
import { OtpForm } from 'components/ui/OtpForm';
import { gtmClickSendForm } from 'utils/gtmSender/gtmSender';
import { AppRoute } from 'utils/route/app-route';
import { setEmailSendTime } from 'utils/storage/date-and-time/set-email-send-time.util';
import { getEmail } from 'utils/storage/email/get-email.util';
import { setEmail } from 'utils/storage/email/set-email.util';
import { getOtpToken } from 'utils/storage/otp/get-otp-token.util';

export const PagePasswordReset: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const passwordChangeCodeFetchStatus = useSelector(selectPasswordChangeCodeFetchStatus);
  const otpVerificationFetchStatus = useSelector(selectOtpVerificationStatus);
  const passwordResetStatus = useSelector(selectPasswordResetStatus);
  const passwordChangeStep = useSelector(selectChangePasswordStep);

  const handlePasswordResetFormSubmit = ({ email }: IPasswordResetFormValues) => {
    setEmail(email);
    void dispatch(
      requestPasswordChangeCodeThunkAction({
        email,
        onSuccessCb: () => {
          setEmailSendTime();
          dispatch(setPasswordChangeStepAction('confirm-code'));
        },
      }),
    );
  };

  const handleResendOtp = () => {
    setEmailSendTime();
    void dispatch(
      requestPasswordChangeCodeThunkAction({
        email: getEmail(),
        onSuccessCb: () => null,
      }),
    );
  };

  const handleOtpFormSubmit = ({ code }: IOtpFormValues) => {
    void dispatch(
      requestOtpVerificationThunkAction({
        code,
        onSuccessCb: () => dispatch(setPasswordChangeStepAction('new-password')),
      }),
    );
  };

  const handleOtpBackClick = () => dispatch(setPasswordChangeStepAction('request-code'));

  const handleNewPasswordFormSubmit = ({ password }: INewPasswordFormValues) => {
    void dispatch(
      requestPasswordResetThunkAction({
        body: {
          password,
          password_confirmation: password,
          email: getEmail(),
          token: getOtpToken(),
        },
        onSuccessCb: () => {
          dispatch(setPasswordChangeStepAction('success'));
        },
      }),
    );
  };

  const handleContinueOnSuccess = () => {
    navigate(AppRoute.Login());
    dispatch(resetPasswordChangeStepAction());
    gtmClickSendForm('Log in');
  };

  switch (passwordChangeStep) {
    case 'request-code':
      return (
        <PasswordResetForm
          handleFormSubmit={handlePasswordResetFormSubmit}
          isSubmitting={passwordChangeCodeFetchStatus === 'pending'}
          hasBackendError={passwordChangeCodeFetchStatus === 'rejected'}
          t={t}
        />
      );
    case 'confirm-code':
      return (
        <OtpForm
          title={t('passwordReset.otpFormTitle')}
          email={getEmail()}
          handleFormSubmit={handleOtpFormSubmit}
          handleResendCode={handleResendOtp}
          handleBackClick={handleOtpBackClick}
          isSubmitting={otpVerificationFetchStatus === 'pending'}
          hasBackendError={otpVerificationFetchStatus === 'rejected'}
          t={t}
        />
      );
    case 'new-password':
      return (
        <NewPasswordForm
          handleFormSubmit={handleNewPasswordFormSubmit}
          isSubmitting={passwordResetStatus === 'pending'}
          hasBackendError={passwordResetStatus === 'rejected'}
          t={t}
        />
      );
    case 'success':
      return <AuthSuccess variant="password-reset" handleContinue={handleContinueOnSuccess} />;
  }
};
