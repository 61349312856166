import React from 'react';
import { ReactComponent as Active } from 'assets/icons/assets-page-icons/active.svg';
import { ReactComponent as Disabled } from 'assets/icons/assets-page-icons/disabled.svg';
import { ReactComponent as Waiting } from 'assets/icons/assets-page-icons/waiting.svg';
import { TMinerStatus } from 'pages/PageAssets/types/miner-status.type';

import * as S from './MinerStatus.styled';

interface IMinerStatusProps {
  status: TMinerStatus;
  tagOverride?: string;
  t: (key: string) => string;
}

export const MinerStatus: React.FC<IMinerStatusProps> = ({ status, tagOverride, t }) => {
  switch (status) {
    case 'active':
      return (
        <S.Wrapper as={tagOverride}>
          <Active />
          <S.StatusText>{t('assets.active')}</S.StatusText>
        </S.Wrapper>
      );
    case 'pending':
      return (
        <S.Wrapper as={tagOverride}>
          <Waiting />
          <S.StatusText>{t('assets.waiting')}</S.StatusText>
        </S.Wrapper>
      );
    case 'disabled':
      return (
        <S.Wrapper as={tagOverride}>
          <Disabled />
          <S.TurnedOffText>{t('assets.turnedOff')}</S.TurnedOffText>
        </S.Wrapper>
      );
  }
};
