import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { ELanguages } from './components/logic/LanguageProvider/types/language-provider.interface';
import DE from './locales/de/translation.json';
import EN from './locales/en/translation.json';
import ES from './locales/es/translation.json';
import IT from './locales/it/translation.json';

void i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: EN,
    },
    de: {
      translation: DE,
    },
    it: {
      translation: IT,
    },
    es: {
      translation: ES,
    },
  } as const,
  lng: ELanguages[localStorage.getItem('language') as keyof typeof ELanguages] || ELanguages.ENG,
  fallbackLng: ELanguages.ENG,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
