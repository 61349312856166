import { DAY_14_IN_SECONDS } from 'constants/time/day-14-in-seconds';
import dayjs from 'dayjs';

export function isTimePassed(time: string | null): boolean {
  if (time) {
    const nowUnix = dayjs.utc().format();
    const timeDifferenceInSeconds = dayjs(nowUnix).diff(time, 'seconds');
    return timeDifferenceInSeconds > DAY_14_IN_SECONDS;
  }
  return true;
}
