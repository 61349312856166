import React, { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { FormApi } from 'final-form';
import { INewPasswordFormValues } from 'types/ui/NewPasswordForm/new-password-form-values.interface';

import { Input } from 'components/ui/Input';
import { removeSpacesAndEmojis } from 'utils/formatters/remove-spaces-and-emojis.util';
import { gtmClickSetNewPassword } from 'utils/gtmSender/gtmSender';
import { composeValidators } from 'utils/validators/compose-validators';
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from 'utils/validators/constants/validation.constants';
import { isNotTooLong } from 'utils/validators/is-not-too-long';
import { isNotTooShort } from 'utils/validators/is-not-too-short';
import { isRequired } from 'utils/validators/is-required';

import { INewPasswordFormProps } from './types/new-password-form-props.interface';

import * as S from './NewPasswordForm.styled';

export const NewPasswordForm: FC<INewPasswordFormProps> = ({
  handleFormSubmit,
  isSubmitting = false,
  hasBackendError = false,
  t,
}) => {
  const handlePasswordChange = (
    value: string,
    form: FormApi<INewPasswordFormValues, Partial<INewPasswordFormValues>>,
  ) => form.change('password', removeSpacesAndEmojis(value));

  return (
    <S.SetNewPasswordSection>
      <S.MainContentWrapper>
        <S.Title>{t('passwordReset.otpFormTitle')}</S.Title>

        <Form onSubmit={handleFormSubmit}>
          {({ handleSubmit, submitFailed, hasValidationErrors, form }) => (
            <S.Form onSubmit={handleSubmit} $isFailed={submitFailed || hasBackendError}>
              <Field
                name="password"
                validate={composeValidators([
                  isRequired,
                  isNotTooShort(MIN_PASSWORD_LENGTH),
                  isNotTooLong(MAX_PASSWORD_LENGTH),
                ])}
              >
                {({ input, meta }) => (
                  <S.InputLabel>
                    <S.LabelText>{t('passwordReset.newPasswordText')}</S.LabelText>
                    <Input
                      {...input}
                      type="password"
                      hasErrors={meta.error && submitFailed}
                      onChange={(evt) => handlePasswordChange(evt.currentTarget.value, form)}
                    />
                    {meta.error && submitFailed && <S.ErrorText>{meta.error}</S.ErrorText>}
                  </S.InputLabel>
                )}
              </Field>

              <S.SubmitButton
                type="submit"
                text="Set a new password"
                disabled={(submitFailed && hasValidationErrors) || isSubmitting}
                onClick={() => gtmClickSetNewPassword()}
              />
            </S.Form>
          )}
        </Form>
      </S.MainContentWrapper>

      <S.RequiredFieldsNote>{t('passwordReset.requiredFieldText')}</S.RequiredFieldsNote>
    </S.SetNewPasswordSection>
  );
};
