import { styled } from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const DiscountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  grid-area: discount;
  margin-top: 12px;
  font-family: ${FontFamily.Montserrat};
  font-style: normal;
  font-weight: 500;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
  }
`;

export const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SecondLine = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.span`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #646464;
`;

export const ValuesWrapper = styled.div`
  display: flex;
  gap: 2px;
  color: #272727;
`;

export const Value = styled.span`
  font-size: 14px;
  line-height: 18px;
`;
