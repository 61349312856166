import React from 'react';

import * as S from './ElectricityCashbackTerms.styled';

interface IProps {
  t: (key: string) => string;
}

export const ElectricityCashbackTerms: React.FC<IProps> = ({ t }) => {
  return (
    <S.CashbackTermsArticle>
      <S.CashbackTermsTitle>{t('terms.termsTitle')}</S.CashbackTermsTitle>
      <S.DescriptionParagraph>{t('terms.termsDescription')}</S.DescriptionParagraph>

      <S.TermsCategoriesList>
        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.promotionNameTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.promotionNameTerm')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.promotionTerritoryTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.promotionTerritoryTerm')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.termsDefinitionsTitle')}</S.CategoryTitle>
          <S.DefinitionsList>
            <div>
              <S.DefinitionTerm>{t('terms.promotionTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{t('terms.promotionDescription')}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('terms.cashbackTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{t('terms.cashbackDescription')}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('terms.companyTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{t('terms.companyDescription')}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('terms.participantTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>{t('terms.participantDescription')}</S.DefinitionDescription>
            </div>
            <div>
              <S.DefinitionTerm>{t('terms.promotionPeriodTerm')}</S.DefinitionTerm>
              <S.DefinitionDescription>
                {t('terms.promotionPeriodDescription')}
              </S.DefinitionDescription>
            </div>
          </S.DefinitionsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.generalTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.general1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.general2Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.conditionsTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.conditions1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.conditions2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.conditions3Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.participationTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.participation1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.participation2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.participation3Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.creditingTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.crediting1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.crediting2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.crediting3Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.crediting4Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.responsibilityTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.responsibility1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.responsibility2Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.confidentialityTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.confidentialityTerm')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>

        <S.TermsCategory>
          <S.CategoryTitle>{t('terms.otherTitle')}</S.CategoryTitle>
          <S.TermsList>
            <S.TermItem>
              <S.TermText>{t('terms.other1Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.other2Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.other3Term')}</S.TermText>
            </S.TermItem>
            <S.TermItem>
              <S.TermText>{t('terms.other4Term')}</S.TermText>
            </S.TermItem>
          </S.TermsList>
        </S.TermsCategory>
      </S.TermsCategoriesList>
    </S.CashbackTermsArticle>
  );
};
