import { createAsyncThunk } from '@reduxjs/toolkit';
import { requestActiveMiners } from 'store/api/cuverse-api/purchase/purchase.service';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { mapActiveMiners } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

export const fetchActiveMinersThunkAction = createAsyncThunk(
  'miners/fetchActiveMiners',
  async (): Promise<IMinerDataMapped[]> => {
    return await requestActiveMiners()
      .then((res) => mapActiveMiners(res))
      .catch((error) => {
        //TODO replace with correct message
        notifyError('Miners cannot be requested');
        throw error;
      });
  },
);
