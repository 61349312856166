import { ReactComponent as BasicVerifiedIcon } from 'assets/icons/verify-icons/basic-verification-icon.svg';
import { ReactComponent as FullVerifiedIcon } from 'assets/icons/verify-icons/full-verification-icon.svg';
import { ReactComponent as NotVerifiedIcon } from 'assets/icons/verify-icons/profile-status-icon.svg';

export const verificationStatuses = {
  notVerified: {
    statusText: 'Not verified',
    icon: NotVerifiedIcon,
  },
  basicVerification: {
    statusText: 'Basic verification',
    icon: BasicVerifiedIcon,
  },
  fullVerification: {
    statusText: 'Full verification',
    icon: FullVerifiedIcon,
  },
};
