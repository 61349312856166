import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { Button } from 'components/ui/Button';

export const QuantityContainer = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  font-family: ${FontFamily.Montserrat};

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${BreakPoint.TabletLow}) and (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const QuantityTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
  text-transform: capitalize;

  @media (max-width: ${BreakPoint.LaptopLow}) {
    font-size: 16px;
  }
`;

export const ButtonsCountWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 140px;
`;

export const AddToCartWrapper = styled.div`
  display: flex;
  align-self: flex-end;
`;

export const QuantityButton = styled(Button)`
  width: 100%;
  padding: 12px 16px;
  border-radius: 24px;
`;

export const Text = styled.div`
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
`;

export const Cost = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const Price = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
`;
