import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MinerOptions } from 'pages/PageMinerInfo/components/ui/MinerOptions';
import { WorkFlowInfo } from 'pages/PageMinerInfo/components/ui/WorkFlowInfo';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';
import { fetchChartThunkAction } from 'store/chart-reducer/chart.thunk-actions';
import { useAppDispatch } from 'store/store';

import { SPage } from 'components/styled/SPage';
import { SPageSection } from 'components/styled/SPageSection';

import { BackToMinerCardLink } from './components/ui/BackToMinerCardLink';
import { CartSection } from './components/ui/CartSection';
import { Chart } from './components/ui/Chart';
import { ContractInfo } from './components/ui/ContractInfo';
import { ProfitabilityInfo } from './components/ui/ProfitabilityInfo/ProfitabilityInfo';
import { ProfitSection } from './components/ui/ProfitSection';

import * as S from './PageMinerInfo.styled';

interface IMinerInfoProps {
  monthlyRevenue: number;
  price: number;
  quantity: number;
  availableMinersNumber: number;
  isActualSoldOut: boolean;
  energyEfficiency: string;
  annualRoi: number;
  monthlyIncome: number;
  monthlyElectricityFee: number;
  monthlyProfit: number;
  netProfitYear: number;
  dailyElectricityFee: number;
  estDailyOutput: number;
  btcUsdRate: number;
  minerData: IMinerDataMapped;
}

export const PageMinerInfo = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    monthlyRevenue,
    price,
    quantity,
    availableMinersNumber,
    isActualSoldOut,
    energyEfficiency,
    annualRoi,
    monthlyIncome,
    monthlyElectricityFee,
    monthlyProfit,
    dailyElectricityFee,
    estDailyOutput,
    btcUsdRate,
    minerData,
  } = location.state as IMinerInfoProps;
  const { hashrate, picture, model } = minerData;
  useEffect(() => {
    void dispatch(fetchChartThunkAction());
  }, [dispatch]);

  return (
    <SPage pageTittle={model} headerComponent={<BackToMinerCardLink t={t} />}>
      <SPageSection>
        <S.CommonWrapper>
          <MinerOptions
            hashRate={hashrate}
            energyEfficiency={energyEfficiency}
            annualRoi={annualRoi}
            monthlyRevenue={monthlyRevenue}
            picture={picture}
            t={t}
          />
          <CartSection
            monthlyRevenue={monthlyRevenue}
            price={price}
            quantity={quantity}
            availableMinersNumber={availableMinersNumber}
            isActualSoldOut={isActualSoldOut}
            minerData={minerData}
            monthlyElectricityFee={monthlyElectricityFee}
            t={t}
          />
        </S.CommonWrapper>

        <WorkFlowInfo t={t} />

        <ProfitabilityInfo
          monthlyProfit={monthlyProfit}
          monthlyElectricityFee={monthlyElectricityFee}
          monthlyIncome={monthlyIncome}
          annualRoi={annualRoi}
          t={t}
        />

        <S.CommonWrapper>
          <Chart t={t} btcUsdRate={btcUsdRate} />
          <ProfitSection
            electricityFees={dailyElectricityFee}
            estDailyOutput={estDailyOutput}
            btcUsdRate={btcUsdRate}
            t={t}
          />
        </S.CommonWrapper>

        <ContractInfo t={t} />
      </SPageSection>
    </SPage>
  );
};
