import { forwardRef } from 'react';

import { IButtonProps } from './Button.types';

import * as S from './Button.styled';

export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  ({ variant = 'primary', svgProps, isReversed = false, text = '', icon, ...props }, ref) => {
    const formattedSvgProps = Object.fromEntries(
      Object.entries(svgProps || {}).map(([key, value]) => ['$' + key, value]),
    );

    switch (variant) {
      case 'borderless':
        return (
          <S.BorderlessButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {icon && icon}
              </>
            ) : (
              <>
                {icon && icon}
                {text && text}
              </>
            )}
          </S.BorderlessButton>
        );
      case 'bordered':
        return (
          <S.BorderedButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {icon && icon}
              </>
            ) : (
              <>
                {icon && icon}
                {text && text}
              </>
            )}
          </S.BorderedButton>
        );
      case 'alter-primary':
        return (
          <S.AlterPrimaryButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {icon && icon}
              </>
            ) : (
              <>
                {icon && icon}
                {text && text}
              </>
            )}
          </S.AlterPrimaryButton>
        );
      case 'alter-bordered':
        return (
          <S.AlterBorderedButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {icon && icon}
              </>
            ) : (
              <>
                {icon && icon}
                {text && text}
              </>
            )}
          </S.AlterBorderedButton>
        );
      case 'secondary':
        return (
          <S.SecondaryButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {icon && icon}
              </>
            ) : (
              <>
                {icon && icon}
                {text && text}
              </>
            )}
          </S.SecondaryButton>
        );
      case 'primary-gray': {
        return (
          <S.PrimaryGrayButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {icon && icon}
              </>
            ) : (
              <>
                {icon && icon}
                {text && text}
              </>
            )}
          </S.PrimaryGrayButton>
        );
      }
      case 'primary-with-children':
        return (
          <S.PrimaryButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {props.children}
              </>
            ) : (
              <>
                {props.children}
                {text && text}
              </>
            )}
          </S.PrimaryButton>
        );
      default:
        return (
          <S.PrimaryButton {...props} ref={ref} {...formattedSvgProps}>
            {isReversed ? (
              <>
                {text && text}
                {icon && icon}
              </>
            ) : (
              <>
                {icon && icon}
                {text && text}
              </>
            )}
          </S.PrimaryButton>
        );
    }
  },
);
