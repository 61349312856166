import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  ICardPaymentMessageMapped,
  IOrdersIds,
  IPaymentResponseData,
  TAlphaPoMessage,
} from 'store/api/cuverse-api/payment/types/payment-response.interface';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchPaymentThunkAction } from './payment.thunk-actions';

export const enum EPaymentCategory {
  Electricity = 'electricity',
  Cart = 'cart',
}

export enum EPaymentMethod {
  BtcBalance = 'btc-balance',
  AlphaPo = 'alpha-po',
  Mercuryo = 'mercuryo',
}

export const enum EOperationType {
  PoolMiner = 'pool_miner',
  ElectricityFeePayment = 'electricity_fee_payment',
}

export interface IPaymentState {
  paymentStatusResult: string | null;
  paymentCategory: EPaymentCategory | null;
  ordersIds: IOrdersIds[] | null;
  cardPaymentLink: string | null;
  alphaPoPaymentLink: string | null;
  btcBalanceFetchStatus: TFetchStatus;
  alphaPoFetchStatus: TFetchStatus;
  mercuryoFetchStatus: TFetchStatus;
  error: string | null;
}

const initialState: IPaymentState = {
  paymentStatusResult: null,
  paymentCategory: null,
  ordersIds: null,
  cardPaymentLink: null,
  alphaPoPaymentLink: null,
  btcBalanceFetchStatus: 'initial',
  alphaPoFetchStatus: 'initial',
  mercuryoFetchStatus: 'initial',
  error: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentCategoryAction: (state, action: PayloadAction<EPaymentCategory>) => {
      state.paymentCategory = action.payload;
    },
    clearPaymentStateAction: (state) => ({
      ...initialState,
      paymentCategory: state.paymentCategory,
    }),
    resetPaymentLinks: (state) => {
      state.cardPaymentLink = null;
      state.alphaPoPaymentLink = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentThunkAction.pending, (state, action) => {
      const { paymentMethod } = action.meta.arg;
      if (paymentMethod === 'btc-balance') {
        state.btcBalanceFetchStatus = 'pending';
      } else if (paymentMethod === 'alpha-po') {
        state.alphaPoFetchStatus = 'pending';
      } else if (paymentMethod === 'mercuryo') {
        state.mercuryoFetchStatus = 'pending';
      }
      state.error = null;
    });
    builder.addCase(
      fetchPaymentThunkAction.fulfilled,
      (
        state,
        action: PayloadAction<
          IPaymentResponseData | TAlphaPoMessage | ICardPaymentMessageMapped
        > & { meta: { arg: { paymentMethod: EPaymentMethod } } },
      ) => {
        const { paymentMethod } = action.meta.arg;
        if (paymentMethod === 'btc-balance') {
          state.btcBalanceFetchStatus = 'fulfilled';
        } else if (paymentMethod === 'alpha-po') {
          state.alphaPoFetchStatus = 'fulfilled';
        } else if (paymentMethod === 'mercuryo') {
          state.mercuryoFetchStatus = 'fulfilled';
        }
        if (action.payload) {
          if (typeof action.payload === 'object' && 'status' in action.payload) {
            const { status, data } = action.payload;
            state.paymentStatusResult = status;
            state.ordersIds = data || null;
          } else if (typeof action.payload === 'object' && 'paymentLink' in action.payload) {
            state.cardPaymentLink = action.payload.paymentLink;
          } else if (typeof action.payload === 'string') {
            state.alphaPoPaymentLink = state.alphaPoPaymentLink ?? action.payload;
          }
        }
      },
    );
    builder.addCase(fetchPaymentThunkAction.rejected, (state, action) => {
      const { paymentMethod } = action.meta.arg;
      if (paymentMethod === 'btc-balance') {
        state.btcBalanceFetchStatus = 'rejected';
      } else if (paymentMethod === 'alpha-po') {
        state.alphaPoFetchStatus = 'rejected';
      } else if (paymentMethod === 'mercuryo') {
        state.mercuryoFetchStatus = 'rejected';
      }
      state.error = JSON.stringify(action.error);
    });
  },
});

export const { resetPaymentLinks } = paymentSlice.actions;
export const paymentReducer = paymentSlice.reducer;
export const { setPaymentCategoryAction, clearPaymentStateAction } = paymentSlice.actions;
