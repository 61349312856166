import { ButtonHTMLAttributes, DetailedHTMLProps, ReactElement } from 'react';

export type TButtonVariant =
  | 'primary'
  | 'primary-gray'
  | 'secondary'
  | 'borderless'
  | 'bordered'
  | 'alter-primary'
  | 'alter-bordered'
  | 'primary-with-children';

export const enum EButtonAnimations {
  rotate = 'rotate',
}

export interface IButtonSvgProps {
  svgFill?: string;
  svgStroke?: string;
  activeSvgFill?: string;
  activeSvgStroke?: string;
  disabledSvgFill?: string;
  hoverSvgFill?: string;
  hoverSvgStroke?: string;
  focusSvgFill?: string;
  focusSvgStroke?: string;
  withAnimation?: EButtonAnimations;
}

export interface IFormattedSvgProps {
  $svgFill?: string;
  $svgStroke?: string;
  $activeSvgFill?: string;
  $activeSvgStroke?: string;
  $disabledSvgFill?: string;
  $hoverSvgFill?: string;
  $hoverSvgStroke?: string;
  $focusSvgFill?: string;
  $focusSvgStroke?: string;
  $withAnimation?: EButtonAnimations;
}

export interface IButtonProps
  extends Partial<DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> {
  variant?: TButtonVariant;
  isReversed?: boolean;
  text?: string;
  icon?: ReactElement;
  svgProps?: IButtonSvgProps;
}
