import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const ExplainerArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 16px;
  }
`;

export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: stretch;
  gap: 12px;
  padding: 16px;
  border-radius: 16px;
  background-color: ${EColors.BlueGrayBackDark};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 110px;
`;

export const LeftColumn = styled(Column)`
  align-items: flex-end;
  text-align: right;
`;

export const RightColumn = styled(Column)`
  align-items: flex-start;
`;

export const UptimeText = styled.span`
  font-family: ${FontFamily.Montserrat};
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.2px;
`;

export const UptimeTextLarge = styled(UptimeText)`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const Tittle = styled(SText)`
  font-weight: 700;
  line-height: 20px;
`;

export const ExplainerParagraphsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 380px;
  max-height: 320px;
  padding: 12px;
  border-radius: 12px;
  background-color: #f4f4f7;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #c9c9c9 transparent;

  @media (max-width: ${BreakPoint.MobileTop}) {
    max-width: 100%;
    max-height: 388px;
  }
`;

export const ExplainerParagraph = styled.p`
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #272727;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 12px;
    line-height: 18px;
  }
`;
