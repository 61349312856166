import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultProfileIcon } from 'assets/icons/profile/default-profile-icon.svg';

import { IProfileProps } from './types/profile-props.interface';

import * as S from './Profile.styled';

export const Profile: React.FC<IProfileProps> = ({ variant }) => {
  const isMenuVariant = variant === 'menu';
  const { t } = useTranslation();

  return (
    <>
      <S.ProfileWrapper $isMenuVariant={isMenuVariant}>
        <S.AvatarWrapper>
          <DefaultProfileIcon />
        </S.AvatarWrapper>
        <S.ProfileText $isMenuVariant={isMenuVariant}>{t('profile.title')}</S.ProfileText>
      </S.ProfileWrapper>
    </>
  );
};
