import { AxiosResponse } from 'axios';
import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';

import {
  IPromocodeCheckRequestBody,
  IPromocodeRequestBody,
} from './types/promocode-request-body.interface';
import { IPromocodeCheckResponse, IPromocodeResponse } from './types/promocode-response.interface';
import { PromocodeRoute } from './promocode.route';

export const checkPromocodeAvailability = async (
  body: IPromocodeCheckRequestBody,
): Promise<IPromocodeCheckResponse> =>
  cuverseApi
    .post<
      IPromocodeResponse,
      AxiosResponse<IPromocodeCheckResponse>,
      IPromocodeCheckRequestBody
    >(PromocodeRoute.PromocodeCheck(), body)
    .then((res) => res?.data);

export const applyPromocode = async (body: IPromocodeRequestBody): Promise<IPromocodeResponse> =>
  cuverseApi
    .patch<
      IPromocodeResponse,
      AxiosResponse<IPromocodeResponse>,
      IPromocodeRequestBody
    >(PromocodeRoute.Promocode(), body)
    .then((res) => res?.data);
