import { styled } from 'styled-components';

import { SText } from 'components/styled/SText';

export const SwitcherLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  user-select: none;
`;

export const Tittle = styled(SText)`
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;
