import { FC } from 'react';
import { ReactComponent as CartIcon } from 'assets/icons/purchase-page-icons/shopping-cart-simple.svg';

import * as S from './CartTittle.styled';

interface ICartTittleProps {
  t: (key: string) => string;
}

export const CartTittle: FC<ICartTittleProps> = ({ t }) => {
  return (
    <S.CartTittleWrapper>
      <CartIcon />
      <S.CartTitle>{t('cart.cart')}</S.CartTitle>
    </S.CartTittleWrapper>
  );
};
