import { DAY_IN_SECONDS } from 'constants/time/day-in-seconds';
import { HOUR_IN_SECONDS } from 'constants/time/hour-in-seconds.const';
import { SECONDS_IN_MINUTE } from 'constants/time/seconds-in-minute.const';
import dayjs from 'dayjs';
import { i18n } from 'i18next';

export const getTimeLeft = (
  time: string,
  timeEnd: number,
  t: (key: string) => string,
  i18n: i18n,
): string => {
  const pluralRules = new Intl.PluralRules(i18n.language, { type: 'cardinal' });

  const now = dayjs();
  const toggleTime = dayjs(time);
  const nextAvailableToggleTime = toggleTime.add(timeEnd, 'seconds');
  const toggleTimeDiffInSeconds = nextAvailableToggleTime.diff(now, 'seconds');

  const days = Math.floor(toggleTimeDiffInSeconds / DAY_IN_SECONDS);
  const hours = Math.floor((toggleTimeDiffInSeconds % DAY_IN_SECONDS) / HOUR_IN_SECONDS);
  const minutes = Math.floor((toggleTimeDiffInSeconds % HOUR_IN_SECONDS) / SECONDS_IN_MINUTE);
  const seconds = Math.max(0, toggleTimeDiffInSeconds % SECONDS_IN_MINUTE);

  const getForms = (key: string, count: number): string => {
    const singular = t(`time.${key}`);
    const plural = t(`time.${key}s`);
    return pluralRules.select(count) === 'one' ? singular : plural;
  };

  let result = '';
  if (days > 0) {
    const dayForms = getForms('day', days);
    result = `${days} ${dayForms.toLocaleLowerCase(i18n.language)}`;
  } else if (hours > 0) {
    const hourForms = getForms('hour', hours);
    result = `${hours} ${hourForms.toLocaleLowerCase(i18n.language)}`;
  } else if (minutes > 0) {
    const minuteForms = getForms('minute', minutes);
    result = `${minutes} ${minuteForms.toLocaleLowerCase(i18n.language)}`;
  } else {
    const secondForms = getForms('second', seconds);
    result = `${seconds} ${secondForms.toLocaleLowerCase(i18n.language)}`;
  }

  return result;
};
