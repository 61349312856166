import { EOperationType, EPaymentCategory } from 'store/payment-reducer/payment.reducer';

export const getOperationTypeByCategory = (
  category: EPaymentCategory | null,
): EOperationType | null => {
  if (!category) return null;
  const operationTypeByCategory: Record<EPaymentCategory, EOperationType> = {
    [EPaymentCategory.Electricity]: EOperationType.ElectricityFeePayment,
    [EPaymentCategory.Cart]: EOperationType.PoolMiner,
  };
  return operationTypeByCategory[category];
};
