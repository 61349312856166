import React from 'react';
import { useTranslation } from 'react-i18next';

import { SPage } from 'components/styled/SPage';

import { HistorySection } from './components/logic/HistorySection';

export const PageTransactionsHistory: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SPage pageTittle={t('transactionHistory.title')}>
      <HistorySection t={t} />
    </SPage>
  );
};
