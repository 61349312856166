import React from 'react';
import { useTranslation } from 'react-i18next';

import { SPage } from 'components/styled/SPage';

import { AssetsSection } from './components/logic/AssetsSection';
import { LimitedUptimeHeader } from './components/logic/LimitedUptimeHeader/LimitedUptimeHeader';

export const PageAssets: React.FC = () => {
  const { t } = useTranslation();
  return (
    <SPage pageTittle={t('assets.title')} headerComponent={<LimitedUptimeHeader />}>
      <AssetsSection t={t} />
    </SPage>
  );
};
