import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinerLinkIcon } from 'assets/icons/miner-info/minerLinkIcon.svg';

import { AppRoute } from 'utils/route/app-route';

import * as S from './BackToMinerCard.styled';

interface IBackToMinerCardLinkProps {
  t: (key: string) => string;
}

export const BackToMinerCardLink: FC<IBackToMinerCardLinkProps> = ({ t }): React.ReactElement => {
  const navigate = useNavigate();
  return (
    <S.LinkButton
      text={'All miners'}
      variant="bordered"
      icon={<MinerLinkIcon />}
      onClick={() => navigate(AppRoute.Purchase())}
      svgProps={{
        hoverSvgFill: 'transparent',
      }}
    />
  );
};
