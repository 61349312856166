import { AxiosError } from 'axios';
import { IPromocodeError } from 'store/api/cuverse-api/promocode/types/coupon-error.interface';
import { IDataError } from 'store/api/cuverse-api/promocode/types/promocode-response.interface';

import { notifyError } from 'utils/notify/notify.utils';

export const errorsPresent = (
  error: AxiosError<IPromocodeError> | null,
  errors: IDataError | null,
): void => {
  if (errors) {
    Object.entries(errors).forEach(([key, value]) => notifyError(`${key}: ${value}`));
    throw error;
  }

  Object.entries(error?.response?.data.errors || {}).forEach(([key, value]) =>
    notifyError(`${key}: ${value}`),
  );
  throw error;
};
