import styled from 'styled-components';
import { EColors } from 'styles/style-variables/colors';

export const PromoCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .promo-input-wrapper {
    display: flex;
    width: 100%;
    min-height: max-content;
  }

  .promo-enter-button {
    justify-content: center;
    width: 100%;
    min-height: 32px;
    margin-bottom: 0;
    background-color: ${EColors.BlueGrayBack};
  }
`;
