import { styled } from 'styled-components';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const ContentPlaceholderWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Tittle = styled(SText)`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 1.3px;
  text-align: center;
  text-transform: uppercase;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;
`;

export const BuyMinerButton = styled(Button)`
  gap: 8px;
  width: max-content;
  padding: 12px 16px;
  border-radius: 24px;
`;
