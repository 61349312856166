import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MinerWhiteIcon } from 'assets/icons/button-icons/miner-white.svg';
import { MinersGroupByModel } from 'pages/PageAssets/components/ui/MobileGroupByModel';
import { TMinersGroupByModel } from 'pages/PageAssets/types/miners-group-by-model.type';

import { ContentPlaceholder } from 'components/ui/ContentPlaceholder';
import { AppRoute } from 'utils/route/app-route';

import * as S from './AssetsTableMobile.styled';

interface IAssetsTableMobileProps {
  minersByModel: TMinersGroupByModel[];
  btcToUsdRate: number;
  isPending: boolean;
  t: (key: string) => string;
}

export const AssetsTableMobile: React.FC<IAssetsTableMobileProps> = ({
  minersByModel,
  btcToUsdRate,
  isPending,
  t,
}) => {
  const navigate = useNavigate();

  if (minersByModel.length === 0 && !isPending) {
    return (
      <S.TableWrapper>
        <ContentPlaceholder
          buttonIcon={<MinerWhiteIcon />}
          buttonText={t('contentPlaceholder.buyMiner')}
          tittleText={t('contentPlaceholder.buyYourFirstMiner')}
          onButtonClick={() => navigate(AppRoute.Purchase())}
        />
      </S.TableWrapper>
    );
  }

  return (
    <S.TableWrapper>
      {minersByModel.map((minersGroup: TMinersGroupByModel) => {
        const minerModel = Object.keys(minersGroup)[0];
        return (
          <MinersGroupByModel
            key={minerModel}
            minersGroupByModel={minersGroup}
            btcToUsdRate={btcToUsdRate}
            t={t}
          />
        );
      })}
    </S.TableWrapper>
  );
};
