import type { FC } from 'react';

import { TButtonVariant } from 'components/ui/Button/Button.types';

import * as S from './ContentPlaceholder.styled';

interface IContentPlaceholderProps {
  tittleText?: string;
  buttonText?: string;
  buttonVariant?: TButtonVariant;
  buttonIcon?: React.ReactElement;
  onButtonClick?: () => void;
}

export const ContentPlaceholder: FC<IContentPlaceholderProps> = ({
  tittleText = 'tittle text',
  buttonText = 'button text',
  buttonVariant = 'primary',
  buttonIcon,
  onButtonClick,
}): JSX.Element => {
  return (
    <S.ContentPlaceholderWrapper>
      <S.Tittle>{tittleText}</S.Tittle>
      <S.BuyMinerButton
        text={buttonText}
        variant={buttonVariant}
        icon={buttonIcon}
        onClick={onButtonClick}
      />
    </S.ContentPlaceholderWrapper>
  );
};
