import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

export const ContentHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 104px;
  padding: 32px 24px;
  background-color: #0e46c0;

  @media (max-width: ${BreakPoint.TabletTop}) {
    flex-direction: column;
    gap: 16px;
    min-height: 88px;
    padding: 24px 16px;
  }
`;

export const Title = styled.h1`
  font-family: ${FontFamily.Montserrat};
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  color: #fff;
`;

export const ContentSectionsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  padding: 24px 24px 64px;

  @media (max-width: ${BreakPoint.LaptopTop}) {
    padding: 32px 24px 64px;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 32px 16px 64px;
  }
`;
