import { SHistoryLink } from 'pages/PageDashboard/components/styled';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';
import { FontFamily } from 'styles/style-variables/font-family';

import { SText } from 'components/styled/SText';

export const HistoryArticle = styled.article<{ $isEmpty: boolean }>`
  display: ${({ $isEmpty }) => ($isEmpty ? 'flex' : 'grid')};
  flex: 1;
  grid-template-areas:
    'title link'
    'table table';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  padding: 24px;
  border-radius: 24px;
  background-color: ${EColors.White};
  column-gap: 20px;
  row-gap: 24px;

  @media (max-width: ${BreakPoint.TabletTop}) {
    padding: 16px;
    row-gap: 16px;
  }

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-areas:
      'title'
      'link'
      'table';
    grid-template-columns: 100%;
    grid-template-rows: auto auto 1fr;
  }
`;

export const Title = styled(SText)`
  display: flex;
  align-items: center;
  gap: 4px;
  grid-area: title;
  font-family: ${FontFamily.Montserrat};
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  color: ${EColors.Gray1Text};

  & svg {
    flex-shrink: 0;
  }

  @media (max-width: ${BreakPoint.TabletTop}) {
    font-size: 18px;
    line-height: 24px;

    & svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const HistoryLink = styled(SHistoryLink)`
  justify-self: end;

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-self: center;
    width: 100%;
  }
`;

export const TransactionsList = styled.dl`
  display: flex;
  flex-direction: column;
  gap: 16px;
  grid-area: table;

  @media (max-width: ${BreakPoint.MobileTop}) {
    gap: 24px;
  }
`;

export const ListRow = styled.div`
  display: grid;
  align-items: start;
  grid-template-areas: 'type date message';
  grid-template-columns: 132px 160px auto;
  column-gap: 12px;
  row-gap: 16px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    grid-template-areas:
      'date type'
      'message message';
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    row-gap: 8px;
  }
`;

export const TransactionType = styled.dt`
  grid-area: type;
`;

export const TransactionDate = styled.dd`
  grid-area: date;
  font-family: ${FontFamily.Montserrat};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${EColors.Gray2};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    justify-self: start;
    align-self: center;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.3px;
  }
`;

export const TransactionMessage = styled.dd`
  grid-area: message;
  font-family: ${FontFamily.Montserrat};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  white-space: wrap;
  color: ${EColors.Gray1Text};
  font-variant-numeric: lining-nums tabular-nums;

  @media (max-width: ${BreakPoint.MobileTop}) {
    font-size: 11px;
    line-height: 16px;
  }
`;
