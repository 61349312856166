import React from 'react';
import { VerificationInfo } from 'pages/PageVerification/components/logic/VerificationInfo';

import * as S from './VerificationPanel.styled';

interface IVerificationPanelDescription {
  text: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  description: string;
  buttonText: string;
}

interface IVerificationPanelProps {
  label: string;
  hasLimitTag?: boolean;
  data: IVerificationPanelDescription[];
  isWithdrawalView?: boolean;
  t: (key: string) => string;
}

export const VerificationPanel: React.FC<IVerificationPanelProps> = ({
  label,
  hasLimitTag,
  data,
  isWithdrawalView,
  t,
}) => {
  return (
    <S.VerificationPanel isWithdrawalView={isWithdrawalView}>
      <S.WithdrawalLimit isWithdrawalView={isWithdrawalView}>
        {label}
        {hasLimitTag && (
          <S.LimitPanel>
            <S.LimitTitle>{t('profile.currentWithdrawalsLimit')}</S.LimitTitle>
            <S.LimitValue>{t('kyc.withdrawalValue')}</S.LimitValue>
          </S.LimitPanel>
        )}
      </S.WithdrawalLimit>
      <VerificationInfo data={data} isWithdrawalView={isWithdrawalView} />
    </S.VerificationPanel>
  );
};
