import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ListIcon } from 'assets/icons/button-icons/list.svg';
import { EPaymentCategory } from 'store/payment-reducer/payment.reducer';

import { AppRoute } from 'utils/route/app-route';

import * as S from './PaymentOptionsHeader.styled';

interface IPaymentOptionsHeaderProps {
  paymentCategory: EPaymentCategory | null;
}

export const PaymentOptionsHeader: React.FC<IPaymentOptionsHeaderProps> = ({ paymentCategory }) => {
  const navigate = useNavigate();

  if (!paymentCategory || paymentCategory === EPaymentCategory.Electricity) {
    return null;
  }

  const handleBackClick = () => {
    navigate(AppRoute.PurchaseCheckout());
  };

  return (
    <>
      <S.BackButton
        variant="bordered"
        text="Back to invoice"
        icon={<ListIcon />}
        onClick={handleBackClick}
      />
    </>
  );
};
