import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { selectChart } from 'store/chart-reducer/chart-selectors';
import { FontFamily } from 'styles/style-variables/font-family';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './Charts.styled';

interface IChart {
  btcUsdRate: number;
  t: (key: string) => string;
}

export const Chart: FC<IChart> = ({ btcUsdRate, t }): React.ReactElement => {
  const chartData = useSelector(selectChart);

  const result = useMemo(() => {
    if (chartData) {
      return Object.entries(chartData).map(([date, value]) => ({
        month: dayjs(date).format('DD MMM'),
        income: value.toFixed(8),
      }));
    }
    return [];
  }, [chartData]);
  const totalIncomeInUsd = `$${formatUsdOutput(
    Object.values(chartData || []).reduce((cur, acc) => cur + acc, 0) * btcUsdRate,
  )}`;

  return (
    <S.Charts>
      <S.ChartsHeader>
        <S.ChartTitle>{t('minerInfo.dailyIncomePerMiner')}</S.ChartTitle>
        <S.ChartsTotal>
          <S.ChartLabel>{t('minerInfo.totalFor')}</S.ChartLabel>
          <S.ChartLabel>{totalIncomeInUsd}</S.ChartLabel>
        </S.ChartsTotal>
      </S.ChartsHeader>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={600}
          height={400}
          data={result}
          margin={{ top: 10, right: 24, left: -24, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4da5f2" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ffffff" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="month"
            stroke="#307bff"
            fontFamily={FontFamily.Montserrat}
            fontSize={12}
            interval={48}
          />
          <YAxis stroke="#616161" fontFamily={FontFamily.Montserrat} fontSize={10} />
          <CartesianGrid strokeDasharray="3 3" fill="#ffffff" opacity={1} stroke="#b4b4b4" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="income"
            stroke="#307bff"
            strokeWidth={3}
            fillOpacity={1}
            fill="url(#colorPv)"
            legendType={'circle'}
          />
          <Line
            type="linear"
            dataKey="price"
            stroke="#5015e0"
            strokeWidth={6}
            activeDot={{ r: 8 }}
            dot={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </S.Charts>
  );
};
