import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useUptime } from 'hooks/useUptime';
import { ElectricityArticle } from 'pages/PageDashboard/components/ui/ElectricityArticle';
import { MiningArticle } from 'pages/PageDashboard/components/ui/MiningArticle';
import { WalletArticle } from 'pages/PageDashboard/components/ui/WalletArticle';
import {
  selectDashboardElectricity,
  selectDashboardHashRate,
  selectDashboardTotalMining,
  selectDashboardWallet,
} from 'store/dashboard-reducer/dashboard.selectors';
import {
  requestDashboardElectricity,
  requestDashboardMiningHashRate,
  requestDashboardMiningTotal,
  requestDashboardWalletBalance,
} from 'store/dashboard-reducer/dashboard.thunk-actions';
import {
  openChangeWalletPopupAction,
  openDepositBtcPopupAction,
  openDepositElectricityPopupAction,
  openLimitedUptimeExplainerPopupAction,
  openWithdrawBtcPopupAction,
} from 'store/modals-reducer/modals.reducer';
import { setChangeWalletAddressStepAction } from 'store/profile-reducer/profile.reducer';
import { selectWallets } from 'store/profile-reducer/profile.selectors';
import {
  requestProfileDataThunkAction,
  requestWalletsDataThunkAction,
} from 'store/profile-reducer/profile.thunk-actions';
import { useAppDispatch } from 'store/store';
import { requestUptimeThunkAction } from 'store/uptime-reducer/uptime.thunk-action';
import { setWithdrawStepAction } from 'store/withdraw-reducer/withdraw.reducer';
import { EColors } from 'styles/style-variables/colors';

import { SPageSection } from 'components/styled/SPageSection';
import { SVisuallyHidden } from 'components/styled/SVisuallyHidden/SVisuallyHidden';
import { formatBtcOutput } from 'utils/formatters/format-btc-output.util';
import { formatToNumberWithSpaces } from 'utils/formatters/format-to-number-with-spaces.util';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';
import { gtmClickDeposit, gtmClickWithdraw } from 'utils/gtmSender/gtmSender';

import * as S from './InfoSection.styled';

export const InfoSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { amount, usdAmount } = useSelector(selectDashboardWallet);
  const { balance, availableDays, dailyTotalFee, totalEfficiency } = useSelector(
    selectDashboardElectricity,
  );
  const {
    active: { hashrate: activeHashrate, number: activeNumber },
    pending: { hashrate: pendingHashrate, number: pendingNumber },
  } = useSelector(selectDashboardHashRate);
  const {
    BTC: { yesterday, historical },
  } = useSelector(selectDashboardTotalMining);
  const { btc: btcWalletAddress } = useSelector(selectWallets);

  const btcValue = `${formatBtcOutput(amount)} BTC`;
  const usdValue = `≈ $${formatUsdOutput(usdAmount)}`;
  const paymentBalanceUsd = `$${formatUsdOutput(balance)}`;
  const dailyPaymentUsd = `$${formatUsdOutput(dailyTotalFee)} ${t('wallet.per')} ${formatToNumberWithSpaces(totalEfficiency)} kW`;
  const paidDays = `${formatToNumberWithSpaces(availableDays)} ${t('wallet.days')}`;
  const hashrate = `${formatToNumberWithSpaces(activeHashrate, 2)} TH/s`;
  const activeMinersCount = formatToNumberWithSpaces(activeNumber);
  const launchPendingHashrate = `${formatToNumberWithSpaces(pendingHashrate, 2)} TH/s`;
  const launchPendingMinersCount = formatToNumberWithSpaces(pendingNumber);
  const minedYesterdayBtc = `${formatBtcOutput(yesterday)} BTC`;
  const totalMinedBtc = `${formatBtcOutput(historical)} BTC`;
  const hasBtcAddress = Boolean(btcWalletAddress);

  const handleDepositButtonClick = () => {
    dispatch(openDepositBtcPopupAction());
    gtmClickDeposit();
  };
  const handleWithdrawButtonClick = () => {
    hasBtcAddress
      ? dispatch(openWithdrawBtcPopupAction())
      : dispatch(openChangeWalletPopupAction());
    dispatch(setWithdrawStepAction('initial'));
    dispatch(setChangeWalletAddressStepAction('initial'));
    gtmClickWithdraw();
  };
  const handleReplenishButtonClick = () => dispatch(openDepositElectricityPopupAction());
  const openUptimeLimitPopup = () => dispatch(openLimitedUptimeExplainerPopupAction());
  useEffect(() => {
    void dispatch(requestDashboardWalletBalance());
    void dispatch(requestDashboardElectricity());
    void dispatch(requestDashboardMiningHashRate());
    void dispatch(requestDashboardMiningTotal());
    void dispatch(requestProfileDataThunkAction());
    void dispatch(requestWalletsDataThunkAction());
    void dispatch(requestUptimeThunkAction());
  }, [dispatch]);

  const { uptime } = useUptime();

  return (
    <SPageSection tittleCeo={t('info.infoSectionSeoTitle')}>
      <S.InfoCard>
        <WalletArticle
          btcValue={btcValue}
          usdValue={usdValue}
          handleDepositClick={handleDepositButtonClick}
          handleWithdrawClick={handleWithdrawButtonClick}
          t={t}
        />
        <MiningArticle
          hashrate={hashrate}
          activeMinersCount={activeMinersCount}
          launchPendingHashrate={launchPendingHashrate}
          launchPendingMinersCount={launchPendingMinersCount}
          minedYesterdayBtc={minedYesterdayBtc}
          totalMinedBtc={totalMinedBtc}
          t={t}
          isEnabled={uptime}
          openLimitedUptimePopup={openUptimeLimitPopup}
        />
      </S.InfoCard>
      <S.ElectricityCard>
        <ElectricityArticle
          paymentBalanceUsd={paymentBalanceUsd}
          paidDays={paidDays}
          dailyPaymentUsd={dailyPaymentUsd}
          handleReplenishClick={handleReplenishButtonClick}
          t={t}
        />
      </S.ElectricityCard>
    </SPageSection>
  );
};
