import { FC } from 'react';
import { EColors } from 'styles/style-variables/colors';

import { IPropsSwitcherRequired } from 'components/ui/Switcher/types/switcher-props';

import * as S from './SwitcherWithInput.styled';

export const SwitchWithInput: FC<IPropsSwitcherRequired> = ({
  checked,
  onChange,
  width,
  height,
  circle,
  margin,
  disabledBackground,
  background,
  disabled,
  icon,
}) => {
  return (
    <>
      <S.SwitcherHiddenInput
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        $switcherCircleSize={circle}
      />
      <S.Switch
        $checked={checked}
        $switcherWidth={width}
        $switcherHeight={height}
        $switcherCircleSize={circle}
        $switcherDisabledBackground={disabledBackground}
        $switcherBackground={disabled ? EColors.Gray3 : background}
        $circleMargin={margin}
      >
        {icon}
      </S.Switch>
    </>
  );
};
