import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { FontFamily } from 'styles/style-variables/font-family';

import { Select } from 'components/ui/Select';

export const ChartsInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  font-family: ${FontFamily.Montserrat};
`;

export const HeadCell = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
  color: #646464;
  justify-self: flex-start;
`;

export const Th = styled.th`
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  text-transform: uppercase;
  color: #646464;
`;

export const ProfitTitle = styled.h2`
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px;
`;

export const ProfitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const BtcPricer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const TableWrapper = styled.div`
  font-family: ${FontFamily.Montserrat};
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 16px;
`;

export const TableHead = styled.thead`
  border-collapse: separate;
`;

export const TableTrHr = styled.tr`
  margin-bottom: 16px;
`;

export const TableTr = styled.tr`
  width: 100%;
`;

export const Text = styled.td`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const BtcValue = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #646464;
`;

export const Income = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
`;

export const Hr = styled.hr`
  width: 100%;
  opacity: 0.3;
  color: #a2a2a2;
`;

export const ProfitSelect = styled(Select)`
  width: 100px;

  & > button {
    min-height: 32px;
    padding: 7px 31px 7px 11px;

    & span {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (max-width: ${BreakPoint.TabletTop}) and (min-width: ${BreakPoint.TabletLow}) {
    width: 120px;
  }
`;
