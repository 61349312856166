import React from 'react';
import { ReactComponent as SelectMinerIcon } from 'assets/icons/purchase-page-icons/miner-2.svg';
import { MinerCard } from 'pages/PagePurchase/components/logic/MinerCard';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { SPageSection } from 'components/styled/SPageSection';

import * as S from './MinersBuyingSection.styled';

interface MinersBuyingSectionProps {
  minersData: IMinerDataMapped[];
  t: (key: string) => string;
}

export const MinersBuyingSection: React.FC<MinersBuyingSectionProps> = ({
  minersData,

  t,
}) => {
  return (
    <SPageSection tittleCeo="buy a miner">
      <S.TitleWrapper>
        <SelectMinerIcon />
        <S.MinersBlockTitle>{t('purchase.selectMinerTitle')}</S.MinersBlockTitle>
      </S.TitleWrapper>
      <S.MinerList as="ul">
        {minersData.map((miner) => {
          return <MinerCard key={miner.id} minerData={miner} t={t} />;
        })}
      </S.MinerList>
    </SPageSection>
  );
};
