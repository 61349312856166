import { cuverseApi } from 'store/api/cuverse-api/cuverse.api';
import { PaymentRoute } from 'store/api/cuverse-api/payment/payment.route';

import { IElectricityPaymentRequest, IReplenishRequest } from './types/replenish-request.interface';
import {
  IElectricityPaymentResponse,
  IReplenishResponse,
} from './types/replenish-response.interface';
import { ReplenishRoute } from './replenish.route';

export const requestElectricityReplenish = async (
  body: IReplenishRequest,
): Promise<IReplenishResponse> =>
  cuverseApi.post(ReplenishRoute.ElectricityReplenish(), body).then((res) => {
    return res?.data;
  });

export const requestElectricityPayment = async (
  body: IElectricityPaymentRequest,
): Promise<IElectricityPaymentResponse> =>
  cuverseApi.post(PaymentRoute.Payment(), body).then((res) => {
    return res?.data;
  });
