import { EPromoCodeStatus } from 'store/promocode-reducer/constants/promocode-statuses';
import styled from 'styled-components';
import { BreakPoint } from 'styles/style-variables/breakpoint';
import { EColors } from 'styles/style-variables/colors';

import { SText } from 'components/styled/SText';
import { Button } from 'components/ui/Button';

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DepositElectricityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 24px 24px;

  @media (max-width: ${BreakPoint.MobileTop}) {
    padding: 0 16px;
  }
`;

export const SuggestedAmountsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AnotherAmountBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SuggestedAmountTitle = styled(SText)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const FormInputsWrapper = styled.div<{ $submitFailed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $submitFailed }) => ($submitFailed ? '8px' : '24px')};
`;

export const LabelText = styled(SText)`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export const SwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 8px;
`;

export const DepositButtonWrapper = styled.div<{ $promoStatus: EPromoCodeStatus }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DepositButton = styled(Button)`
  padding: 12px 24px;
  border-radius: 24px;
`;
