import { useTranslation } from 'react-i18next';
import { ReactComponent as Check } from 'assets/icons/purchase-page-icons/check.svg';
import { ReactComponent as CloseButton } from 'assets/icons/purchase-page-icons/close-button-red.svg';
import { EPromoCodeStatus } from 'pages/PagePurchase/constants/promo-code-status.const';
import { IPromoStatusAttributes } from 'pages/PagePurchase/types/promo-status-attributes.interface';

import { SSpinnerBlue } from 'components/styled/SSpinnerBlue';
import { Button } from 'components/ui/Button';

export const usePromoStatusAttributes = (
  promoStatus: EPromoCodeStatus,
  handleCloseButtonClick?: () => void,
): IPromoStatusAttributes => {
  const { t } = useTranslation();
  let leftIcon = null;
  let text = '';
  let closeButton = null;

  switch (promoStatus) {
    case EPromoCodeStatus.Checking:
      leftIcon = <SSpinnerBlue />;
      text = t('purchase.checkingCode');
      closeButton = null;
      break;
    case EPromoCodeStatus.Accepted:
      leftIcon = <Check />;
      text = t('purchase.promoCodeAccepted');
      closeButton = null;
      break;
    case EPromoCodeStatus.Rejected:
      leftIcon = null;
      text = t('purchase.wrongPromoCode');
      closeButton = (
        <Button variant="borderless" icon={<CloseButton />} onClick={handleCloseButtonClick} />
      );
  }

  return { leftIcon, text, closeButton };
};
