import React from 'react';
import { ReactComponent as Plus } from 'assets/icons/purchase-page-icons/plus-white.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/purchase-page-icons/warning.svg';

import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import * as S from './InvoiceStatus.styled';

interface IInvoiceStatusProps {
  launchDate?: string;
  paymentBalanceUsd: number;
  handleReplenishClick: () => void;
  electricityFee: number;
  t: (key: string) => string;
}

export const InvoiceStatus: React.FC<IInvoiceStatusProps> = ({
  launchDate,
  paymentBalanceUsd,
  handleReplenishClick,
  electricityFee,
  t,
}) => {
  return (
    <S.InvoiceStatus>
      <S.Title>{t('purchase.invoicePaidTitle')}</S.Title>
      <S.LaunchNotion>
        {t('purchase.minerDateStartText')} <S.LaunchDate>{launchDate}</S.LaunchDate>
      </S.LaunchNotion>
      <S.WarningBlock>
        <WarningIcon />
        <S.WarningText>
          {t('purchase.electricityFeeNotificationText')}{' '}
          <S.WarningSpan>(${formatUsdOutput(electricityFee)})</S.WarningSpan>
        </S.WarningText>
      </S.WarningBlock>
      <S.ElectricityBalanceBlock as="dl">
        <S.BalanceTitle as="dt">{t('purchase.electricityBalanceTitle')}</S.BalanceTitle>
        <S.BalanceValue as="dd">{`$${formatUsdOutput(paymentBalanceUsd)}`}</S.BalanceValue>
        <S.ReplenishButton
          text={t('deposit.deposit')}
          icon={<Plus />}
          onClick={handleReplenishClick}
        />
      </S.ElectricityBalanceBlock>
    </S.InvoiceStatus>
  );
};
