import { createSlice } from '@reduxjs/toolkit';
import { IChartResponseData } from 'store/api/cuverse-api/chart/types/chart-response';
import { TFetchStatus } from 'types/api/fetch-status.type';

import { fetchChartThunkAction } from './chart.thunk-actions';

export interface IChartState {
  chart: IChartResponseData | null;
  chartStatus: TFetchStatus;
  cartError: string | null;
}

export const initialState: IChartState = {
  chart: null,
  chartStatus: 'initial',
  cartError: null,
};

const chartSlice = createSlice({
  name: 'chart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChartThunkAction.pending, (state) => {
        state.chartStatus = 'pending';
      })
      .addCase(fetchChartThunkAction.fulfilled, (state, action) => {
        state.chartStatus = 'fulfilled';
        state.chart = action.payload;
      })
      .addCase(fetchChartThunkAction.rejected, (state, error) => {
        state.chartStatus = 'rejected';
        state.chart = { '': 0 };
      });
  },
});

export const chartReducer = chartSlice.reducer;
