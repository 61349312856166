import { useNavigate } from 'react-router-dom';
import {
  clearPaymentStateAction,
  EOperationType,
  EPaymentMethod,
} from 'store/payment-reducer/payment.reducer';
import { fetchPaymentThunkAction } from 'store/payment-reducer/payment.thunk-actions';
import { useAppDispatch } from 'store/store';

import { AppRoute } from 'utils/route/app-route';

interface IUsePaymentActionReturn {
  payment: (
    paymentId: number,
    paymentMethod: EPaymentMethod,
    operationType: EOperationType,
    route?: string,
    replace?: boolean,
  ) => void;
}

export const usePaymentAction = (): IUsePaymentActionReturn => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const payment = async (
    paymentId: number,
    paymentMethod: EPaymentMethod,
    operationType: EOperationType,
    route?: string,
    replace?: boolean,
  ) => {
    try {
      const response = await dispatch(
        fetchPaymentThunkAction({ id: paymentId, paymentMethod, operationType }),
      ).unwrap();

      if (response && typeof response === 'object' && 'data' in response) {
        if (operationType === EOperationType.PoolMiner) {
          return route && navigate(AppRoute.PurchaseSuccess(), { replace });
        }

        return route && navigate(route, { replace });
      }
    } catch (error) {
      /* empty */
    } finally {
      dispatch(clearPaymentStateAction());
    }
  };

  return { payment };
};
