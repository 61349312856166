import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { FallbackReplenishMessages } from 'constants/notification-messages/fallback-replenish-messages';
import { requestElectricityReplenish } from 'store/api/cuverse-api/replenish/replenish.service';
import { IReplenishError } from 'store/api/cuverse-api/replenish/types/replenish-error.interface';
import { IReplenishRequest } from 'store/api/cuverse-api/replenish/types/replenish-request.interface';
import { IReplenishResponseDataMapped } from 'store/api/cuverse-api/replenish/types/replenish-response.interface';
import { closePopupAction } from 'store/modals-reducer/modals.reducer';
import { clearPromoCheckState } from 'store/promocode-reducer/promocode.reducer';
import { AppDispatch } from 'store/store';

import { mapReplenishData } from 'utils/mapper/mapper';
import { notifyError } from 'utils/notify/notify.utils';

export const fetchReplenishThunkAction = createAsyncThunk<
  IReplenishResponseDataMapped,
  IReplenishRequest,
  { dispatch: AppDispatch }
>('replenish/fetchAndPayment', async ({ amount, coupon_id }, { dispatch }) => {
  return requestElectricityReplenish({ amount, coupon_id })
    .then((res) => {
      const mappedRes = mapReplenishData(res.data);
      void dispatch(clearPromoCheckState());
      void dispatch(closePopupAction());
      return mappedRes;
    })

    .catch((error: AxiosError<IReplenishError>) => {
      if (error.response?.data.message) {
        notifyError(error.response.data.message);
        throw error;
      }
      if (error.response?.data.errors?.amount) {
        notifyError(error.response.data.errors.amount[0]);
        throw error;
      }
      notifyError(FallbackReplenishMessages.CannotReplenish);
      throw error;
    });
});
