import styled from 'styled-components';
import { TransitionTime } from 'styles/style-variables/transition-time';

import { IPropsStyledSwitcher } from 'components/ui/Switcher/types/switcher-props';

export const Switch = styled.div<IPropsStyledSwitcher>`
  --sw: ${({ $switcherWidth }) => $switcherWidth};
  --sh: ${({ $switcherHeight }) => $switcherHeight};
  --cm: ${({ $circleMargin }) => $circleMargin};
  --ss: ${({ $switcherCircleSize }) => $switcherCircleSize};
  --sb: ${({ $switcherBackground }) => $switcherBackground};
  --sdb: ${({ $switcherDisabledBackground }) => $switcherDisabledBackground};
  --ml: var(--cm);
  --mr: calc(0px - var(--cm));
  --br: var(--sw);

  position: relative;
  width: var(--sw);
  height: var(--sh);
  border-radius: var(--br);
  background-color: var(--sb);

  & svg {
    position: absolute;
    top: 50%;
    right: ${({ $checked }) => ($checked ? 'auto' : 'var(--ml)')};
    left: ${({ $checked }) => ($checked ? 'var(--ml)' : 'auto')};
    z-index: 1;
    width: calc(var(--ss));
    height: calc(var(--ss));
    transition: right left ${TransitionTime.Active};
    transform: translate(0, -50%);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    width: var(--ss);
    height: var(--ss);
    margin: ${({ $checked }) => ($checked ? '0 var(--mr)' : '0 var(--ml)')};
    border-radius: 50%;
    background-color: white;
    transition: ${TransitionTime.Active} all;
    transform: translate(0, -50%);
  }
`;

export const SwitcherHiddenInput = styled.input<{
  $switcherCircleSize: string;
}>`
  display: none;

  &:disabled + ${Switch} {
    background-color: var(--sdb);
    opacity: 0.3;
  }

  &:checked + ${Switch} {

    &::before {
      left: calc(100% - var(--ss));
    }
  }
`;
