import React, { useEffect, useRef } from 'react';
import { ReactComponent as ReceiptIcon } from 'assets/icons/purchase-page-icons/receipt.svg';
import { ReactComponent as SpinnerSmallIcon } from 'assets/icons/spinner/spinner-white-small.svg';
import { ICartItemMapped } from 'store/api/cuverse-api/cart/types/cart-response.interface';
import { IMinerDataMapped } from 'store/api/cuverse-api/purchase/types/purchase-active-miners.interface';

import { CartTooltip } from 'components/logic/Cart/components/logic/CartTooltip/CartTooltip';
import { EButtonAnimations } from 'components/ui/Button/Button.types';
import { formatUsdOutput } from 'utils/formatters/format-usd-output.util';

import { DEBOUNCE_DELAY } from './constants/debounce-delay';

import * as S from './CartContent.styled';

interface ICartContentProps {
  cartId: number;
  handleCheckoutClick: () => void;
  cartList: ICartItemMapped[];
  minersData: IMinerDataMapped[];
  totalCartSum: number;
  isCheckoutPending: boolean;
  t: (key: string) => string;
}

export const CartContent: React.FC<ICartContentProps> = ({
  cartId,
  handleCheckoutClick,
  cartList,
  minersData,
  totalCartSum,
  isCheckoutPending,
  t,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const cartItemsAllQuantity = cartList.reduce((acc, item) => acc + item.quantity, 0);

  const handleTooltipMouseOver = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsTooltipOpen(true);
  };

  const handleTooltipMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setIsTooltipOpen(false);
    }, DEBOUNCE_DELAY);
  };

  return (
    <S.CartContentWrapper>
      <CartTooltip
        cartList={cartList}
        minersData={minersData}
        isTooltipOpen={isTooltipOpen}
        cartId={cartId}
        handleTooltipMouseLeave={handleTooltipMouseLeave}
        handleTooltipMouseOver={handleTooltipMouseOver}
        t={t}
      />
      <S.CartContent>
        <S.CartTooltipText
          onMouseLeave={handleTooltipMouseLeave}
          onMouseOver={handleTooltipMouseOver}
        >
          {cartItemsAllQuantity} {cartItemsAllQuantity > 1 ? t('cart.miners') : t('cart.miner')}
        </S.CartTooltipText>
        <S.CartTotalValue>${formatUsdOutput(totalCartSum)}</S.CartTotalValue>
        {isCheckoutPending ? (
          <S.CartCheckoutButton
            variant="primary"
            text="Checkout"
            icon={<SpinnerSmallIcon />}
            onClick={handleCheckoutClick}
            svgProps={{
              withAnimation: EButtonAnimations.rotate,
            }}
          />
        ) : (
          <S.CartCheckoutButton
            variant="primary"
            text="Checkout"
            icon={<ReceiptIcon />}
            onClick={handleCheckoutClick}
            svgProps={{
              svgFill: 'transparent',
            }}
          />
        )}
      </S.CartContent>
    </S.CartContentWrapper>
  );
};
