import { FC } from 'react';

import { SwitchWithInput } from 'components/ui/Switcher/components/ui/SwitcherWithInput/SwitchWithInput';
import { IPropsSwitcherRequired } from 'components/ui/Switcher/types/switcher-props';

import * as S from './SwitcherWrapper.styled';

export const SwitcherWrapper: FC<{
  leftTittle?: string | React.ReactElement;
  rightTittle?: string | React.ReactElement;
  props: IPropsSwitcherRequired;
  icon?: React.ReactElement;
}> = ({ leftTittle, rightTittle, props, icon }) => {
  return (
    <S.SwitcherLabel>
      {leftTittle && <S.Tittle>{leftTittle}</S.Tittle>}
      <SwitchWithInput {...props} icon={icon} />
      {rightTittle && <S.Tittle>{rightTittle}</S.Tittle>}
    </S.SwitcherLabel>
  );
};
