import React from 'react';
import { PaymentOption } from 'pages/PagePayment/components/ui/PaymentOption';
import { EPaymentMethod } from 'store/payment-reducer/payment.reducer';

import { SPageSection } from 'components/styled/SPageSection';

import * as S from './PaymentOptionsScreen.styled';

interface PaymentOptionsScreenProps {
  handlePaymentButtonClick: (paymentMethod: EPaymentMethod) => void;
  balance: number;
  amount: number;
  t: (key: string) => string;
}

export const PaymentOptionsScreen: React.FC<PaymentOptionsScreenProps> = (props) => {
  const { handlePaymentButtonClick, balance, amount, t } = props;

  return (
    <SPageSection>
      <S.ScreenWrapper>
        {Object.values(EPaymentMethod).map((method) => {
          return (
            <PaymentOption
              key={method}
              handlePaymentButtonClick={handlePaymentButtonClick}
              paymentMethod={method}
              amount={amount}
              balance={balance}
              t={t}
            />
          );
        })}
      </S.ScreenWrapper>
    </SPageSection>
  );
};
