import React from 'react';

import { SECTION_TITLE } from './constants/ui-text.const';
import { IDisable2FAConfirmationProps } from './types/disable-2fa-confirmation-props.interface';

import * as S from './Disable2FAConfirmation.styled';

export const Disable2FAConfirmation: React.FC<IDisable2FAConfirmationProps> = ({
  handleConfirm,
  handleCancel,
  isPending,
  t,
}) => {
  return (
    <S.ConfirmationSection>
      <S.MainContentWrapper>
        <S.Title>
          {t('disable2fa.title')}
          {SECTION_TITLE}
          {t('disable2fa.auth')}
        </S.Title>
        <S.ButtonsWrapper>
          <S.ButtonsDescription>{t('disable2fa.description')}</S.ButtonsDescription>
          <S.ConfirmButton
            text={t('disable2fa.confirmButtonText')}
            onClick={handleConfirm}
            autoFocus={true}
            disabled={isPending}
          />
          <S.CancelButton
            variant="bordered"
            text="Cancel"
            onClick={handleCancel}
            disabled={isPending}
          />
        </S.ButtonsWrapper>
      </S.MainContentWrapper>

      <S.RequiredFieldsNote>{t('disable2fa.requiredNoteText')}</S.RequiredFieldsNote>
    </S.ConfirmationSection>
  );
};
