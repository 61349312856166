import { ECategory } from 'store/api/cuverse-api/transaction/types/transaction-history-response.interface';

export const getTHistoryDescriptionTranslations = (
  title: string,
  tHCategory: ECategory,
  t: (key: string) => string,
): string => {
  switch (tHCategory) {
    case ECategory.deposit:
      return title
        .replace('deposited to', t('transactionHistory.depositedToText'))
        .replace('wallet', t('transactionHistory.wallet'));
    case ECategory.withdrawal:
      return title
        .replace('Withdrawal of', t('transactionHistory.withdrawalOf'))
        .replace('Withdrawal fee', t('transactionHistory.withdrawalFee'))
        .replace('wallet', t('transactionHistory.wallet'));
    case ECategory.mining:
      return title
        .replace('Daily mining reward', t('transactionHistory.miningText'))
        .replace('algorithm', t('transactionHistory.algorithm'));
    case ECategory.invoice:
      return title
        .replace('Payment for invoice', t('transactionHistory.paymentInvoiceText'))
        .replace('Miners purchase', t('transactionHistory.minersPurchaseText'));
    case ECategory.electricity_fee:
      return title.replace('electricity fee payment', t('transactionHistory.electricityFeeText'));
    default:
      return '';
  }
};
