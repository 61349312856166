import React, { FC } from 'react';
import { ReactComponent as CostsIcon } from 'assets/icons/miner-info/costsIcon.svg';
import { ReactComponent as RepairsIcon } from 'assets/icons/miner-info/repairsIcon.svg';
import { ReactComponent as SecureIcon } from 'assets/icons/miner-info/secureIcon.svg';
import { ReactComponent as StartIcon } from 'assets/icons/miner-info/startIcon.svg';
import { ReactComponent as TermsIcon } from 'assets/icons/miner-info/termsIcon.svg';
import { ReactComponent as WarrantyIcon } from 'assets/icons/miner-info/warrantyIcon.svg';

import * as S from './ContractInfo.styled';

interface IContractInfo {
  t: (key: string) => string;
}

export const ContractInfo: FC<IContractInfo> = ({ t }): React.ReactElement => {
  const data = [
    {
      icon: <SecureIcon />,
      title: t('minerInfo.secureTitle'),
      text: t('minerInfo.secureText'),
    },
    {
      icon: <TermsIcon />,
      title: t('minerInfo.termsTitle'),
      text: t('minerInfo.termsText'),
    },
    {
      icon: <WarrantyIcon />,
      title: t('minerInfo.warrantyTitle'),
      text: t('minerInfo.warrantyText'),
    },
    {
      icon: <RepairsIcon />,
      title: t('minerInfo.repairsTitle'),
      text: t('minerInfo.repairsText'),
    },
    {
      icon: <StartIcon />,
      title: t('minerInfo.startTitle'),
      text: t('minerInfo.startText'),
    },
    {
      icon: <CostsIcon />,
      title: t('minerInfo.costsTitle'),
      text: t('minerInfo.costsText'),
    },
  ];

  return (
    <S.HighLightsContainer>
      <S.HighLightsTitle>{t('minerInfo.contractTitle')}</S.HighLightsTitle>

      <S.InfoCardsBlock>
        {data.map((item) => (
          <S.HighLightsWrapper key={item.title}>
            <S.HighLightsSubtitles>
              <div>{item.icon}</div>
              <S.SubtitleText>{item.title}</S.SubtitleText>
            </S.HighLightsSubtitles>
            <S.Text>{item.text}</S.Text>
          </S.HighLightsWrapper>
        ))}
      </S.InfoCardsBlock>
    </S.HighLightsContainer>
  );
};
